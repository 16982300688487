import React, { useState, useEffect, useCallback } from "react";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { TaskContext } from "../../context/TaskContext";

const CelesMultiUpload = ({ label, name, setField, maxFiles = 4 }) => {
  const { form } = useContext(TaskContext);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setField(name)(acceptedFiles);
    },
  });

  const deleteUpload = () => {
    setField(name)(
      form[name].map((img) => {
        return {
          ...img,
          delete: true,
        };
      })
    );
  };

  return (
    <section>
      <div
        className="outline-none focus:ring ring-green-500"
        {...getRootProps({ className: "dropzone" })}
      >
        <div className="cursor-pointer py-4 rounded-md  bg-white border border-gray-200 mb-2">
          <img className=" h-6 w-6 m-auto " src="../cloud.svg" />
          <input
            className="outline-none focus:ring ring-green-500"
            {...getInputProps()}
          />
          <div className="text-center text-blue-500 text-sm">
            {label} : SELECT FILE
          </div>
        </div>
      </div>
      <aside>
        <ul className="flex justify-between">
          {!acceptedFiles?.length &&
            form[name]?.length > 0 &&
            !form[name]?.some((img) => img.delete) && (
              <li>
                <a
                  target="_blank"
                  className="text-blue-500 hover:text-blue-300 duration-300"
                  href={`https://admin.ariyatham.com${
                    form[name][form[name]?.length - 1].url
                  }`}
                >
                  {form[name][form[name]?.length - 1].name}
                </a>
              </li>
            )}
          {acceptedFiles.map((file) => (
            <li key={file.path} className="text-blue-500">
              {file.path}
            </li>
          ))}
          {form[name]?.length > 0 && (
            <li
              className="bg-red-500 text-white w-14 text-center rounded-md"
              onClick={() => deleteUpload()}
            >
              Delete
            </li>
          )}
        </ul>
      </aside>
    </section>
  );
};

export default CelesMultiUpload;
