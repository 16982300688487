import { useState, useCallback } from "react";

const useForm = (initFormState = {}, option = {}) => {
  const [error, setError] = useState({});
  const [form, setForm] = useState(initFormState);

  const setFieldMulti = useCallback(
    (field, primaryField, index) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((prev) => {
        let currentForm = [...prev[primaryField]];
        currentForm[index] = {
          ...currentForm[index],
          [field]: value,
        };
        return {
          ...prev,
          [primaryField]: currentForm,
        };
      });
    },
    [setForm]
  );

  // TEST ด้วย
  const setFieldUploadMulti = useCallback(
    (primaryField, index) => (e) => {
      const value = e && e.target ? e.target.value : e;

      setForm((prev) => {
        let currentForm = [...prev[primaryField]];
        currentForm[index] = value;
        return {
          ...prev,
          [primaryField]: currentForm,
        };
      });
    },
    [setForm]
  );

  // TEST ด้วย
  const setFieldRemoveMulti = useCallback(
    (primaryField, index) => (e) => {
      setForm((prev) => {
        let currentForm = [...prev[primaryField]];
        currentForm.splice(index, 1);
        return {
          ...prev,
          [primaryField]: currentForm,
        };
      });
    },
    [setForm]
  );

  const setFieldAddMulti = useCallback(
    (primaryField, defaultValue) => {
      setForm((prev) => {
        let currentForm = [...prev[primaryField]];
        const newForm = currentForm.concat(defaultValue);
        return {
          ...prev,
          [primaryField]: newForm,
        };
      });
    },
    [setForm]
  );

  const setField = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setForm]
  );

  const setFieldWithValue = (field, e) => setField(field)(e);
  const checkFormError = useCallback(() => option.formValidation(form), [
    form,
    option,
  ]);
  const setFormError = (form) => setError(checkFormError(form));
  const isFormError = useCallback(
    (err) => Object.keys(err).some((key) => err[key]),
    []
  );
  return {
    form,
    setForm,
    setFormError,
    setField,
    setFieldMulti,
    setFieldRemoveMulti,
    setFieldWithValue,
    setFieldAddMulti,
    setError,
    error,
    checkFormError,
    isFormError,
    setFieldUploadMulti,
  };
};

export default useForm;
