import gql from "graphql-tag";

const MUTATION_UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($input: updateUserInput) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export default MUTATION_UPDATE_USER_ROLE;
