import gql from "graphql-tag";

const DELETE_TASK = gql`
  mutation DELETETask($input: deleteTaskInput) {
    deleteTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export default DELETE_TASK;
