import gql from "graphql-tag";

const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        username
        email
        confirmed
        blocked
        role {
          id
          name
          type
        }
      }
    }
  }
`;

export default LOGIN;
