import React, { useState, useEffect, useCallback, useContext } from "react";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import CelesButton from "../../lib/CelesButton";
import _ from "lodash";
import CelesFormatCurrency from "./CelesFormatCurrency";
import TextArea from "./TextArea";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";
import numeral from "numeral";
import RadioGroup from "./RadioGroup";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

const EditCourt = () => {
  const { form, setField, setFieldMulti, removeArray, addArray } = useContext(
    TaskContext
  );

  const handleChange = (e, name, field, index) => {
    const value = e.target.value;
    setFieldMulti(name, field, index)(value);
  };

  const handleChangeSingle = (e, name) => {
    const value = e.target.value;
    setField(name)(value);
  };

  const checkRadio = (name, value) => {
    const getValue = form[name];
    const result = getValue === value;
    return result;
  };

  const checkRadioMulti = (val, i) => {
    const result = form?.legal_relation[i]?.collateral_type === val;

    return result;
  };

  return (
    <div className="p-4">
      <div className={"grid grid-cols-1 "}>
        {CategoryTitle("หมวดนิติสัมพันธ์")}
        {form?.legal_relation?.length &&
          form.legal_relation.map((row, index) => {
            const principle = numeral(row.principle).value();
            const interest = numeral(row.interest).value();

            const total_cost = principle + interest;

            const radio_1 = checkRadioMulti("บุคคลค้ำประกัน", index);
            const radio_2 = checkRadioMulti("หลักทรัพย์ค้ำประกัน", index);
            const radio_3 = checkRadioMulti(
              "บุคคลและหลักทรัพย์ค้ำประกัน",
              index
            );
            const radio_4 = checkRadioMulti("ไม่มีหลักประกัน", index);

            return (
              <div className="grid grid-row-1">
                {Title("นิติสัมพันธ์/เอกสาร/สัญญา ครั้งที่ " + [index + 1])}
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_relation"
                  name="account_number"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.account_number}
                  label="เลขที่บัญชี"
                />
                <CelesFormatCurrency
                  index={index}
                  primaryField="legal_relation"
                  name="principle"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.principle}
                  label="เงินต้น"
                />
                <CelesFormatCurrency
                  index={index}
                  primaryField="legal_relation"
                  name="interest"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.interest}
                  label="ดอกเบี้ย"
                />

                {Label("ยอดรวม")}
                {numeral(total_cost).format("0,0.00")}

                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_relation"
                  name="bank"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.bank}
                  label="ธนาคาร"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_relation"
                  name="branch"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.branch}
                  label="สาขา"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_relation"
                  name="county"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.county}
                  label="เขต"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_relation"
                  name="type_loan"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.type_loan}
                  label="ประเภทสินเชื่อ"
                />
                {Label("ประเภทหลักประกัน")}
                <RadioGroup
                  handleChange={handleChange}
                  index={index}
                  radio_1={radio_1}
                  radio_2={radio_2}
                  radio_3={radio_3}
                  radio_4={radio_4}
                />

                <TextArea
                  title="รายละเอียดคนค้ำประกัน"
                  name="detail_type"
                  value={row.detail_type}
                  primaryField="legal_relation"
                  setFieldMulti={setFieldMulti}
                  index={index}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("legal_relation", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <div className="grid grid-cols-1">
          <CelesButton
            onClick={() =>
              addArray("legal_relation", {
                account_number: "",
                principle: "",
                interest: "",
                total_cost: "",
                bank: "",
                branch: "",
                county: "",
                type_loan: "",
                collateral_type: "",
                detail_type: "",
              })
            }
            text="เพิ่มนิติสัมพันธ์/เอกสาร/สัญญา"
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </div>
        {Label("เรื่อง")}
        <div className=" p-2 rounded-md border border-gray-200 text-gray-700">
          <div className="">
            <div>
              <label className="inline-flex items-center">
                <input
                  onChange={(e) => handleChangeSingle(e, "fault")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="fault"
                  value="กู้ยืม"
                  checked={checkRadio("fault", "กู้ยืม")}
                />
                <span className="ml-2">กู้ยืม</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center ">
                <input
                  onChange={(e) => handleChangeSingle(e, "fault")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="fault"
                  value="กู้ยืม, ค้ำประกัน"
                  checked={checkRadio("fault", "กู้ยืม, ค้ำประกัน")}
                />
                <span className="ml-2">กู้ยืม, ค้ำประกัน</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center ">
                <input
                  onChange={(e) => handleChangeSingle(e, "fault")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="fault"
                  value="กู้ยืม, จำนอง"
                  checked={checkRadio("fault", "กู้ยืม, จำนอง")}
                />
                <span className="ml-2">กู้ยืม, จำนอง</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center">
                <input
                  onChange={(e) => handleChangeSingle(e, "fault")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="fault"
                  value="อื่นๆ"
                  checked={checkRadio("fault", "อื่นๆ")}
                />
                <span className="ml-2">อื่นๆ</span>
              </label>
            </div>
          </div>

          <input
            onChange={(e) => handleChangeSingle(e, "otherFault")}
            className=" p-1 rounded-md text-gray-700 border border-gray-200 my-1 px-2 w-full outline-none focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="pt-2" />
        <div className="border-b border-gray-300 my-4"></div>
      </div>
    </div>
  );
};

export default EditCourt;
