import React, { useContext } from "react";
import { format } from "date-fns";
import EditCourt from "../../components/create_task/EditCourt";

function Court() {
  return (
    <React.Fragment>
      <EditCourt />
    </React.Fragment>
  );
}

export default Court;
