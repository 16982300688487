export const CategoryTitle = (name) => (
  <div className="text-2xl mb-2 text-gray-900 ">{name}</div>
);

export const Title = (name) => (
  <div className="text-xl mb-2 text-green-500 ">{name}</div>
);

export const SubTitle = (name) => (
  <div className="text-lg mb-2 text-gray-700 ">{name}</div>
);

export const Label = (name) => (
  <div className="text-lg mb-2 text-gray-600 ">{name}</div>
);
