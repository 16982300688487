const MultiShowUpload = ({
  form,
  ComponentName,
  title,
  STRAPI_URL = "https://admin.ariyatham.com",
  fileName = "ไฟล์",
  index,
}) => {
  const data = form[ComponentName] || [];

  return (
    <div>
      {data?.map((row, index) => {
        if (!row.file) return <div></div>; //ดัก taskUser
        if (!row.file[0]?.url) return <div></div>; //ดัก task
        return (
          <div>
            <div className="text-lg flex flex-row justify-between ">
              <div>
                <a
                  className="my-4 text-blue-500 "
                  href={STRAPI_URL + row.file[0]?.url}
                  target="_blank"
                >
                  {title} {index + 1}
                </a>
              </div>
              <div>
                <a
                  className="my-4 text-blue-500 "
                  href={STRAPI_URL + row.file[0]?.url}
                  target="_blank"
                >
                  {fileName}
                </a>
              </div>
            </div>
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
    </div>
  );
};
export default MultiShowUpload;
