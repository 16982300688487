import { CategoryTitle, Title } from "../../components/create_task/TitleStyle";
import Main from "./Main";
import numeral from "numeral";
import React from "react";

const Payment = ({ form, STRAPI_URL }) => {
  //-----------------------------------------------------------------------------//
  //Function
  const DateMulti = ({ title, getrow, index }) => {
    if (!getrow) return <div></div>;
    return (
      <div>
        <div>
          <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
            <div>
              {title} {index + 1}
            </div>
            <div>
              <strong className="text-gray-700 text-right">{getrow}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };
  //-----------------------------------------------------------------------------//
  //คำนวณ
  const court_fee2 = numeral(form.court_fee2).value();
  const summons_cost = numeral(form.summons_cost).value();
  const precautions2 = numeral(form.precautions2).value();
  const document_certification_fee = numeral(
    form.document_certification_fee
  ).value();
  const newspaper_announcement_fee = numeral(
    form.newspaper_announcement_fee
  ).value();
  const other_expenses = numeral(form.other_expenses).value();
  const seizure_fees2 = numeral(form.seizure_fees2).value();
  const asset_withdrawal_fee = numeral(form.asset_withdrawal_fee).value();

  const total_court_fee_details =
    court_fee2 +
    summons_cost +
    precautions2 +
    document_certification_fee +
    newspaper_announcement_fee +
    other_expenses +
    seizure_fees2 +
    asset_withdrawal_fee;
  const c_total_court_fee_details = numeral(total_court_fee_details).format(
    "0,0.00"
  );
  //-----------------------------------------------------------------------------//
  const transportation_expenses = numeral(form.transportation_expenses).value();
  const food_expenses = numeral(form.food_expenses).value();
  const postage = numeral(form.postage).value();
  const newspaper_announcement_fee2 = numeral(
    form.newspaper_announcement_fee2
  ).value();
  const civil_registration_fee = numeral(form.civil_registration_fee).value();
  const documentation_fee = numeral(form.documentation_fee).value();
  const photocopying_fee = numeral(form.photocopying_fee).value();
  const allowance_profession = numeral(form.allowance_profession).value();
  const other_expenses2 = numeral(form.other_expenses2).value();

  const details_costs_litigation =
    transportation_expenses +
    food_expenses +
    postage +
    newspaper_announcement_fee2 +
    civil_registration_fee +
    documentation_fee +
    photocopying_fee +
    allowance_profession +
    other_expenses2;
  const c_details_costs_litigation = numeral(details_costs_litigation).format(
    "0,0.00"
  );
  //-----------------------------------------------------------------------------//
  const result_all_cost = form?.detail_payment?.reduce((prev, cur) => {
    const ask_cost = numeral(cur.ask_cost).value();
    const etc_cost = numeral(cur.etc_cost).value();
    const lump_cost = numeral(cur.lump_cost).value();
    const professional_fee = numeral(cur.professional_fee).value();

    const result = ask_cost + etc_cost + lump_cost + professional_fee;

    return result + prev;
  }, 0);
  const end_cost = result_all_cost - details_costs_litigation;
  const c_end_cost = numeral(end_cost).format("0,0.00");

  const checkRole = () => {
    const roleName = localStorage.getItem("roleName");
    if (["Lawyer", "Admin"].includes(roleName)) {
      return false;
    }

    return true;
  };

  //-----------------------------------------------------------------------------//
  return (
    <div className="p-4 bg-white rounded-md my-4 shadow" id="paper">
      {CategoryTitle("หมวดการเงิน")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {Title("รายละเอียดค่าธรรมเนียมศาล/บังคับคดี")}
      <Main title="ค่าขึ้นศาล" name={form.court_fee2} />
      <Main title="ค่านำหมายเรียก ฯ" name={form.summons_cost} />
      <Main title="ค่านำคำบังคับ ฯ" name={form.precautions2} />
      <Main title="ค่ารับรองเอกสาร" name={form.document_certification_fee} />
      <Main
        title="ค่าประกาศหนังสือพิมพ์"
        name={form.newspaper_announcement_fee}
      />
      <Main title="ค่าใช้จ่ายอื่น ๆ" name={form.other_expenses} />
      <Main title="ค่าธรรมเนียมยึดทรัพย์" name={form.seizure_fees2} />
      <Main title="ค่าธรรมเนียมอายัดทรัพย์" name={form.asset_withdrawal_fee} />
      <Main title="ผลรวม" name={c_total_court_fee_details} />
      <div className="border-b border-gray-300 my-4"></div>
      {Title("รายละเอียดค่าใช้จ่ายในการดำเนินคดี")}
      <Main title="ค่าพาหนะเดินทาง" name={form.transportation_expenses} />
      <Main title="ค่าอาหาร" name={form.food_expenses} />
      <Main title="ค่าส่งไปรษณีย์" name={form.postage} />
      <Main
        title="ค่าประกาศหนังสือพิมพ์"
        name={form.newspaper_announcement_fee2}
      />
      <Main title="ค่าคัดทะเบียนราษฎร์" name={form.civil_registration_fee} />
      <Main title="ค่าจัดทำเอกสาร" name={form.documentation_fee} />
      <Main title="ค่าถ่ายเอกสาร" name={form.photocopying_fee} />
      <Main title="ค่าเบี้ยเลี้ยง / วิชาชีพ" name={form.allowance_profession} />
      <Main title="ค่าใช้จ่ายอื่น ๆ" name={form.other_expenses2} />
      <Main title="ผลรวม" name={c_details_costs_litigation} />
      <div className="border-b border-gray-300 my-4"></div>

      {checkRole() && (
        <React.Fragment>
          {Title("รายละเอียดเกี่ยวกับรายได้")}
          {form.detail_payment.map((row, index) => {
            //-----------------------------------------------------------------------------//
            //คำนวณ
            const ask_cost = numeral(row.ask_cost).value();
            const lump_cost = numeral(row.lump_cost).value();
            const professional_fee = numeral(row.professional_fee).value();
            const etc_cost = numeral(row.etc_cost).value();

            const detail_payment_cost =
              ask_cost + lump_cost + professional_fee + etc_cost;
            const c_detail_payment_cost = numeral(detail_payment_cost).format(
              "0,0.00"
            );
            //-----------------------------------------------------------------------------//
            return (
              <div>
                <DateMulti
                  title="วันเบิกเงินงวด ที่ "
                  index={index}
                  getrow={row.reveal_date}
                />
                <DateMulti
                  title="วันได้รับเงินงวด ที่ "
                  index={index}
                  getrow={row.received_date}
                />
                <Main title="ค่าทวงถาม" name={row.ask_cost} />
                <Main title="ค่าใช้จ่ายเหมา" name={row.lump_cost} />
                <Main title="ค่าวิชาชีพ" name={row.professional_fee} />
                <Main title="ค่าใช้จ่ายอื่น ๆ" name={row.etc_cost} />
                <Main title="ผลรวม" name={c_detail_payment_cost} />
                <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
              </div>
            );
          })}
          <Main title="รายได้สุทธิ" name={c_end_cost} />
          <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Payment;
