import React, { useState, useEffect, useContext, memo } from "react";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import CelesButton from "../../lib/CelesButton";
import CelesFormatCurrency from "./CelesFormatCurrency";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";
import numeral from "numeral";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";
import TextArea from "./TextArea";

const EditDoc = () => {
  const {
    form,
    setField,
    setFieldMulti,
    onDropFileSingle,
    removeArray,
    addArray,
    editing,
  } = useContext(TaskContext);
  const handleChange = (e, name) => {
    const value = e.target.value;
    setField(name)(value);
  };
  const court_fee = numeral(form.court_fee).value();
  const summons = numeral(form.summons).value();
  const precautions = numeral(form.precautions).value();
  const lawyer_fee = numeral(form.lawyer_fee).value();
  const court_fees = numeral(form.court_fees).value();

  const cost_fees_account =
    court_fee + summons + precautions + lawyer_fee - court_fees;

  const seizure_fees = numeral(form.seizure_fees).value();
  const delivery_cost = numeral(form.delivery_cost).value();
  const etc_cost = numeral(form.etc_cost).value();

  const account_legal_fees = seizure_fees + delivery_cost + etc_cost;

  const checkRadio = (name, value) => {
    const getValue = form[name];
    const result = getValue === value;
    return result;
  };

  return (
    <div className="p-4">
      <div className="grid grid-row-1">
        {CategoryTitle("หมวดหน่วยงาน")}
        {form?.agencyComponent?.length &&
          form.agencyComponent.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <div className=" text-lg sm:text-xl mb-2  text-green-500">
                  ศาล ที่ {index + 1}
                </div>
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="agencyComponent"
                  name="agency_name"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.agency_name}
                  label="หน่วยงานที่รับฟ้อง / รับเรื่อง"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="agencyComponent"
                  name="case_type"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.case_type}
                  label="ประเภทคดี"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="agencyComponent"
                  name="black_case"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.black_case}
                  label="หมายเลขคดีดำ"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="agencyComponent"
                  name="red_case"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.red_case}
                  label="หมายเลขคดีแดง"
                />
                <CelesFormatCurrency
                  index={index}
                  primaryField="agencyComponent"
                  name="capital"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.capital}
                  label="ทุนทรัพย์"
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("agencyComponent", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() =>
            addArray("agencyComponent", {
              agency_name: "",
              case_type: "",
              black_case: "",
              red_case: "",
              capital: "",
            })
          }
          text="เพิ่มหน่วยงาน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="border-b border-gray-300 my-4"></div>
        {Title("บัญชีค่าฤชาธรรมเนียมศาล")}
        <CelesFormatCurrency
          setField={setField}
          name="court_fee"
          label="ค่าขึ้นศาล"
          value={form.court_fee}
        />
        <CelesFormatCurrency
          setField={setField}
          name="summons"
          label="ค่านำหมายเรียก ฯ"
          value={form.summons}
        />
        <CelesFormatCurrency
          setField={setField}
          name="precautions"
          label="ค่านำคำบังคับ"
          value={form.precautions}
        />
        <CelesFormatCurrency
          setField={setField}
          name="lawyer_fee"
          label="ค่าทนายความ"
          value={form.lawyer_fee}
        />
        <CelesFormatCurrency
          setField={setField}
          name="court_fees"
          label="ค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
          value={form.court_fees}
        />
        {Label("ยอดรวม")}
        {numeral(cost_fees_account).format("0,0.00")}
        {/* ตรงนี่้ทำที่คำนวน ไอ 5 อันบน */}
        <TextArea
          title="ผลการส่งหมายเรียกฯ"
          name="result_summons"
          setField={setField}
        />
        <div className="border-b border-gray-300 my-4"></div>
        {form?.proceedingsComponent?.length &&
          form.proceedingsComponent.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <div className=" text-lg  mb-2  text-gray-700">
                  รายงานกระบวนพิจารณา ครั้งที่ {index + 1}
                </div>
                <TextArea
                  primaryField="proceedingsComponent"
                  name="text"
                  setFieldMulti={setFieldMulti}
                  value={row.text}
                  index={index}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("proceedingsComponent", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("proceedingsComponent", { text: "" })}
          text="เพิ่มรายงานกระบวนพิจารณา"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="border-b border-gray-300 my-4"></div>
        {Label(" ผลการพิจารณาคดี")}
        <div className=" p-2 rounded-md border border-gray-200 text-gray-700">
          <div className="">
            <div>
              <label className="inline-flex items-center">
                <input
                  onChange={(e) => handleChange(e, "trial_result")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="trial_result"
                  value="พิพากษาฝ่ายเดียว"
                  checked={checkRadio("trial_result", "พิพากษาฝ่ายเดียว")}
                />
                <span className="ml-2">พิพากษาฝ่ายเดียว</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center ">
                <input
                  onChange={(e) => handleChange(e, "trial_result")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="trial_result"
                  value="พิพากษาตามยอม"
                  checked={checkRadio("trial_result", "พิพากษาตามยอม")}
                />
                <span className="ml-2">พิพากษาตามยอม</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center ">
                <input
                  onChange={(e) => handleChange(e, "trial_result")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="trial_result"
                  value="พิพากษาตามรูปคดี"
                  checked={checkRadio("trial_result", "พิพากษาตามรูปคดี")}
                />
                <span className="ml-2">พิพากษาตามรูปคดี</span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center ">
                <input
                  onChange={(e) => handleChange(e, "trial_result")}
                  type="radio"
                  className="form-radio text-green-500"
                  name="trial_result"
                  value="ถอนฟ้อง"
                  checked={checkRadio("trial_result", "ถอนฟ้อง")}
                />
                <span className="ml-2">ถอนฟ้อง</span>
              </label>
            </div>
          </div>
        </div>
        <div className="mt-4" />
        <TextArea
          title="ผลการส่งคำบังคับ ฯ"
          name="result_regulations"
          setField={setField}
        />
        {form?.detail_shortly?.length &&
          form.detail_shortly.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <TextArea
                  title={
                    "รายละเอียดคำฟ้อง (โดยย่อ) / คำขอท้ายฟ้อง ที่ " +
                    [index + 1]
                  }
                  primaryField="detail_shortly"
                  name="brief_indictment"
                  setFieldMulti={setFieldMulti}
                  value={row.brief_indictment}
                  index={index}
                />

                <TextArea
                  title={"รายละเอียดคำพิพากษา (โดยย่อ) ที่" + [index + 1]}
                  primaryField="detail_shortly"
                  name="judgment_details"
                  setFieldMulti={setFieldMulti}
                  value={row.judgment_details}
                  index={index}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("detail_shortly", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() =>
            addArray("detail_shortly", {
              brief_indictment: "",
              judgment_details: "",
            })
          }
          text="เพิ่มรายละเอียดคำฟ้อง (โดยย่อ) และ รายละเอียดคำพิพากษา (โดยย่อ)"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        {/* // งานบังคับคดี legal_executionComponent */}
        <div className="pt-2" />
        {form?.legal_executionComponent?.length &&
          form.legal_executionComponent.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                {Title("งานบังคับคดี ที่ " + [index + 1])}
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_executionComponent"
                  name="agency_name"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.agency_name}
                  label="หน่วยงานที่รับฟ้อง / รับเรื่อง"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_executionComponent"
                  name="court"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.court}
                  label="ศาล"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_executionComponent"
                  name="black_case"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.black_case}
                  label="หมายเลขคดีดำ"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_executionComponent"
                  name="red_case"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.red_case}
                  label="หมายเลขคดีแดง"
                />
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="legal_executionComponent"
                  name="capital"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.capital}
                  label="ทุนทรัพย์"
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("legal_executionComponent", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() =>
            addArray("legal_executionComponent", {
              agency_name: "",
              court: "",
              black_case: "",
              red_case: "",
              capital: "",
            })
          }
          text="เพิ่มงานบังคับคดี"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="border-b border-gray-300 my-4"></div>
        {Title("บัญชีค่าฤชาธรรมเนียมบังคับคดี")}
        <CelesFormatCurrency
          setField={setField}
          name="seizure_fees"
          label=" ค่าธรรมเนียมยึด / อายัดทรัพย์"
          value={form.seizure_fees}
        />
        <CelesFormatCurrency
          setField={setField}
          name="delivery_cost"
          label="ค่านำส่งคำคู่ความ"
          value={form.delivery_cost}
        />
        <CelesFormatCurrency
          setField={setField}
          name="etc_cost"
          label="อื่น ๆ"
          value={form.etc_cost}
        />
        {Label("ผลรวม")}
        {numeral(account_legal_fees).format("0,0.00")}
        <TextArea
          title="รายละเอียดการบังคับคดี"
          name="details_execution"
          setField={setField}
        />
        {form?.official_appraisal_price?.length &&
          form.official_appraisal_price.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <div className=" text-md  mb-2  text-gray-700">
                  ราคาประเมินเจ้าพนักงาน ครั้งที่ {index + 1}
                </div>
                <CelesFormatCurrency
                  index={index}
                  primaryField="official_appraisal_price"
                  name="cost"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.cost}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("official_appraisal_price", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("official_appraisal_price", { cost: "" })}
          text="เพิ่มราคาประเมินเจ้าพนักงาน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="border-b border-gray-300 my-4"></div>
      </div>
    </div>
  );
};
export default EditDoc;
