import gql from "graphql-tag";

const QUERY_TASK_BY_ID = gql`
  query queryTaskById($id: ID!) {
    task(id: $id) {
      id
      acceptance_code
      register
      lawyer_name
      status
      date_accept
      counterclaim_date
      court_fees
      plaintiffComponent {
        citizen_number
        address
        position
        phone_number
        email
        name
      }
      defendantComponent {
        citizen_number
        address
        position
        phone_number
        email
        name
      }
      complainantComponent {
        citizen_number
        address
        position
        phone_number
        email
        name
      }
      suffererComponent {
        citizen_number
        address
        position
        phone_number
        email
        name
      }
      fault
      agencyComponent {
        agency_name
        black_case
        red_case
        capital
        case_type
      }
      seizure_fees
      delivery_cost
      etc_cost
      details_execution
      newspaper_request_date
      delay_work_date
      filing_date
      consideration_date {
        date
      }
      appointment_judgment_date
      judgment_reading_date
      appeal_deadline {
        date
      }
      appeal_filing_date
      submission_decision_date
      appointment_decision_date
      read_decision_date
      supreme_court_date {
        date
      }
      petition_date
      submission_petition_date
      appointment_petition_date
      read_petition_date
      submission_execution_date
      execution_date
      final_certificate_date
      publication_certificate_date
      accept_certificate_date
      receive_certificate_date
      claim_fee_date
      receive_fee_date
      closing_date
      preparing_documents_date
      execution_date2
      public_auction_date {
        date
      }
      money_from_execution {
        date
      }
      receipt_payment_account {
        date
      }
      closing_day2
      loan_agreement {
        id
        url
        name
      }
      surety_contract {
        id
        url
        name
      }
      mortgage {
        id
        url
        name
      }
      id_card {
        id
        url
        name
      }
      notice_demand {
        file {
          id
          url
          name
        }
      }
      newspaper_announcement {
        id
        url
        name
      }
      debt_calculation_list {
        id
        url
        name
      }
      indictment {
        id
        url
        name
      }
      end_plaint {
        id
        url
        name
      }
      paper_lawyer {
        id
        url
        name
      }
      witness_account {
        id
        url
        name
      }
      closing_statement {
        id
        url
        name
      }
      request_consideration {
        id
        url
        name
      }
      statement_request_documents {
        id
        url
        name
      }
      request_document1 {
        file {
          id
          url
          name
        }
      }
      summons_defendant {
        id
        url
        name
      }
      pictures_photos_maps {
        id
        url
        name
      }
      report_seizure {
        id
        url
        name
      }
      auction_announcement {
        id
        url
        name
      }
      payment_account {
        id
        url
        name
      }
      proceedings_report {
        file {
          id
          url
          name
        }
      }
      sentence_document {
        id
        url
        name
      }
      decree_document {
        id
        url
        name
      }
      fee_account {
        id
        url
        name
      }
      warrant_execution {
        id
        url
        name
      }
      certificate_final_case {
        id
        url
        name
      }
      request_document2 {
        file {
          id
          url
          name
        }
      }
      withdraw_documents {
        id
        url
        name
      }
      withdrawal_book {
        file {
          id
          url
          name
        }
      }
      court_fee
      summons_cost
      precautions
      document_certification_fee
      newspaper_announcement_fee
      other_expenses
      seizure_fees2
      asset_withdrawal_fee
      transportation_expenses
      postage
      newspaper_announcement_fee2
      civil_registration_fee
      documentation_fee
      photocopying_fee
      allowance_profession
      other_expenses2
      detail_payment {
        lump_cost
        professional_fee
        ask_cost
        etc_cost
        received_date
        reveal_date
      }
      legal_relation {
        collateral_type
        branch
        detail_type
        total_cost
        county
        interest
        type_loan
        account_number
        principle
        bank
      }
      lawyer_fee
      court_fee
      cost_fees_account
      result_summons
      proceedingsComponent {
        text
      }
      trial_result
      result_regulations
      detail_shortly {
        brief_indictment
        judgment_details
      }
      legal_executionComponent {
        agency_name
        court
        black_case
        red_case
        capital
      }
      account_legal_fees
      official_appraisal_price {
        cost
      }
      delay_sue
      introductory_date {
        date
      }
      announcement_date
      announcement_date2
      declaration_date {
        date
      }
      sell_to_public {
        date
      }
      operation_record {
        text
      }
      receipt {
        file {
          id
          url
          name
        }
      }
      etc_book1 {
        file {
          id
          url
          name
        }
        name
      }
      court_order {
        file {
          id
          url
          name
        }
      }
      upload_receipt {
        file {
          id
          url
          name
        }
      }
      etc_book2 {
        file {
          id
          url
          name
        }
        name
      }
      opinion_book {
        file {
          id
          url
          name
        }
      }
      etc_book3 {
        name
        file {
          id
          url
          name
        }
      }
      total_court_fee_details
      food_expenses
      details_costs_litigation
      detail_payment_cost
      result_all_cost
      summons
      introduction_date {
        date
      }
      submission_document {
        name
        date
      }
      parties
      end_cost
      court_fee2
      precautions2
      mediation_date
      recived_document {
        date
        name
      }
      etc_book4 {
        name
        file {
          id
          url
          name
        }
      }
      eid_mediation_date
      eid_appeal_deadline {
        id
        eid
      }
      eid_consideration_date {
        id
        eid
      }
      eid_supreme_court_date {
        id
        eid
      }
      eid_mediation_date
      eid_appointment_judgment_date
      eid_appointment_decision_date
      eid_appointment_petition_date
    }
  }
`;

export default QUERY_TASK_BY_ID;
