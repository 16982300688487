import React, { useState, useEffect, useCallback, useContext } from "react";
import CelesMultiUpload from "./CelesMultiUpload";
import CelesUpload from "./CelesUpload";
import CelesDate from "./CelesDatePicker";
import Lable from "./CelesLabel";
import CelesButton from "../../lib/CelesButton";
import CelesFormatCurrency from "./CelesFormatCurrency";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";
import numeral from "numeral";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

function EditClient() {
  const { setField, form, setFieldMulti, removeArray, addArray } = useContext(
    TaskContext
  );

  // const { name } = numeral(form.[name]).value();
  // const total_court_fee2_details = {name} + summons;
  //[...perv] ของ array แล้วก็ เอา name ไป maps ให่้ได้ที่คำนวณออกมา

  const checkRole = () => {
    const roleName = localStorage.getItem("roleName");
    if (["Lawyer", "Admin"].includes(roleName)) {
      return false;
    }
    return true;
  };

  const court_fee2 = numeral(form.court_fee2).value();
  const summons_cost = numeral(form.summons_cost).value();
  const precautions2 = numeral(form.precautions2).value();
  const document_certification_fee = numeral(
    form.document_certification_fee
  ).value();
  const newspaper_announcement_fee = numeral(
    form.newspaper_announcement_fee
  ).value();
  const other_expenses = numeral(form.other_expenses).value();
  const seizure_fees2 = numeral(form.seizure_fees2).value();
  const asset_withdrawal_fee = numeral(form.asset_withdrawal_fee).value();

  const total_court_fee_details =
    court_fee2 +
    summons_cost +
    precautions2 +
    document_certification_fee +
    newspaper_announcement_fee +
    other_expenses +
    seizure_fees2 +
    asset_withdrawal_fee;

  const transportation_expenses = numeral(form.transportation_expenses).value();
  const food_expenses = numeral(form.food_expenses).value();
  const postage = numeral(form.postage).value();
  const newspaper_announcement_fee2 = numeral(
    form.newspaper_announcement_fee2
  ).value();
  const civil_registration_fee = numeral(form.civil_registration_fee).value();
  const documentation_fee = numeral(form.documentation_fee).value();
  const photocopying_fee = numeral(form.photocopying_fee).value();
  const allowance_profession = numeral(form.allowance_profession).value();
  const other_expenses2 = numeral(form.other_expenses2).value();

  const details_costs_litigation =
    transportation_expenses +
    food_expenses +
    postage +
    newspaper_announcement_fee2 +
    civil_registration_fee +
    documentation_fee +
    photocopying_fee +
    allowance_profession +
    other_expenses2;

  const result_all_cost = form?.detail_payment?.reduce((prev, cur) => {
    const ask_cost = numeral(cur.ask_cost).value();
    const etc_cost = numeral(cur.etc_cost).value();
    const lump_cost = numeral(cur.lump_cost).value();
    const professional_fee = numeral(cur.professional_fee).value();

    const result = ask_cost + etc_cost + lump_cost + professional_fee;

    return result + prev;
  }, 0);

  const end_cost = result_all_cost - details_costs_litigation;
  return (
    <div className="p-4 ">
      {CategoryTitle("หมวดการเงิน")}
      {Title("รายละเอียดค่าธรรมเนียมศาล / บังคับคดี")}
      <CelesFormatCurrency
        setField={setField}
        name="court_fee2"
        label="ค่าขึ้นศาล"
        value={form.court_fee2}
      />
      <CelesFormatCurrency
        setField={setField}
        name="summons_cost"
        label="ค่านำหมายเรียก ฯ"
        value={form.summons_cost}
      />

      <CelesFormatCurrency
        setField={setField}
        name="precautions2"
        label="ค่านำคำบังคับ"
        value={form.precautions2}
      />
      <CelesFormatCurrency
        setField={setField}
        name="document_certification_fee"
        label="ค่ารับรองเอกสาร"
        value={form.document_certification_fee}
      />
      <CelesFormatCurrency
        setField={setField}
        name="newspaper_announcement_fee"
        label="ค่าประกาศหนังสือพิมพ์"
        value={form.newspaper_announcement_fee}
      />
      <CelesFormatCurrency
        setField={setField}
        name="other_expenses"
        label="ค่าใช้จ่ายอื่น ๆ"
        value={form.other_expenses}
      />
      <CelesFormatCurrency
        setField={setField}
        name="seizure_fees2"
        label="ค่าธรรมเนียมยึดทรัพย์"
        value={form.seizure_fees2}
      />
      <CelesFormatCurrency
        setField={setField}
        name="asset_withdrawal_fee"
        label="ค่าธรรมเนียมอายัดทรัพย์"
        value={form.asset_withdrawal_fee}
      />
      {Label("ผลรวม")}
      {numeral(total_court_fee_details).format("0,0.00")}
      <div className="border-b border-gray-300 my-4"></div>
      {Title("รายละเอียดค่าใช้จ่ายในการดำเนินคดี")}
      <CelesFormatCurrency
        setField={setField}
        name="transportation_expenses"
        label="ค่าพาหนะเดินทาง"
        value={form.transportation_expenses}
      />
      <CelesFormatCurrency
        setField={setField}
        name="food_expenses"
        label="ค่าอาหาร"
        value={form.food_expenses}
      />
      <CelesFormatCurrency
        setField={setField}
        name="postage"
        label="ค่าส่งไปรษณีย์"
        value={form.postage}
      />
      <CelesFormatCurrency
        setField={setField}
        name="newspaper_announcement_fee2"
        label="ค่าประกาศหนังสือพิมพ์"
        value={form.newspaper_announcement_fee2}
      />
      <CelesFormatCurrency
        setField={setField}
        name="civil_registration_fee"
        label="ค่าคัดทะเบียนราษฎร์"
        value={form.civil_registration_fee}
      />
      <CelesFormatCurrency
        setField={setField}
        name="documentation_fee"
        label="ค่าจัดทำเอกสาร"
        value={form.documentation_fee}
      />
      <CelesFormatCurrency
        setField={setField}
        name="photocopying_fee"
        label="ค่าถ่ายเอกสาร"
        value={form.photocopying_fee}
      />
      <CelesFormatCurrency
        setField={setField}
        name="allowance_profession"
        label="ค่าเบี้ยเลี้ยง / วิชาชีพ"
        value={form.allowance_profession}
      />
      <CelesFormatCurrency
        setField={setField}
        name="other_expenses2"
        label="ค่าใช้จ่ายอื่น ๆ"
        value={form.other_expenses2}
      />
      {Label("ผลรวม")}
      {numeral(details_costs_litigation).format("0,0.00")}
      <div className="border-b border-gray-300 my-4"></div>
      {/* ค่าทวงถาม [index+1] ค่าใช้จ่ายเหมา[index+1] ค่าวิชาชีพ[index+1]
      ค่าใช้จ่ายอื่นๆ[index+1] */}

      {checkRole() && (
        <React.Fragment>
          {Title("รายละเอียดเกี่ยวกับรายได้")}
          {form?.detail_payment?.length &&
            form.detail_payment.map((row, index) => {
              const ask_cost = numeral(row.ask_cost).value();
              const lump_cost = numeral(row.lump_cost).value();
              const professional_fee = numeral(row.professional_fee).value();
              const etc_cost = numeral(row.etc_cost).value();

              const detail_payment_cost =
                ask_cost + lump_cost + professional_fee + etc_cost;

              return (
                <div className="grid grid-row-1">
                  <div className="text-lg text-gray-600"></div>
                  <div className="grid grid-cols-1">
                    <CelesDate
                      index={index}
                      primaryField="detail_payment"
                      name="reveal_date"
                      label={"วันเบิกเงินงวด ที่ " + (index + 1)}
                      form={form}
                      setFieldMulti={setFieldMulti}
                      value={row.reveal_date}
                    />
                    <div className="text-lg text-gray-600"></div>
                    <div className="grid grid-cols-1">
                      <CelesDate
                        index={index}
                        primaryField="detail_payment"
                        name="received_date"
                        label={"วันได้รับเงินงวด ที่ " + (index + 1)}
                        form={form}
                        setFieldMulti={setFieldMulti}
                        value={row.received_date}
                      />
                    </div>
                  </div>

                  <CelesFormatCurrency
                    index={index}
                    primaryField="detail_payment"
                    name="ask_cost"
                    form={form}
                    setFieldMulti={setFieldMulti}
                    value={row.ask_cost}
                    label="ค่าทวงถาม"
                  />
                  <CelesFormatCurrency
                    index={index}
                    primaryField="detail_payment"
                    name="lump_cost"
                    form={form}
                    setFieldMulti={setFieldMulti}
                    value={row.lump_cost}
                    label="ค่าใช้จ่ายเหมา"
                  />
                  <CelesFormatCurrency
                    index={index}
                    primaryField="detail_payment"
                    name="professional_fee"
                    form={form}
                    setFieldMulti={setFieldMulti}
                    value={row.professional_fee}
                    label="ค่าวิชาชีพ"
                  />
                  <CelesFormatCurrency
                    index={index}
                    primaryField="detail_payment"
                    name="etc_cost"
                    form={form}
                    setFieldMulti={setFieldMulti}
                    value={row.etc_cost}
                    label="ค่าใช้จ่ายอื่น ๆ"
                  />
                  {Label("ผลรวม")}
                  {numeral(detail_payment_cost).format("0,0.00")}
                  <button
                    onClick={() => removeArray("detail_payment", index)}
                    className="bg-red-500 text-white p-2  rounded"
                    hidden={index === 0}
                  >
                    Delete
                  </button>
                </div>
              );
            })}
          <div className="pt-2" />
          <div className="grid grid-row-1">
            <CelesButton
              onClick={() =>
                addArray("detail_payment", {
                  received_date: "",
                  reveal_date: "",
                  ask_cost: "",
                  lump_cost: "",
                  professional_fee: "",
                  etc_cost: "",
                })
              }
              text="เพิ่มงวด"
              icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
            {Label("รายได้สุทธิ")}
            {numeral(end_cost).format("0,0.00")}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default EditClient;
