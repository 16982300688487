import CelesButton from "../../lib/CelesButton";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";

import { TaskContext } from "../../context/TaskContext";
import React, { useContext } from "react";

const ProfileArray = ({ ComponentName, TextName, ButtonName }) => {
  const {
    form,
    setField,
    setForm,
    checkRadio,
    setFieldMulti,
    removeArray,
    addArray,
  } = useContext(TaskContext);

  const data = form[ComponentName] || [];

  return (
    // <div>asdasd</div>
    <div>
      {/* form?[ComponentName]?.length &&
        form[ComponentName].map */}
      {data.map((row, index) => {
        return (
          <div className="grid grid-row-1">
            <div className="grid grid-row-1">
              <div className=" text-xl mb-2 text-gray-800">
                {Title([TextName] + [index + 1])}
              </div>
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="name"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.name}
                label="ชื่อ"
              />
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="citizen_number"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.citizen_number}
                label="เลขประจำตัวประชาชน"
              />
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="address"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.address}
                label="ที่อยู่"
              />
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="position"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.position}
                label="ฐานะ"
              />
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="phone_number"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.phone_number}
                label="โทรศัพท์"
              />
              <CelesTextFieldWithTitle
                index={index}
                primaryField={ComponentName}
                name="email"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.email}
                label="อีเมล"
              />
            </div>
            <div className="pt-2" />
            <button
              onClick={() => removeArray([ComponentName], index)}
              className="bg-red-500 text-white p-2  rounded"
              hidden={index === 0}
            >
              Delete
            </button>
          </div>
        );
      })}

      <div className="mt-4"></div>
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() =>
            addArray([ComponentName], {
              name: "",
              citizen_number: "",
              address: "",
              position: "",
              phone_number: "",
              email: "",
            })
          }
          text={ButtonName}
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="border-b border-gray-300 my-4"></div>
    </div>
  );
};
export default ProfileArray;
