import Main from "./Main";

const Profile_Array = ({ ComponentName, title, form }) => {
  const data = form[ComponentName] || [];
  const checkFileIsExist = data.some((row) => row?.name?.length > 0);
  if (!checkFileIsExist) return <div></div>;
  return (
    <div>
      {data.map((row, index) => {
        return (
          <div>
            <div className="text-xl mb-2 text-green-500 ">
              {title} {index + 1}
            </div>
            <Main title="ชื่อ" name={row.name} />
            <Main title="เลขประจำตัวประชาชน" name={row.citizen_number} />
            <Main title="ที่อยู่" name={row.address} />
            <Main title="ฐานะ" name={row.position} />
            <Main title="โทรศัพท์" name={row.phone_number} />
            <Main title="อีเมล" name={row.email} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
    </div>
  );
};
export default Profile_Array;
