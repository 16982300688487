import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import th from "date-fns/locale/th";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { Controller } from "react-hook-form";
import { set } from "date-fns";
// registerLocale("th", th);

const Style =
  "border border-gray-200  text-gray-700 text-md sm:text-lg my-1 py-1 px-2 rounded w-full";

function autoTab(obj) {
  var pattern = new String("__/__/____"); // กำหนดรูปแบบในนี้
  var pattern_ex = new String("/"); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
  var returnText = new String("");
  var obj_l = obj.length;
  var obj_l2 = obj_l - 1;
  for (var i = 0; i < pattern.length; i++) {
    if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
      returnText += obj + pattern_ex;
      obj = returnText;
    }
  }
  if (obj_l >= pattern.length) {
    obj = obj.substr(0, pattern.length);
  }
  return obj;
}

const CelesDatePicker = ({
  label,
  name,
  setField,
  value,
  setFieldMulti,
  primaryField,
  index,
  className = "text-lg mb-2 text-gray-600 ",
}) => {
  const onChange = (e) => {
    const value = autoTab(e.target.value);
    if (primaryField) {
      return setFieldMulti(name, primaryField, index)(value);
    }
    setField(name)(value);
  };

  return (
    <div className="w-full">
      <div className={className}>{label}</div>
      <input
        type=""
        name={name}
        placeholder=""
        value={value}
        onChange={onChange}
        className={
          "bg-white border border-gray-200 my-1 rounded p-1 px-2 w-full focus:ring-1 focus:ring-green-500 outline-none  text-gray-700 "
        }
      />
    </div>
  );
};

export default CelesDatePicker;
