import { CategoryTitle, Title } from "../../components/create_task/TitleStyle";
import _ from "lodash";
import MultiShowUpload from "./MultiShowUpload";
import ShowUpload from "./ShowUpload";

const getImageUrl = (datas = []) => {
  if (datas.length === 0) return;

  let index = 0;
  index = datas?.length - 1;

  return datas[index]?.url;
};

const Upload = ({ form, STRAPI_URL }) => {
  const loan_agreement = getImageUrl(form?.loan_agreement);
  const surety_contract = getImageUrl(form?.surety_contract);
  const mortgage = getImageUrl(form?.mortgage);
  const id_card = getImageUrl(form?.id_card);
  const newspaper_announcement = getImageUrl(form?.newspaper_announcement);
  const debt_calculation_list = getImageUrl(form?.debt_calculation_list);
  const indictment = getImageUrl(form?.indictment);
  const end_plaint = getImageUrl(form?.end_plaint);
  const paper_lawyer = getImageUrl(form?.paper_lawyer);
  const witness_account = getImageUrl(form?.witness_account);
  const closing_statement = getImageUrl(form?.closing_statement);
  const request_consideration = getImageUrl(form?.request_consideration);
  const statement_request_documents = getImageUrl(
    form?.statement_request_documents
  );
  const payment_account = getImageUrl(form?.payment_account);
  const sentence_document = getImageUrl(form?.sentence_document);
  const fee_account = getImageUrl(form?.fee_account);
  const decree_document = getImageUrl(form?.decree_document);
  const warrant_execution = getImageUrl(form?.warrant_execution);
  const certificate_final_case = getImageUrl(form?.certificate_final_case);
  const summons_defendant = getImageUrl(form?.summons_defendant);
  const pictures_photos_maps = getImageUrl(form?.pictures_photos_maps);
  const report_seizure = getImageUrl(form?.report_seizure);
  const auction_announcement = getImageUrl(form?.auction_announcement);
  const withdraw_documents = getImageUrl(form?.withdraw_documents);

  const MultiUploadwithText = ({
    ComponentName,
    title,
    fileName = "ไฟล์",
    index,
    name,
  }) => {
    const data = form[ComponentName] || [];
    const checkFileIsExist = data.some((item) => item?.file?.length > 0);
    if (!checkFileIsExist) return <div></div>;
    return (
      <div>
        <div className="my-2 text-gray-500 text-lg"> อื่นๆ</div>
        <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
        {data.map((row, index) => {
          return (
            <div>
              <div className="text-lg flex flex-row justify-between ">
                <div>
                  <a
                    className="my-4 text-blue-500 "
                    href={STRAPI_URL + row?.file[0]?.url}
                    target="_blank"
                  >
                    {row.name}
                  </a>
                </div>
                <div>
                  <a
                    className="my-4 text-blue-500 "
                    href={STRAPI_URL + row?.file[0]?.url}
                    target="_blank"
                  >
                    {fileName}
                  </a>
                </div>
              </div>
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="p-4 bg-white rounded-md my-4 shadow" id="paper">
      {CategoryTitle("หมวดอัปโหลดเอกสาร")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {Title("ขั้นตอนเตรียมงาน/เตรียมเอกสาร")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <ShowUpload title="สัญญากู้" name={loan_agreement} />
      <ShowUpload title="สัญญาค้ำประกัน" name={surety_contract} />
      <ShowUpload title="สัญญาจำนอง,ต่อท้าย" name={mortgage} />
      <ShowUpload title="สำเนาบัตรประชาชน,ทะเบียนบ้าน" name={id_card} />
      <MultiShowUpload
        form={form}
        ComponentName="notice_demand"
        title="หนังสือบอกกล่าวงทวงถาม ครั้งที่ "
      />
      <ShowUpload title="ประกาศหนังสือพิมพ์" name={newspaper_announcement} />
      <ShowUpload title="รายการคำนวณยอดหนี้" name={debt_calculation_list} />
      <MultiUploadwithText ComponentName="etc_book4" title="อื่น ๆ " />
      {Title("ขั้นตอนฟ้องคดี/ดำเนินคดี")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <ShowUpload title="คำฟ้อง" name={indictment} />
      <ShowUpload title="เอกสารท้ายคำฟ้อง" name={end_plaint} />
      <ShowUpload title="ใบแต่งทนายความ" name={paper_lawyer} />
      <ShowUpload title="บัญชีระบุพยาน" name={witness_account} />
      <ShowUpload
        title="คำแถลงปิดหมาย พร้อมเอกสารที่อยู่จำเลย"
        name={closing_statement}
      />
      <ShowUpload
        title="คำร้องขอเลื่อนกำหนดนัดพิจารณา"
        name={request_consideration}
      />
      <ShowUpload
        title="คำแถลงขออ้างเอกสารท้ายคำฟ้องแทนการส่งสำเนาเอกสาร"
        name={statement_request_documents}
      />
      <MultiShowUpload
        form={form}
        ComponentName="request_document1"
        title="คำร้อง / คำขอ / คำแถลง ครั้งที่ "
      />
      <ShowUpload title="หมายเรียกจำเลย" name={summons_defendant} />
      <ShowUpload title="รูปภาพ ,ภาพถ่าย,แผนที่" name={pictures_photos_maps} />
      <ShowUpload title="รายงานการยึด , อายัดทรัพย์" name={report_seizure} />
      <ShowUpload title="ประกาศขายทอดตลาด" name={auction_announcement} />
      <ShowUpload title="บัญชีรับจ่ายเงิน" name={payment_account} />
      <MultiShowUpload
        form={form}
        ComponentName="receipt"
        title="ใบเสร็จรับเงิน ครั้งที่ "
      />
      <MultiUploadwithText ComponentName="etc_book1" title="อื่น ๆ " />
      {Title("ขั้นตอนนัดพิจารณา/พิพากษา/บังคับคดี")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <MultiShowUpload
        form={form}
        ComponentName="proceedings_report"
        title="รายงานกระบวนพิจารณา ครั้งที่ "
      />
      <ShowUpload title="คำพิพากษา" name={sentence_document} />
      <ShowUpload title="คำบังคับ" name={decree_document} />
      <ShowUpload title="บัญชีค่าฤชาธรรมเนียม" name={fee_account} />
      <ShowUpload title="หมายบังคับคดี" name={warrant_execution} />
      <ShowUpload
        title="หนังสือรับรองคดีถึงที่สุด"
        name={certificate_final_case}
      />
      <MultiShowUpload
        form={form}
        ComponentName="request_document2"
        title="คำร้อง / คำขอ / คำแถลง ครั้งที่ "
      />
      <MultiShowUpload
        form={form}
        ComponentName="court_order"
        title="คำสั่งศาล ครั้งที่ "
      />
      <MultiShowUpload
        form={form}
        ComponentName="upload_receipt"
        title="ใบเสร็จรับเงิน ครั้งที่ "
      />
      <MultiUploadwithText ComponentName="etc_book2" title="อื่น ๆ " />
      {Title("ขั้นตอนติดต่อกับลูกความ")}
      <ShowUpload title="หนังสือเบิกเอกสาร" name={withdraw_documents} />
      <MultiShowUpload
        form={form}
        ComponentName="opinion_book"
        title="หนังสือความเห็น ครั้งที่ "
      />
      <MultiShowUpload
        form={form}
        ComponentName="withdrawal_book"
        title="หนังสือเบิกเงิน ครั้งที่ "
      />
      <MultiUploadwithText ComponentName="etc_book3" title="อื่น ๆ " />
    </div>
  );
};

export default Upload;
