import Main from "./Main";
import { CategoryTitle } from "../../components/create_task/TitleStyle";
import MainTextArea from "./MainTextArea";
import numeral from "numeral";

const Relation = ({ form, STRAPI_URL }) => {
  return (
    <div>
      <div className="p-4 bg-white rounded-md my-4 shadow">
        {CategoryTitle("หมวดนิติสัมพันธ์")}
        <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
        {form.legal_relation.map((row, index) => {
          //-----------------------------------------------------------------------------//
          //คำนวณ
          const principle = numeral(row.principle).value();
          const interest = numeral(row.interest).value();

          const total_cost = principle + interest;
          const c_total_cost = numeral(total_cost).format("0,0.00");
          //-----------------------------------------------------------------------------//

          return (
            <div>
              <div className="text-xl mb-2 text-green-500 ">
                นิติสัมพันธ์/เอกสาร/สัญญา ครั้งที่ {index + 1}
              </div>
              <Main title="เลขที่บัญชี" name={row.account_number} />
              <Main title="เงินต้น" name={row.principle} />
              <Main title="ดอกเบี้ย" name={row.interest} />
              <Main title="ยอดรวม" name={c_total_cost} />
              <Main title="ธนาคาร" name={row.bank} />
              <Main title="สาขา" name={row.branch} />
              <Main title="เขต" name={row.county} />
              <Main title="ประเภทสินเชื่อ" name={row.type_loan} />
              <Main title="ประเภทหลักประกัน" name={row.collateral_type} />
              <MainTextArea
                title="รายละเอียดคนค้ำประกัน"
                name={row.detail_type}
              />
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        })}
        <Main title="เรื่อง" name={form.fault} />
      </div>
    </div>
  );
};

export default Relation;
