import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import LawyerInfo from "./LawyerInfo";
import Profile from "./Profile";
import Relation from "./Relation";
import Agency from "./Agency";
import Date from "./Date";
import Upload from "./Upload";
import Payment from "./Payment";

import EditProfile from "../../components/create_task/EditProfile";
import EditCourt from "../../components/create_task/EditCourt";
import EditDoc from "../../components/create_task/EditDoc";
import EditOperation from "../../components/create_task/EditOperation";
import EditClient from "../../components/create_task/EditClient";
import EditPayment from "../../components/create_task/EditPayment";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

//APOLLO
import initialApollo from "../../apollo";
import QUERY_TASK_BY_ID from "../../apollo/queries/queryTaskById";

const Task = () => {
  const params = useParams();
  const router = useHistory();
  const { taskId } = params;

  const [tabs, setTabs] = useState(0);

  const { client } = initialApollo();

  const {
    form,
    setField,
    setForm,
    STRAPI_URL,
    editing,
    setEditing,
  } = useContext(TaskContext);

  useEffect(() => {
    if (!taskId) return;

    const load = async () => {
      try {
        let { data } = await client.query({
          query: QUERY_TASK_BY_ID,
          variables: {
            id: taskId,
          },
        });
        // console.log(`data.task: `, data.task);
        setForm({
          ...data.task,
          lawyer_name: {
            label: data?.task?.lawyer_name,
          },
        });
      } catch (error) {
        // ดัก TOKEN หมดอายุหรือ ERROR อื่นๆ กลับไปหน้าล็อคอินใหม่
        localStorage.clear();
        router.push("/user");
      }
    };

    load();
  }, [taskId]);

  return (
    <div className="bg-gray-200 relative font-kanit">
      <div className="bg-gray-100 pb-32 max-w-3xl w-full mx-auto md:bg-gray-200">
        <div className="p-4 mx-0 bg-gray-50">
          <Tabs selectedIndex={tabs} onSelect={(index) => setTabs(index)}>
            <TabList>
              <Tab form={form} setField={setField}>
                หมวดคู่ความ
              </Tab>
              <Tab>หมวดนิติสัมพันธ์</Tab>
              <Tab>หมวดหน่วยงาน</Tab>
              <Tab>หมวดการดำเนินงาน</Tab>
              <Tab>หมวดอัปโหลดเอกสาร</Tab>
              <Tab>หมวดการเงิน</Tab>
            </TabList>
            <LawyerInfo
              taskId={taskId}
              editing={editing}
              setEditing={setEditing}
              form={form}
            />

            <TabPanel>
              {!editing && (
                <h2>
                  <Profile form={form} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditProfile />
                </h2>
              )}
            </TabPanel>
            <TabPanel>
              {!editing && (
                <h2>
                  <Relation form={form} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditCourt />
                </h2>
              )}
            </TabPanel>
            <TabPanel>
              {!editing && (
                <h2>
                  <Agency form={form} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditDoc />
                </h2>
              )}
            </TabPanel>
            <TabPanel>
              {!editing && (
                <h2>
                  <Date form={form} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditOperation />
                </h2>
              )}
            </TabPanel>
            <TabPanel>
              {!editing && (
                <h2>
                  <Upload form={form} STRAPI_URL={STRAPI_URL} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditClient />
                </h2>
              )}
            </TabPanel>
            <TabPanel>
              {!editing && (
                <h2>
                  <Payment form={form} />
                </h2>
              )}
              {editing && (
                <h2>
                  <EditPayment />
                </h2>
              )}
            </TabPanel>
          </Tabs>
          {editing && (
            <div className="relative">
              <button
                onClick={() => setTabs(tabs + 1)}
                hidden={tabs === 5}
                className="absolute right-2 top-8 bg-blue-500 rounded p-1 w-28 text-md text-white mr-2 text-center"
              >
                ถัดไป
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
