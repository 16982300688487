import gql from "graphql-tag";

const QUERY_TASKS = gql`
  query Tasks($where: JSON, $limit: Int, $start: Int) {
    tasks(where: $where, sort: "createdAt:desc", limit: $limit, start: $start) {
      id
      acceptance_code
      plaintiffComponent {
        id
        name
      }
      defendantComponent {
        id
        name
      }
      complainantComponent {
        id
        name
      }
      suffererComponent {
        id
        name
      }
      legal_relation {
        id
        account_number
      }
      lawyer_name
      status
      agencyComponent {
        id
        agency_name
        black_case
        red_case
      }
    }
  }
`;

export default QUERY_TASKS;
