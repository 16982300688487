import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { DebounceInput } from "react-debounce-input";

// COMPONENTS
import CelesLoading from "./lib/CelesLoading";

//APOLLO
import initialApollo from "./apollo";

//GRAPHQL
import QUERY_TASKS from "./apollo/queries/queryTasks";

import TaskCard from "./components/TaskCard";
import LoadingMore from "./components/LoadingMore";

function Home() {
  const { client } = initialApollo();
  const router = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchchValue, setSearchValue] = useState("");
  const [isRefetch, setIsRefetch] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [_where, setWhere] = useState({
    status_ne: "ปิดงาน",
  });
  const [start, setStart] = useState(0);
  const [filter, setFilter] = useState("");

  let _limit = 10;

  useEffect(() => {
    if (!searchchValue) {
      load();
      setIsRefetch(false);
    }
  }, [searchchValue]);

  const fetchMoreData = async () => {
    if (isRefetch) return;
    if (!hasMore) return;

    const where = { ..._where };
    // let start = data?.length || 0;
    const roleName = localStorage.getItem("roleName");
    const userId = localStorage.getItem("uid");
    const userName = localStorage.getItem("userName");
    let newWhere = {
      ...where,
    };
    if (roleName === "User") {
      newWhere.plaintiff_name = userName;
    }
    let queryByLawyer = {
      query: QUERY_TASKS,
      variables: {
        where: {
          ...newWhere,
          users_permissions_user: userId,
        },
        start,
        limit: _limit,
      },
    };
    const queryBySuperAdmin = {
      query: QUERY_TASKS,
      variables: {
        where: newWhere,
        start,
        limit: _limit,
      },
    };
    const { data: dataMore } = await client.query(
      roleName === "Lawyer" ? queryByLawyer : queryBySuperAdmin
    );

    if (!dataMore?.tasks?.length) {
      setHasMore(false);
      return;
    }

    const tasks = dataMore?.tasks;

    let formatDatas = tasks.map((task) => {
      const ret = {
        id: task?.id,
        code: task?.acceptance_code,
        lawyer_name: task?.lawyer_name,
        status: task?.status,
        plaintiff:
          task?.plaintiffComponent && task?.plaintiffComponent[0]?.name,
        complainant:
          task?.complainantComponent && task?.complainantComponent[0]?.name,
        sufferer: task?.suffererComponent && task?.suffererComponent[0]?.name,
        defendant:
          task?.defendantComponent && task?.defendantComponent[0]?.name,
        bank: task?.legal_relation[0]?.account_number,
        agency: task?.agencyComponent[0]?.agency_name,
        blackCode: task?.agencyComponent[0]?.black_case,
        redcode: task?.agencyComponent[0]?.red_case,
      };
      return ret;
    });

    if (localStorage.getItem("roleName") === "User") {
      formatDatas = formatDatas.filter((task) => {
        const userName = localStorage.getItem("userName");
        return (
          task.plaintiff === userName ||
          task.sufferer === userName ||
          task.complainant === userName ||
          task.defendant === userName
        );
      });
    }
    setStart((prev) => prev + _limit);
    setData(data.concat(formatDatas));
  };

  const load = async (where = _where, limit = _limit) => {
    try {
      setLoading(true);
      const roleName = localStorage.getItem("roleName");
      const userId = localStorage.getItem("uid");
      const userName = localStorage.getItem("userName");
      let newWhere = {
        ...where,
      };
      if (roleName === "User") {
        newWhere.plaintiff_name = userName;
      }
      let queryByLawyer = {
        query: QUERY_TASKS,
        variables: {
          where: {
            ...newWhere,
            users_permissions_user: userId,
          },
          limit,
        },
      };
      const queryBySuperAdmin = {
        query: QUERY_TASKS,
        variables: {
          where: newWhere,
          limit,
        },
      };
      const { data } = await client.query(
        roleName === "Lawyer" ? queryByLawyer : queryBySuperAdmin
      );
      const tasks = data?.tasks;

      let formatDatas = tasks.map((task) => {
        const ret = {
          id: task?.id,
          code: task?.acceptance_code,
          lawyer_name: task?.lawyer_name,
          status: task?.status,
          plaintiff:
            task?.plaintiffComponent && task?.plaintiffComponent[0]?.name,
          complainant:
            task?.complainantComponent && task?.complainantComponent[0]?.name,
          sufferer: task?.suffererComponent && task?.suffererComponent[0]?.name,
          defendant:
            task?.defendantComponent && task?.defendantComponent[0]?.name,
          bank: task?.legal_relation[0]?.account_number,
          agency: task?.agencyComponent[0]?.agency_name,
          blackCode: task?.agencyComponent[0]?.black_case,
          redcode: task?.agencyComponent[0]?.red_case,

          //อยากได้ Search อะไรมาใส่ตรงนี้
        };
        return ret;
      });

      if (localStorage.getItem("roleName") === "User") {
        formatDatas = formatDatas.filter((task) => {
          const userName = localStorage.getItem("userName");
          return (
            task.plaintiff === userName ||
            task.sufferer === userName ||
            task.complainant === userName ||
            task.defendant === userName
          );
        });
      }
      setData(formatDatas);
      setLoading(false);
    } catch (error) {
      // ดัก TOKEN หมดอายุหรือ ERROR อื่นๆ กลับไปหน้าล็อคอินใหม่
      localStorage.clear();
      router.push("/user");
      console.error(error);
    }
  };

  const onChangePage = (id) => {
    const roleName = localStorage.getItem("roleName");
    switch (roleName) {
      case "User":
        return router.push(`/taskUser/${id}`);
      default:
        return router.push(`/task/${id}`);
    }
  };

  // const onClickShowStatus = async (status) => {
  //   setShowFinish(!showFinish);

  //   switch (status) {
  //     case "ทั้งหมด":
  //       setHasMore(false);
  //       setWhere({});
  //       await load({}, -1);
  //       break;
  //     case "ปิดงาน":
  //       setWhere({
  //         status_ne: "ปิดงาน",
  //       });
  //       await load({
  //         status_ne: "ปิดงาน",
  //       });
  //       setHasMore(true);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const editSearchTerm = async (e) => {
    const value = e.target.value;
    let where = { ..._where };

    where = {
      ...where,
      _q: value,
    };
    setWhere(where);

    if (value) {
      setLoading(true);
      setIsRefetch(true);
      load(where);
      setLoading(false);
    } else {
      setHasMore(true);
    }
    setSearchValue(value);
  };

  const onClickFilter = (filter, keyWhere) => (e) => {
    let where = {
      _q: _where._q,
      [keyWhere]: "ปิดงาน",
    };
    setStart(0);
    setFilter(filter);
    setWhere(where);
    load(where);
  };

  const dynamicSearch = () => {
    // if (!data) return [];
    var search_result = data.filter(
      (data) =>
        ///อันนี้คือตัว return ที่จะเอามา search <ret ข้างบน เอาตัว ข้างหน้ามา>
        data?.code?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.bank?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.lawyer_name
          ?.toLowerCase()
          .includes(searchchValue.toLowerCase()) ||
        data?.status?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.plaintiff?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.defendant?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.account_number
          ?.toLowerCase()
          .includes(searchchValue.toLowerCase()) ||
        data?.agency?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.blackCode?.toLowerCase().includes(searchchValue.toLowerCase()) ||
        data?.redcode?.toLowerCase().includes(searchchValue.toLowerCase())
    );
    if (statusFilter != "all")
      search_result = search_result.filter((data) =>
        data.status.includes(statusFilter)
      );
    return search_result;
    // return [];
  };

  const Result = () => {
    // const ret = dynamicSearch();
    const style2 =
      "mr-2  rounded-full px-4 text-md  cursor-pointer tracking-wider text-gray-700 border-gray-700 border-2 hover:bg-gray-700 hover:text-white transition ease-out duration-700  ";
    const style =
      "mr-2  rounded-full px-4 text-md  cursor-pointer tracking-wider text-white bg-gray-700 border-gray-700 border-2 hover:bg-white hover:text-gray-700 transition ease-out duration-700  ";

    // const buttonTitle = "แสดงทั้งหมด";
    return (
      <div>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />

        <meta name="viewport" content="width=device-width" />
        <meta charSet="utf-8" />
        <title>Ariyatham Law</title>
        <meta property="og:title" content="Ariyatham Law" />
        <meta property="og:description" content="Ariyatham Law" />
        <meta property="og:image" content="./ariya2.png" />

        <div className="flex flex-row conter-center justify-between mx-4 sm:mx-8">
          <div className="text-xl text-gray-700  font-bold">
            {/* งานทั้งหมด : {data.length} งาน */}
          </div>

          <div className="flex flex-row">
            {filter === "ปิดงาน" ? (
              <div
                className={style2}
                onClick={onClickFilter("แสดงทั้งหมด", "status_ne")}
              >
                แสดงทั้งหมด
              </div>
            ) : (
              <div
                className={style}
                onClick={onClickFilter("ปิดงาน", "status_eq")}
              >
                ปิดงาน
              </div>
            )}
            {/* <button className={style} onClick={() => router.push(`/showAll`)}>
              {buttonTitle}
            </button> */}

            {/* <svg
              className="w-auto h-6 text-gray-700 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
              />
            </svg> */}

            {localStorage.getItem("roleName") !== "User" && (
              <a href="/newtask">
                <svg
                  className="h-12 z-10 fixed  bottom-20  right-6  sm:right-44 xl:right-96  w-auto text-white  bg-green1 hover:bg-green2   rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </a>
            )}
          </div>
        </div>

        <InfiniteScroll
          pageStart={start}
          loadMore={fetchMoreData}
          hasMore={hasMore}
          loader={data.length > 4 && hasMore ? <LoadingMore /> : <div></div>}
        >
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => onChangePage(item.id)}
              >
                <TaskCard
                  id={item.id}
                  code={item.code}
                  lawyer_name={item.lawyer_name}
                  status={item.status}
                  plaintiff={item.plaintiff}
                  defendant={item.defendant}
                  bank={item.bank}
                  agency={item.agency}
                  blackCode={item.blackCode}
                  redCode={item.redcode}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  // if (loading) {
  //   return <CelesLoading />;
  // }

  return (
    <div
      className="bg-gray-100 w-screen pb-16 min-h-screen "
      // style={{
      //   backgroundImage: "url(" + "bg2.jpg" + ")",
      //   backgroundPosition: "center",
      //   backgroundSize: "cover",
      //   backgroundRepeat: "repeat-y",
      // }}
    >
      <div className="max-w-3xl mx-auto">
        <div className="py-4">
          <div className="bg-white flex items-center md:rounded-3xl shadow-xl mx-2 sm:mx-8">
            <DebounceInput
              minLength={0}
              debounceTimeout={500}
              className="rounded-l-full w-full py-4 px-6 text-gray-700 leading-tight focus:outline-none"
              id="search"
              type="text"
              placeholder="Search ..."
              value={searchchValue}
              onChange={editSearchTerm}
            />
            <div className="p-4">
              <svg
                className="bg-green-600 text-white rounded-full p-2 mx-4 "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        {loading ? <CelesLoading /> : <Result />}
      </div>
    </div>
  );
}

export default Home;
