import React, { Component } from "react";
import { useForm } from "react-hook-form";

const User = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);

  return (
    <div className="sm:bg-user2 bg-user1 sm:w-full sm:max-w-3xl sm:mx-auto">
      <div className="p-5 ">
        

        <div className="flex flex-col sm:mt-52  mt-28">
          <div className="place-self-center text-4xl  sm:text-5xl font-black text-white  ">
            Congratulation!
          </div>

          <img
            className="mt-20 place-self-center h-40 w-auto"
            src="/ariyatham.PNG"
          ></img>

          <img
            className="mt-8 place-self-center h-20 w-20 "
            src="/Done.png"
          ></img>

          <div className="my-4 place-self-center text-3xl font-black">
            Success !{" "}
          </div>
          <div className="py-20 sm:py-10 xl:py-10 "></div>
        </div>
      </div>
    </div>
  );
};
export default User;
