import React, { useState, useEffect, useCallback } from "react";
import Loading from "../../lib/CelesLoading";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//TAP
import Defendant from "../../pages/new_task/Defendant";
import Court from "../../pages/new_task/Court";
import Document from "../../pages/new_task/Document";
import Payment from "../../pages/new_task/Payment";
import Client from "../../pages/new_task/Client";
import Operation from "../../pages/new_task/Operation";

//GOOGLE
import { addGoogleEvent } from "../../pages/task/GoogleEvent";

//APOLLO
import controller from "../../apollo/controller";
import UPDATE_TASK from "../../apollo/mutation/updateTask";
import initialApollo from "../../apollo";

const Stepper = ({
  step,
  // setTap,
  form,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tabs, setTabs] = useState(0);

  const { client } = initialApollo();

  const router = useHistory();

  if (loading) return <Loading />;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const uploadFormData = async (files, taskId, field) => {
    if (!files || files === undefined) return;

    if (files?.length > 0) {
      for (const file of files) {
        if (!file) continue;
        const formDataUpload = new FormData();
        formDataUpload.append("refId", taskId);
        formDataUpload.append("ref", "tasks");
        formDataUpload.append("field", field);
        formDataUpload.append("files", file);
        await controller.uploadFile(formDataUpload);
      }
      return;
    }

    const formDataUpload = new FormData();
    formDataUpload.append("refId", taskId);
    formDataUpload.append("ref", "tasks");
    formDataUpload.append("field", field);
    formDataUpload.append("files", files);
    await controller.uploadFile(formDataUpload);
  };

  const uploadFormDataComponent = async (components = [], field = "file") => {
    if (!components.length || components === undefined || !components) return;

    const newComponents = await Promise.all(
      components
        .map(async (component, index) => {
          if (!component[field] || !component) return;
          const formDataUpload = new FormData();
          formDataUpload.append("files", component[field]);
          const { data } = await controller.uploadFile(formDataUpload);
          return { ...component, [field]: data[0].id };
        })
        .filter((component) => component)
    );
    return newComponents;
  };

  const onSubmit = async () => {
    setLoading(true);
    form.notice_demand = await uploadFormDataComponent(
      form.notice_demand,
      "file"
    );

    form.request_document1 = await uploadFormDataComponent(
      form.request_document1,
      "file"
    );

    form.receipt = await uploadFormDataComponent(form.receipt, "file");
    form.etc_book1 = await uploadFormDataComponent(form.etc_book1, "file");
    form.proceedings_report = await uploadFormDataComponent(
      form.proceedings_report,
      "file"
    );
    form.request_document2 = await uploadFormDataComponent(
      form.request_document2,
      "file"
    );
    form.court_order = await uploadFormDataComponent(form.court_order, "file");
    form.upload_receipt = await uploadFormDataComponent(
      form.upload_receipt,
      "file"
    );
    form.etc_book2 = await uploadFormDataComponent(form.etc_book2, "file");
    form.opinion_book = await uploadFormDataComponent(
      form.opinion_book,
      "file"
    );
    form.etc_book3 = await uploadFormDataComponent(form.etc_book3, "file");
    form.etc_book4 = await uploadFormDataComponent(form.etc_book4, "file");
    form.withdrawal_book = await uploadFormDataComponent(
      form.withdrawal_book,
      "file"
    );

    // ////////////////
    const { data } = await controller.createTask(form);
    const { id } = data?.createTask?.task;
    await uploadFormData(form.loan_agreement, id, "loan_agreement");
    await uploadFormData(form.surety_contract, id, "surety_contract");
    await uploadFormData(form.mortgage, id, "mortgage");
    await uploadFormData(form.id_card, id, "id_card");
    await uploadFormData(
      form.newspaper_announcement,
      id,
      "newspaper_announcement"
    );
    await uploadFormData(
      form.debt_calculation_list,
      id,
      "debt_calculation_list"
    );
    await uploadFormData(form.indictment, id, "indictment");
    await uploadFormData(form.end_plaint, id, "end_plaint");
    await uploadFormData(form.paper_lawyer, id, "paper_lawyer");
    await uploadFormData(form.witness_account, id, "witness_account");
    await uploadFormData(form.closing_statement, id, "closing_statement");
    await uploadFormData(
      form.request_consideration,
      id,
      "request_consideration"
    );
    await uploadFormData(
      form.statement_request_documents,
      id,
      "statement_request_documents"
    );
    await uploadFormData(form.summons_defendant, id, "summons_defendant");
    await uploadFormData(form.pictures_photos_maps, id, "pictures_photos_maps");
    await uploadFormData(form.report_seizure, id, "report_seizure");
    await uploadFormData(form.auction_announcement, id, "auction_announcement");
    await uploadFormData(form.payment_account, id, "payment_account");
    await uploadFormData(form.sentence_document, id, "sentence_document");
    await uploadFormData(form.decree_document, id, "decree_document");
    await uploadFormData(form.fee_account, id, "fee_account");
    await uploadFormData(form.warrant_execution, id, "warrant_execution");
    await uploadFormData(
      form.certificate_final_case,
      id,
      "certificate_final_case"
    );
    await uploadFormData(form.withdraw_documents, id, "withdraw_documents");

    addGoogleEvent({ taskId: id, form, router });
  };

  return (
    <div>
      <div className="flex justify-between pb-8 items-center">
        <a
          href="/"
          className="bg-blue-500 rounded p-1 w-28 text-md text-white mr-2 text-center"
        >
          กลับสู่หน้าหลัก
        </a>
        <button
          className="bg-blue-500 rounded p-1 w-28 text-md text-white mr-2 text-center"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
      <Tabs selectedIndex={tabs} onSelect={(index) => setTabs(index)}>
        <TabList>
          <Tab>หมวดคู่ความ</Tab>
          <Tab>หมวดนิติสัมพันธ์</Tab>
          <Tab>หมวดหน่วยงาน</Tab>
          <Tab>หมวดการดำเนินงาน</Tab>
          <Tab>หมวดอัปโหลดเอกสาร</Tab>
          <Tab>หมวดการเงิน</Tab>
        </TabList>

        <TabPanel>
          <h2>
            <Defendant />
          </h2>
        </TabPanel>
        <TabPanel>
          <h2>
            <Court />
          </h2>
        </TabPanel>
        <TabPanel>
          <h2>
            <Document />
          </h2>
        </TabPanel>
        <TabPanel>
          <h2>
            <Operation />
          </h2>
        </TabPanel>
        <TabPanel>
          <h2>
            <Client />
          </h2>
        </TabPanel>
        <TabPanel>
          <h2>
            <Payment />
          </h2>
        </TabPanel>
      </Tabs>
      <div className="relative">
        <button
          onClick={() => setTabs(tabs + 1)}
          hidden={tabs === 5}
          className="absolute right-2 top-8 bg-blue-500 rounded p-1 w-28 text-md text-white mr-2 text-center"
        >
          ถัดไป
        </button>
        <button
          hidden={tabs < 5}
          className="absolute right-2 top-8 bg-blue-500 rounded p-1 w-28 text-md text-white mr-2 text-center"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Stepper;
