const Main = ({ title = " ", name = " " }) => {
  if (!name) return <div></div>;

  return (
    <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
      <div>{title}</div>
      <div>
        <strong className="text-gray-700 text-right">{name}</strong>
      </div>
    </div>
  );
};
export default Main;
