const ProductCard = ({
  code,
  plaintiff,
  defendant,
  bank,
  lawyer_name = "",
  status = "",
  agency = "",
  blackCode = "",
  redCode = "",
}) => {
  const statusLabel = () => {
    if (status == "รับเรื่อง") return " bg-yellow-100 text-yellow-600";
    if (status == "ชะลอฟ้อง") return " bg-gray-100 text-gray-600";
    if (status == "ชะลองาน") return " bg-blue-100 text-blue-600";
    if (status == "นัดพิจารณา") return " bg-indigo-100 text-indigo-600";
    if (status == "พิพากษา") return " bg-orange-100 text-orange-600";
    if (status == "บังคับคดี") return " bg-red-100 text-red-600";
    if (status == "ปิดงาน") return " bg-green-100 text-green-600";
    return " bg-green-100 text-green-600";
  };
  //ทำหน้าแรก ให้มันสวยๆ ตรงนี้
  return (
    <div className="bg-white w-728px shadow-lg md:rounded-3xl p-4 m-2 sm:mx-8 font-kanit relative">
      <div className="grid md:grid-cols-2">
        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../shape.svg"
          />
          {code}
        </div>
        <div
          className={
            " absolute top-0 right-0 p-1 mr-2 pr-2 px-2 mt-2  rounded-lg text-lg w-28  text-center" +
            statusLabel()
          }
        >
          {status}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../museum.svg"
          />
          {plaintiff}
        </div>
        <div className="flex">
          {" "}
          <svg
            className="h-4 mr-2 mt-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
            />
          </svg>{" "}
          {agency}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../defendant.svg"
          />
          {defendant}
        </div>
        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../file.svg"
          />
          <div className="text-black">{blackCode} </div>
          <div> - </div>
          <div className="text-red-600">{redCode}</div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../bank.svg"
          />
          {bank}
        </div>

        <div className="flex">
          <img
            className=" h-4  w-4  flex-row  mr-2 mt-1 items-center"
            src="../lawyer.svg"
          />{" "}
          {lawyer_name}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
