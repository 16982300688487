import { TaskContext } from "../../context/TaskContext";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";

const TextArea = ({
  title = "",
  name,
  value,
  setFieldMulti,
  setField,
  index,
  primaryField,
}) => {
  const onChange = (e) => {
    if (primaryField) {
      return setFieldMulti(name, primaryField, index)(e.target.value);
    }
    setField(name)(e.target.value);
  };

  return (
    <div>
      {Label([title])}
      <textarea
        value={value}
        onChange={onChange}
        className="outline-none p-2 rounded-md  text-gray-700 border border-gray-200 mb-2 w-full"
        rows={2}
      />
    </div>
  );
};
export default TextArea;
