import React, { useState, useEffect, useCallback } from "react";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";

// CONTEXT
import { TaskContext } from "../../context/TaskContext";

const CelesMultiUpload = ({ label, setFieldMulti, primaryField, index }) => {
  const { form } = useContext(TaskContext);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // setField(name)(acceptedFiles);

      acceptedFiles.map((file) =>
        setFieldMulti("file", primaryField, index)(file)
      );
    },
  });

  return (
    <section>
      <div
        className="outline-none focus:ring ring-green-500"
        {...getRootProps({ className: "dropzone" })}
      >
        <div className="cursor-pointer py-4 rounded-md  bg-white border border-gray-200 mb-2">
          <img className=" h-6 w-6 m-auto " src="../cloud.svg" />
          <input
            className="outline-none focus:ring ring-green-500"
            {...getInputProps()}
          />
          <div className="text-center text-blue-500 text-sm">
            {label} : SELECT FILE
          </div>
        </div>
      </div>
      <aside>
        <ul>
          {!acceptedFiles?.length &&
            form[primaryField][index]?.file?.length > 0 && (
              <li>
                <a
                  target="_blank"
                  className="text-blue-500 hover:text-blue-300 duration-300"
                  href={`https://admin.ariyatham.com${form[primaryField][index].file[0].url}`}
                >
                  {form[primaryField][index].file[0].name}
                </a>
              </li>
            )}
          {acceptedFiles.map((file) => (
            <li key={file.path} className="text-blue-500">
              {file.path}
            </li>
          ))}
        </ul>
      </aside>
    </section>
  );
};

export default CelesMultiUpload;
