import React, { useState, useEffect, useCallback } from "react";
import Loading from "../../lib/CelesLoading";
import { differenceInCalendarDays } from "date-fns";
import { useHistory } from "react-router-dom";

import { addGoogleEvent } from "./GoogleEvent";

//APOLLO
import controller from "../../apollo/controller";
import axios from "axios";

const LawyerInfo = ({ form, editing, setEditing, taskId }) => {
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const statusLabel = (status) => {
    if (status == "รับเรื่อง") return " bg-yellow-100 text-yellow-600";
    if (status == "ชะลอฟ้อง") return " bg-gray-100 text-gray-600";
    if (status == "ชะลองาน") return " bg-blue-100 text-blue-600";
    if (status == "นัดพิจารณา") return " bg-indigo-100 text-indigo-600";
    if (status == "พิพากษา") return " bg-orange-100 text-orange-600";
    if (status == "บังคับคดี") return " bg-red-100 text-red-600";
    if (status == "ปิดงาน") return " bg-green-100 text-green-600";
    return " bg-green-100 text-green-600";
  };
  const checkParties = (form) => {
    const parties = form?.parties;

    if (parties == "โจทก์")
      return <div>โจทก์ :{form?.plaintiffComponent[0]?.name}</div>;
    if (parties == "จำเลย")
      return <div>จำเลย :{form?.defendantComponent[0]?.name}</div>;
    if (parties == "ผู้ร้อง")
      return <div>ผู้ร้อง : {form?.complainantComponent[0]?.name}</div>;
    if (parties == "ผู้เสียหาย")
      return <div>ผู้เสียหาย : {form?.suffererComponent[0]?.name}</div>;
  };
  if (loading) return <Loading />;

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const deleteTask = async (id) => {
    if (!window.confirm("ยืนยันการลบ?")) {
      return;
    } else {
      setLoading(true);

      await controller.deleteTask(id);
      router.push(`/`);
    }
  };

  const uploadFormData = async (files, taskId, field) => {
    if (!files || files.hasOwnProperty("__typename") || !files.length) return;
    if (files?.length > 0) {
      for (const file of files) {
        if (file?.delete) {
          await axios.delete(
            `https://admin.ariyatham.com/upload/files/${file.id}`
          );
        } else {
          if (!file || file.hasOwnProperty("__typename")) continue;
          const formDataUpload = new FormData();
          formDataUpload.append("refId", taskId);
          formDataUpload.append("ref", "tasks");
          formDataUpload.append("field", field);
          formDataUpload.append("files", file);
          await controller.uploadFile(formDataUpload);
        }
      }
    } else {
      const formDataUpload = new FormData();
      formDataUpload.append("refId", taskId);
      formDataUpload.append("ref", "tasks");
      formDataUpload.append("field", field);
      formDataUpload.append("files", files);
      await controller.uploadFile(formDataUpload);
    }
  };

  const uploadFormDataComponent = async (components = [], field = "file") => {
    if (!components.length || components === undefined || !components) return;

    const newComponents = await Promise.all(
      components
        .map(async (component, index) => {
          const isArray = Array.isArray(component[field]);
          if (!component[field] || (isArray && !component[field]?.length)) {
            return;
          } else if (component[field][0]?.__typename === "UploadFile") {
            return {
              ...component,
              [field]: component[field][0].id,
            };
          } else {
            const formDataUpload = new FormData();
            formDataUpload.append("files", component.file);
            const { data } = await controller.uploadFile(formDataUpload);
            return {
              ...component,
              [field]:
                component[field].length > 0
                  ? [(component[field], data[0].id)]
                  : data[0].id,
            };
          }
        })
        .filter((component) => component)
    );
    return newComponents;
  };

  const onSubmit = async (id) => {
    setLoading(true);

    form.notice_demand = await uploadFormDataComponent(
      form.notice_demand,
      "file"
    );

    form.request_document1 = await uploadFormDataComponent(
      form.request_document1,
      "file"
    );

    form.receipt = await uploadFormDataComponent(form.receipt, "file");
    form.etc_book1 = await uploadFormDataComponent(form.etc_book1, "file");
    form.proceedings_report = await uploadFormDataComponent(
      form.proceedings_report,
      "file"
    );
    form.request_document2 = await uploadFormDataComponent(
      form.request_document2,
      "file"
    );
    form.court_order = await uploadFormDataComponent(form.court_order, "file");
    form.upload_receipt = await uploadFormDataComponent(
      form.upload_receipt,
      "file"
    );
    form.etc_book2 = await uploadFormDataComponent(form.etc_book2, "file");
    form.opinion_book = await uploadFormDataComponent(
      form.opinion_book,
      "file"
    );
    form.etc_book3 = await uploadFormDataComponent(form.etc_book3, "file");
    form.etc_book4 = await uploadFormDataComponent(form.etc_book4, "file");
    form.withdrawal_book = await uploadFormDataComponent(
      form.withdrawal_book,
      "file"
    );

    const { data } = await controller.updateTask(form, id);

    await uploadFormData(form.loan_agreement, id, "loan_agreement");
    await uploadFormData(form.surety_contract, id, "surety_contract");
    await uploadFormData(form.mortgage, id, "mortgage");
    await uploadFormData(form.id_card, id, "id_card");
    await uploadFormData(
      form.newspaper_announcement,
      id,
      "newspaper_announcement"
    );
    await uploadFormData(
      form.debt_calculation_list,
      id,
      "debt_calculation_list"
    );
    await uploadFormData(form.indictment, id, "indictment");
    await uploadFormData(form.end_plaint, id, "end_plaint");
    await uploadFormData(form.paper_lawyer, id, "paper_lawyer");
    await uploadFormData(form.witness_account, id, "witness_account");
    await uploadFormData(form.closing_statement, id, "closing_statement");
    await uploadFormData(
      form.request_consideration,
      id,
      "request_consideration"
    );
    await uploadFormData(
      form.statement_request_documents,
      id,
      "statement_request_documents"
    );
    await uploadFormData(form.summons_defendant, id, "summons_defendant");
    await uploadFormData(form.pictures_photos_maps, id, "pictures_photos_maps");
    await uploadFormData(form.report_seizure, id, "report_seizure");
    await uploadFormData(form.auction_announcement, id, "auction_announcement");
    await uploadFormData(form.payment_account, id, "payment_account");
    await uploadFormData(form.sentence_document, id, "sentence_document");
    await uploadFormData(form.decree_document, id, "decree_document");
    await uploadFormData(form.fee_account, id, "fee_account");
    await uploadFormData(form.warrant_execution, id, "warrant_execution");
    await uploadFormData(
      form.certificate_final_case,
      id,
      "certificate_final_case"
    );
    await uploadFormData(form.withdraw_documents, id, "withdraw_documents");

    addGoogleEvent({ taskId: id, form, router });
  };

  //ข้างบนหัวของ หน้าโชว์
  const formatDate = (date) => {
    if (!date) return "";
    const dd = date.split("/")[0];
    const mm = date.split("/")[1];
    const yy = Number(date.split("/")[2]) - 543;

    return mm + "/" + dd + "/" + yy;
  };

  const getDurationDays = (curr, start, between, end) => {
    if (!start) return;

    if (!endDate && !bettweenDate) {
      const ret = differenceInCalendarDays(new Date(curr), new Date(start)) + 1;
      return ret;
    }

    if (endDate) {
      const ret = differenceInCalendarDays(new Date(end), new Date(start)) + 1;
      return ret;
    }

    if (bettweenDate) {
      const ret =
        differenceInCalendarDays(new Date(between), new Date(start)) + 1;
      return ret;
    }
  };

  const currentDate = new Date().toLocaleDateString("en-US");
  const startDate = formatDate(form.date_accept);
  const bettweenDate = formatDate(form.closing_date);
  const endDate = formatDate(form.closing_day2);

  // console.log("LAW", localStorage.getItem("roleName"));

  const durationDays = getDurationDays(
    currentDate,
    startDate,
    bettweenDate,
    endDate
  );

  const checkDate = (closing_date, closing_day2) => {
    if (!closing_date) return;

    if (!closing_day2) {
      return closing_date;
    } else {
      return closing_day2;
    }
  };

  const checkDelete = () => {
    const roleName = localStorage.getItem("roleName");
    if (["Lawyer", "Admin"].includes(roleName)) {
      return false;
    }

    return !editing;
  };

  return (
    <div className="text-lg text-gray-700 mx-4">
      <div className="flex justify-between ">
        <div className="mt-2">รหัสรับงาน : {form.acceptance_code}</div>
        <div
          className={
            "my-2 w-24 text-sm rounded-lg p-1  text-center " +
            statusLabel(form.status)
          }
        >
          {form.status}
        </div>
      </div>
      <div className="flex justify-between ">
        <div>ฝ่ายคู่ความ : {form.parties}</div>

        <div>วันที่รับงาน : {form.date_accept}</div>
      </div>
      <div className="flex justify-between ">
        <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
          <div className="text-gray-700 text-right">{checkParties(form)}</div>
        </div>
        <div>
          วันที่ปิดงาน : {checkDate(form.closing_date, form.closing_day2)}
        </div>
      </div>
      <div className="flex justify-between ">
        <div>จำเลย : {form.defendantComponent[0]?.name} </div>
        <div>ระยะเวลาในการดำเนินการ {durationDays} วัน</div>
      </div>
      <div className="flex justify-between ">
        <div>ทนาย : {form.lawyer_name?.label}</div>
        <div>ลงทะเบียน : {form.register}</div>
      </div>
      <div className="flex justify-between ">
        {checkDelete() ? (
          <div
            //
            className="  bg-red-500 rounded p-1 w-16 text-md text-white mt-2 text-center "
            onClick={() => deleteTask(taskId)}
          >
            ลบ
          </div>
        ) : (
          <div
            //
            className="  bg-gray-500 rounded p-1 w-16 text-md text-white mt-2 text-center cursor-not-allowed "
          >
            ลบ
          </div>
        )}
        {!editing && (
          <div
            className="  bg-blue-500 rounded p-1 w-16 text-md text-white mt-2 text-center cursor-pointer"
            onClick={() => setEditing(true)}
          >
            แก้ไข
          </div>
        )}
        {editing && (
          <div
            className="flex justify-end w-full
          "
          >
            <div
              className="bg-blue-500 rounded p-1 w-16 text-md text-white mt-2 text-center "
              onClick={() => onSubmit(taskId)}
            >
              Save
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LawyerInfo;
