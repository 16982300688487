import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Main from "./Main";
import MainTextArea from "./MainTextArea";
import numeral from "numeral";
import { CategoryTitle, Title } from "../../components/create_task/TitleStyle";
import initialApollo from "../../apollo/index";
import CelesLoading from "../../lib/CelesLoading";
import QUERY_TASK_BY_ID from "../../apollo/queries/queryTaskById";
import { TaskContext } from "../../context/TaskContext";
import Profile_Array from "./Profile_Array";
import _ from "lodash";
import MultiShowUpload from "./MultiShowUpload";
import ShowUpload from "./ShowUpload";

const getImageUrl = (datas = []) => {
  if (datas.length === 0) return;

  let index = 0;
  index = datas?.length - 1;

  return datas[index]?.url;
};

const ClientUser = () => {
  const params = useParams();
  const router = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { client } = initialApollo();
  const { taskId } = params;

  const { form, setForm, STRAPI_URL } = useContext(TaskContext);

  const indictment = getImageUrl(form?.indictment);
  const witness_account = getImageUrl(form?.witness_account);
  const sentence_document = getImageUrl(form?.sentence_document);
  const fee_account = getImageUrl(form?.fee_account);
  const decree_document = getImageUrl(form?.decree_document);
  const warrant_execution = getImageUrl(form?.warrant_execution);
  const certificate_final_case = getImageUrl(form?.certificate_final_case);

  const statusLabel = (status) => {
    if (status == "รับเรื่อง") return " bg-yellow-100 text-yellow-600";
    if (status == "ชะลอฟ้อง") return " bg-gray-100 text-gray-600";
    if (status == "ชะลองาน") return " bg-blue-100 text-blue-600";
    if (status == "นัดพิจารณา") return " bg-indigo-100 text-indigo-600";
    if (status == "พิพากษา") return " bg-orange-100 text-orange-600";
    if (status == "บังคับคดี") return " bg-red-100 text-red-600";
    if (status == "ปิดงาน") return " bg-green-100 text-green-600";
    return "";
  };

  const DateMulti = ({ title, ComponentName }) => {
    const data = form[ComponentName] || [];
    return (
      <div>
        {data.map((row, index) => {
          if (!row.date) return <div></div>;
          return (
            <div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                <div>
                  {title} {index + 1}
                </div>
                <div>
                  <strong className="text-gray-700 text-right">
                    {row.date}
                  </strong>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const checkParties = (form) => {
    const parties = form?.parties;

    if (parties == "โจทก์") return form?.plaintiffComponent[0]?.name;
    if (parties == "จำเลย") return form?.defendantComponent[0]?.name;
    if (parties == "ผู้ร้อง") return form?.complainantComponent[0]?.name;
    if (parties == "ผู้เสียหาย") return form?.suffererComponent[0]?.name;
  };

  useEffect(() => {
    if (!taskId) return;

    setLoading(true);
    const load = async () => {
      try {
        let { data } = await client.query({
          query: QUERY_TASK_BY_ID,
          variables: {
            id: taskId,
          },
        });
        setForm({
          ...data.task,
          lawyer_name: {
            label: data?.task?.lawyer_name,
          },
        });
      } catch (error) {
        // ดัก TOKEN หมดอายุหรือ ERROR อื่นๆ กลับไปหน้าล็อคอินใหม่
        // localStorage.clear();
        // router.push("/user");
      }
    };
    setLoading(false);

    load();
  }, [taskId]);

  if (loading || !form) return <CelesLoading />;
  if (error) return <p>Something went wrong</p>;

  //-----------------------------------------------------------------------------//
  //คำนวณ
  const court_fee = numeral(form.court_fee).value();
  const summons = numeral(form.summons).value();
  const precautions = numeral(form.precautions).value();
  const lawyer_fee = numeral(form.lawyer_fee).value();
  const court_fees = numeral(form.court_fees).value();
  const cost_fees_account =
    court_fee + summons + precautions + lawyer_fee - court_fees;
  const c_cost_fees_account = numeral(cost_fees_account).format("0,0.00");
  //-----------------------------------------------------------------------------//
  const seizure_fees = numeral(form.seizure_fees).value();
  const delivery_cost = numeral(form.delivery_cost).value();
  const etc_cost = numeral(form.etc_cost).value();
  const account_legal_fees = seizure_fees + delivery_cost + etc_cost;
  const c_account_legal_fees = numeral(account_legal_fees).format("0,0.00");
  //-----------------------------------------------------------------------------//

  return (
    <div className="bg-gray-200 relative font-kanit">
      <div className="bg-gray-100 pb-32 max-w-3xl w-full mx-auto md:bg-gray-200">
        <div className="p-4 mx-0 bg-gray-50">
          <div className="p-4 bg-white rounded-md my-4 shadow">
            <div className="flex justify-between">
              <div className="mt-2">{CategoryTitle("ข้อมูลคดี")}</div>
              <div
                className={
                  "my-2 w-24 text-sm rounded-lg p-1  text-center " +
                  statusLabel(form?.status)
                }
              >
                {form?.status}
              </div>
            </div>
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            <Main title="รหัสงาน" name={form?.acceptance_code} />
            <Main title="ฝ่าย" name={form?.parties} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
              <div>ลูกความ</div>
              <div>
                <div className="text-gray-700 text-right">
                  {checkParties(form)}
                </div>
              </div>
            </div>

            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            {form?.agencyComponent.map((row, index) => {
              return (
                <div>
                  <div className="text-xl mb-2 text-green-500 ">
                    ศาล ที่ {index + 1}
                  </div>
                  <Main
                    title="หน่วยงานที่รับฟ้อง / รับเรื่อง"
                    name={row.agency_name}
                  />
                  <Main title="ประเภทคดี" name={row.case_type} />
                  <Main title="หมายเลขคดีดำ" name={row.black_case} />
                  <Main title="หมายเลขคดีแดง" name={row.red_case} />
                  <Main title="ทุนทรัพย์" name={row.capital} />
                  <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
                </div>
              );
            })}
            {Title("บัญชีค่าฤชาธรรมเนียมศาล")}
            <Main title="ค่าขึ้นศาล" name={form.court_fee} />
            <Main title="ค่านำหมายเรียก ฯ" name={form.summons} />
            <Main title="ค่านำคำบังคับ" name={form.precautions} />
            <Main title="ค่าทนายความ" name={form.lawyer_fee} />
            <Main
              title="ค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
              name={form.court_fees}
            />
            <Main title="ยอดรวม" name={c_cost_fees_account} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>

            {form?.legal_relation.map((row, index) => {
              //-----------------------------------------------------------------------------//
              //คำนวณ
              const principle = numeral(row.principle).value();
              const interest = numeral(row.interest).value();

              const total_cost = principle + interest;
              const c_total_cost = numeral(total_cost).format("0,0.00");
              //-----------------------------------------------------------------------------//

              return (
                <div>
                  <div className="text-xl mb-2 text-green-500 ">
                    นิติสัมพันธ์/เอกสาร/สัญญา ครั้งที่ {index + 1}
                  </div>
                  <Main title="เลขที่บัญชี" name={row.account_number} />
                  <Main title="เงินต้น" name={row.principle} />
                  <Main title="ดอกเบี้ย" name={row.interest} />
                  <Main title="ยอดรวม" name={c_total_cost} />
                  <Main title="สาขา" name={row.branch} />
                  <Main title="ประเภทสินเชื่อ" name={row.type_loan} />
                </div>
              );
            })}
            <Main title="เรื่อง / ฐานความผิด" name={form?.fault} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            <Main title="วันรับเรื่อง" name={form?.date_accept} />
            <DateMulti
              ComponentName="introduction_date"
              title="บอกกล่าวทวงถาม ครั้งที่ "
            />
            <Main title="วันฟ้อง" name={form?.filing_date} />
            <DateMulti
              ComponentName="consideration_date"
              title="วันนัดพิจารณา ครั้งที่ "
            />
            <Main title="วันพิพากษา" name={form?.judgment_reading_date} />
            <Main
              title="วันอ่านคำพิพากษาศาลอุทธรณ์"
              name={form?.read_decision_date}
            />
            <Main
              title="วันอ่านคำพิพากษาศาลฎีกา"
              name={form?.read_petition_date}
            />
            <Main title="วันยื่นฟ้องอุทธรณ์" name={form?.appeal_filing_date} />
            <Main
              title="วันถ่ายรูป,ทำแผนที่,เตรียมเอกสาร"
              name={form?.preparing_documents_date}
            />
            {/* <Main
              title="วันยื่นออกหมายบังคับคดี"
              name={form?.submission_execution_date}
            /> */}
            <Main title="ผลการพิจารณาคดี" name={form?.trial_result} />

            <DateMulti
              ComponentName="public_auction_date"
              title="วันประกาศขายทอดตลาด ครั้งที่ "
            />
            <DateMulti
              ComponentName="sell_to_public"
              title="วันขายทรัพย์ ครั้งที่ "
            />
            <DateMulti
              ComponentName="money_from_execution"
              title="วันได้รับเงินจากการบังคับคดี ครั้งที่ "
            />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            <Profile_Array
              form={form}
              ComponentName="plaintiffComponent"
              title="โจทก์ ที่ "
            />
            <Profile_Array
              form={form}
              ComponentName="defendantComponent"
              title="จำเลย ที่ "
            />
            <Profile_Array
              form={form}
              ComponentName="complainantComponent"
              title="ผู้ร้อง ที่ "
            />
            <Profile_Array
              form={form}
              ComponentName="suffererComponent"
              title="ผู้เสียหาย ที่ "
            />
            {form.detail_shortly.map((row, index) => {
              if (!row.brief_indictment && !row.judgment_details) {
                return "";
              }
              if (!row.judgment_details) {
                return (
                  <div>
                    {" "}
                    <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                      รายละเอียดคำฟ้อง (โดยย่อ) / คำขอท้ายฟ้อง ที่ {index + 1}
                    </div>
                    <MainTextArea name={row.brief_indictment} />
                    <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
                  </div>
                );
              }
              if (!row.brief_indictment) {
                return (
                  <div>
                    <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                      รายละเอียดคำพิพากษา (โดยย่อ) ที่ {index + 1}
                    </div>
                    <MainTextArea name={row.judgment_details} />
                    <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                      รายละเอียดคำฟ้อง (โดยย่อ) / คำขอท้ายฟ้อง ที่ {index + 1}
                    </div>
                    <MainTextArea name={row.brief_indictment} />
                    <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                      รายละเอียดคำพิพากษา (โดยย่อ) ที่ {index + 1}
                    </div>
                    <MainTextArea name={row.judgment_details} />
                    <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
                  </div>
                );
              }
            })}
            {CategoryTitle("เอกสาร")}
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            <MultiShowUpload
              form={form}
              ComponentName="notice_demand"
              title="หนังสือบอกกล่าวงทวงถาม ครั้งที่ "
            />
            <ShowUpload title="คำฟ้อง" name={indictment} />
            <ShowUpload title="บัญชีระบุพยาน" name={witness_account} />
            <ShowUpload title="คำพิพากษา" name={sentence_document} />
            <ShowUpload title="คำบังคับ" name={decree_document} />
            <ShowUpload title="บัญชีค่าฤชาธรรมเนียม" name={fee_account} />
            <ShowUpload title="หมายบังคับคดี" name={warrant_execution} />
            <ShowUpload
              title="หนังสือรับรองคดีถึงที่สุด"
              name={certificate_final_case}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientUser;
