import React, { useContext } from "react";
import EditDoc from "../../components/create_task/EditDoc";

function Document() {
  return (
    <React.Fragment>
      <EditDoc />
    </React.Fragment>
  );
}

export default Document;
