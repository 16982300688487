import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { useQuery } from "@apollo/client";

// APOLLO
import initialApollo from "./apollo";

//PAGE
import Home from "./Home";
// import HomeAllTasks from "./HomeAllTasks";
import MainCalendar from "./MainCalendar";
import NewTask from "./pages/new_task/";
import Task from "./pages/task";

//CONTEXT
import { TaskProvider } from "./context/TaskContext";
import { AppProvider } from "./context/AppContext";

//COMPONENTS
import NavBar from "./components/NavBar";
import Congratulation from "./components/Congratulation";
import Create from "./components/create";
import User from "./components/user";
import ClientUser from "./pages/task/Client";
import Import from "./pages/Import";

// QUERY
import QUERY_ME from "./apollo/queries/queryMe";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const accessToken = localStorage.getItem("jwt");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          return <Component {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/user",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const App = () => {
  const { client } = initialApollo();

  return (
    <ApolloProvider client={client}>
      <Router>
        <div>
          <AppProvider>
            <NavBar />
            {/* <Route path="/import" component={Import} /> */}
            <Switch>
              <Route path="/user" component={User} />
              <Route path="/calendar" component={MainCalendar} />
              <Route path="/create" component={Create} />
              <Route path="/congratulation" component={Congratulation} />
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/showAll" component={HomeAllTasks} /> */}

              <TaskProvider>
                <Route path="/task/:taskId" component={Task} />
                <Route path="/newtask" component={NewTask} />
                <Route path="/taskUser/:taskId" component={ClientUser} />
              </TaskProvider>
            </Switch>
          </AppProvider>
        </div>
      </Router>
    </ApolloProvider>
    // TaskProvider
  );
};

export default App;
