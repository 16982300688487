import gql from "graphql-tag";

const UPDATE_TASK = gql`
  mutation UpdateTask($input: updateTaskInput) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export default UPDATE_TASK;
