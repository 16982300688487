import initialApollo from "./index";

import CREATE_TASK from "./mutation/createTask";
import UPDATE_TASK from "./mutation/updateTask";
import DELETE_TASK from "./mutation/deleteTask";
import UPLOAD_FILE from "./mutation/upload";

import axios from "axios";
import { fromPromise } from "@apollo/client";

const { client } = initialApollo();

const formatDate = (date) => {
  const checkDate = date ? date : new Date();
  const toStrigDate = checkDate.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const dd = toStrigDate.split("/")[0];
  const mm = toStrigDate.split("/")[1];
  const yy = toStrigDate.split("/")[2];

  return yy + "-" + mm + "-" + dd;
  // return date;
};

const formatDateThai = (date) => {
  if (!date) return "";
  else return date.toLocaleDateString("th");
};

const formatThaiDateWithThai = (date) => {
  if (!date) return "";
  else
    return (
      date.toLocaleDateString("th") +
      " " +
      date.toLocaleTimeString("th", { hour: "2-digit", minute: "2-digit" })
    );
};

const indexZero = (form, key, field) => {
  let check = false;
  if (!!form[key] || form[key]?.length > 0) {
    check = true;
  }

  return check > 0 ? form[key][0][field] : "";
};

export default {
  async deleteTask(taskId) {
    return await client.mutate({
      mutation: DELETE_TASK,
      variables: {
        input: {
          where: {
            id: taskId,
          },
        },
      },
    });
  },

  async createTask(form) {
    const etc_book1 = form?.etc_book1?.map((obj) => ({
      name: obj?.name,
      file: obj?.file,
    }));
    const etc_book2 = form?.etc_book2?.map((obj) => ({
      name: obj?.name,
      file: obj?.file,
    }));
    const etc_book3 = form?.etc_book3?.map((obj) => ({
      name: obj?.name,
      file: obj?.file,
    }));
    const etc_book4 = form?.etc_book4?.map((obj) => ({
      name: obj?.name,
      file: obj?.file,
    }));
    const notice_demand = form?.notice_demand?.map((obj) => ({
      file: obj?.file,
    }));
    const request_document1 = form?.request_document1?.map((obj) => ({
      file: obj?.file,
    }));
    const receipt = form?.receipt?.map((obj) => ({
      file: obj?.file,
    }));
    const proceedings_report = form?.proceedings_report?.map((obj) => ({
      file: obj?.file,
    }));
    const request_document2 = form?.request_document2?.map((obj) => ({
      file: obj?.file,
    }));
    const court_order = form?.court_order?.map((obj) => ({
      file: obj?.file,
    }));
    const upload_receipt = form?.upload_receipt?.map((obj) => ({
      file: obj?.file,
    }));
    const opinion_book = form?.opinion_book?.map((obj) => ({
      file: obj?.file,
    }));
    const withdrawal_book = form?.withdrawal_book?.map((obj) => ({
      file: obj?.file,
    }));
    const plaintiffComponent = form?.plaintiffComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const defendantComponent = form?.defendantComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const complainantComponent = form?.complainantComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const suffererComponent = form?.suffererComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const legal_relation = form?.legal_relation?.map((obj) => ({
      account_number: obj?.account_number,
      principle: obj?.principle,
      interest: obj?.interest,
      total_cost: obj?.total_cost,
      bank: obj?.bank,
      branch: obj?.branch,
      county: obj?.county,
      type_loan: obj?.type_loan,
      collateral_type: obj?.collateral_type,
      detail_type: obj?.detail_type,
    }));
    const proceedingsComponent = form?.proceedingsComponent?.map((obj) => ({
      text: obj?.text,
    }));
    const detail_shortly = form?.detail_shortly?.map((obj) => ({
      brief_indictment: obj?.brief_indictment,
      judgment_details: obj?.judgment_details,
    }));
    const legal_executionComponent = form?.legal_executionComponent?.map(
      (obj) => ({
        agency_name: obj?.agency_name,
        court: obj?.court,
        black_case: obj?.black_case,
        red_case: obj?.red_case,
        capital: obj?.capital,
      })
    );
    const official_appraisal_price = form?.official_appraisal_price?.map(
      (obj) => ({
        cost: obj?.cost,
      })
    );
    const agencyComponent = form?.agencyComponent?.map((obj) => ({
      agency_name: obj?.agency_name,
      case_type: obj?.case_type,
      black_case: obj?.black_case,
      red_case: obj?.red_case,
      capital: obj?.capital,
    }));
    const introduction_date = form?.introduction_date?.map((obj) => ({
      date: obj?.date,
    }));
    const introductory_date = form?.introductory_date?.map((obj) => ({
      date: obj?.date,
    }));
    const consideration_date = form?.consideration_date?.map((obj) => ({
      date: obj?.date,
    }));
    const appeal_deadline = form?.appeal_deadline?.map((obj) => ({
      date: obj?.date,
    }));
    const supreme_court_date = form?.supreme_court_date?.map((obj) => ({
      date: obj?.date,
    }));
    const declaration_date = form?.declaration_date?.map((obj) => ({
      date: obj?.date,
    }));
    const submission_document = form?.submission_document?.map((obj) => ({
      name: obj?.name,
      date: obj?.date,
    }));
    const recived_document = form?.recived_document?.map((obj) => ({
      name: obj?.name,
      date: obj?.date,
    }));
    const public_auction_date = form?.public_auction_date?.map((obj) => ({
      date: obj?.date,
    }));
    const sell_to_public = form?.sell_to_public?.map((obj) => ({
      date: obj?.date,
    }));
    const money_from_execution = form?.money_from_execution?.map((obj) => ({
      date: obj?.date,
    }));
    const receipt_payment_account = form?.receipt_payment_account?.map(
      (obj) => ({
        date: obj?.date,
      })
    );
    const operation_record = form?.operation_record?.map((obj) => ({
      text: obj?.text,
    }));
    const detail_payment = form?.detail_payment?.map((obj) => ({
      received_date: obj?.received_date,
      reveal_date: obj?.reveal_date,
      ask_cost: obj?.ask_cost,
      lump_cost: obj?.lump_cost,
      professional_fee: obj?.professional_fee,
      etc_cost: obj?.etc_cost,
      detail_payment_cost: obj?.detail_payment_cost,
    }));

    return await client.mutate({
      mutation: CREATE_TASK,
      variables: {
        input: {
          data: {
            users_permissions_user: form.lawyer_name?.value,
            plaintiff_name: indexZero(form, "plaintiffComponent", "name"),
            name_defendant: indexZero(form, "defendantComponent", "name"),
            name_complainant: indexZero(form, "complainantComponent", "name"),
            name_sufferer: indexZero(form, "suffererComponent", "name"),
            agency_name: indexZero(form, "agencyComponent", "agency_name"),
            black_case: indexZero(form, "agencyComponent", "black_case"),
            red_case: indexZero(form, "AaencyComponent", "red_case"),
            account_number: indexZero(form, "legal_relation", "account_number"),
            acceptance_code: form.acceptance_code,
            status: form.status,
            parties: form.parties,
            register: form.register,
            lawyer_name: form.lawyer_name?.label,
            plaintiffComponent,
            complainantComponent,
            defendantComponent,
            suffererComponent,
            legal_relation,
            court_fee: form.court_fee,
            summons: form.summons,
            precautions: form.precautions,
            lawyer_fee: form.lawyer_fee,
            court_fees: form.court_fees,
            cost_fees_account: form.cost_fees_account,
            result_summons: form.result_summons,
            proceedingsComponent,
            result_regulations: form.result_regulations,
            detail_shortly,
            trial_result: form.trial_result,
            legal_executionComponent,
            fault: form.fault,
            seizure_fees: form.seizure_fees,
            delivery_cost: form.delivery_cost,
            etc_cost: form.etc_cost,
            details_execution: form.details_execution,
            account_legal_fees: form.account_legal_fees,
            official_appraisal_price,
            precautions2: form.precautions2,
            agencyComponent,
            operation_record,
            //วันที่ทั้งหมด
            date_accept: form.date_accept,
            newspaper_request_date: form.newspaper_request_date,
            delay_sue: form.delay_sue,
            delay_work_date: form.delay_work_date,
            mediation_date: form.mediation_date,
            filing_date: form.filing_date,
            announcement_date: form.announcement_date,
            counterclaim_date: form.counterclaim_date,
            appointment_judgment_date: form.appointment_judgment_date,
            judgment_reading_date: form.judgment_reading_date,
            appeal_filing_date: form.appeal_filing_date,
            submission_decision_date: form.submission_decision_date,
            appointment_decision_date: form.appointment_decision_date,
            read_decision_date: form.read_decision_date,
            petition_date: form.petition_date,
            submission_petition_date: form.submission_petition_date,
            appointment_petition_date: form.appointment_petition_date,
            read_petition_date: form.read_petition_date,
            announcement_date2: form.announcement_date2,
            submission_execution_date: form.submission_execution_date,
            execution_date: form.execution_date,
            final_certificate_date: form.final_certificate_date,
            publication_certificate_date: form.publication_certificate_date,
            accept_certificate_date: form.accept_certificate_date,
            receive_certificate_date: form.receive_certificate_date,
            claim_fee_date: form.claim_fee_date,
            receive_fee_date: form.receive_fee_date,
            closing_date: form.closing_date,
            preparing_documents_date: form.preparing_documents_date,
            execution_date2: form.execution_date2,
            closing_day2: form.closing_day2,

            //Component_date ใช้ได้
            introductory_date,
            public_auction_date,
            sell_to_public,
            money_from_execution,
            receipt_payment_account,
            introduction_date,
            submission_document,
            declaration_date,
            recived_document,
            supreme_court_date,
            appeal_deadline,
            consideration_date,
            //การเงิน
            court_fee2: form.court_fee2,
            summons_cost: form.summons_cost,
            precautions: form.precautions,
            document_certification_fee: form.document_certification_fee,
            newspaper_announcement_fee: form.newspaper_announcement_fee,
            other_expenses: form.other_expenses,
            seizure_fees2: form.seizure_fees2,
            asset_withdrawal_fee: form.asset_withdrawal_fee,
            total_court_fee_details: form.total_court_fee_details,
            transportation_expenses: form.transportation_expenses,
            food_expenses: form.food_expenses,
            postage: form.postage,
            newspaper_announcement_fee2: form.newspaper_announcement_fee2,
            civil_registration_fee: form.civil_registration_fee,
            documentation_fee: form.documentation_fee,
            photocopying_fee: form.photocopying_fee,
            allowance_profession: form.allowance_profession,
            other_expenses2: form.other_expenses2,
            details_costs_litigation: form.details_costs_litigation,
            detail_payment,
            result_all_cost: form.result_all_cost,
            end_cost: form.end_cost,
            //หมวดอัปโหลดเอกสาร
            etc_book1,
            etc_book2,
            etc_book3,
            etc_book4,
            notice_demand,
            receipt,
            proceedings_report,
            request_document2,
            court_order,
            opinion_book,
            upload_receipt,
            withdrawal_book,
            request_document1,
            //EID_Google Calendar
            // eid_mediation_date,
            // eid_appointment_judgment_date,
            // eid_appointment_decision_date,
            // eid_appointment_petition_date,
            // //EID_Component
            // eid_appeal_deadline,
            // eid_consideration_date,
            // eid_supreme_court_date,
          },
        },
      },
    });

    // return "Okkkkkk....";
  },

  async updateTask(form, taskId) {
    const getFileId = (file) => {
      if (file?.id) {
        return file?.id;
      }
      return file?.file;
    };

    const etc_book1 = form?.etc_book1?.map((obj) => ({
      name: obj?.name,
      file: getFileId(obj),
    }));
    const etc_book2 = form?.etc_book2?.map((obj) => ({
      name: obj?.name,
      file: getFileId(obj),
    }));
    const etc_book3 = form?.etc_book3?.map((obj) => ({
      name: obj?.name,
      file: getFileId(obj),
    }));
    const etc_book4 = form?.etc_book4?.map((obj) => ({
      name: obj?.name,
      file: getFileId(obj),
    }));

    const notice_demand = form?.notice_demand?.map((obj) => ({
      file: getFileId(obj),
    }));

    const request_document1 = form?.request_document1?.map((obj) => ({
      file: getFileId(obj),
    }));
    const receipt = form?.receipt?.map((obj) => ({
      file: getFileId(obj),
    }));
    const proceedings_report = form?.proceedings_report?.map((obj) => ({
      file: getFileId(obj),
    }));
    const request_document2 = form?.request_document2?.map((obj) => ({
      file: getFileId(obj),
    }));
    const court_order = form?.court_order?.map((obj) => ({
      file: getFileId(obj),
    }));
    const upload_receipt = form?.upload_receipt?.map((obj) => ({
      file: getFileId(obj),
    }));
    const opinion_book = form?.opinion_book?.map((obj) => ({
      file: getFileId(obj),
    }));
    const withdrawal_book = form?.withdrawal_book?.map((obj) => ({
      file: getFileId(obj),
    }));
    const plaintiffComponent = form?.plaintiffComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const defendantComponent = form?.defendantComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const complainantComponent = form?.complainantComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const suffererComponent = form?.suffererComponent?.map((obj) => ({
      name: obj?.name,
      citizen_number: obj?.citizen_number,
      address: obj?.address,
      position: obj?.position,
      phone_number: obj?.phone_number,
      email: obj?.email,
    }));
    const legal_relation = form?.legal_relation?.map((obj) => ({
      account_number: obj?.account_number,
      principle: obj?.principle,
      interest: obj?.interest,
      total_cost: obj?.total_cost,
      bank: obj?.bank,
      branch: obj?.branch,
      county: obj?.county,
      type_loan: obj?.type_loan,
      collateral_type: obj?.collateral_type,
      detail_type: obj?.detail_type,
    }));
    const proceedingsComponent = form?.proceedingsComponent?.map((obj) => ({
      text: obj?.text,
    }));
    const detail_shortly = form?.detail_shortly?.map((obj) => ({
      brief_indictment: obj?.brief_indictment,
      judgment_details: obj?.judgment_details,
    }));
    const legal_executionComponent = form?.legal_executionComponent?.map(
      (obj) => ({
        agency_name: obj?.agency_name,
        court: obj?.court,
        black_case: obj?.black_case,
        red_case: obj?.red_case,
        capital: obj?.capital,
      })
    );
    const official_appraisal_price = form?.official_appraisal_price?.map(
      (obj) => ({
        cost: obj?.cost,
      })
    );
    const agencyComponent = form?.agencyComponent?.map((obj) => ({
      agency_name: obj?.agency_name,
      case_type: obj?.case_type,
      black_case: obj?.black_case,
      red_case: obj?.red_case,
      capital: obj?.capital,
    }));
    const introduction_date = form?.introduction_date?.map((obj) => ({
      date: obj?.date,
    }));
    const introductory_date = form?.introductory_date?.map((obj) => ({
      date: obj?.date,
    }));
    const consideration_date = form?.consideration_date?.map((obj) => ({
      date: obj?.date,
    }));
    const appeal_deadline = form?.appeal_deadline?.map((obj) => ({
      date: obj?.date,
    }));
    const supreme_court_date = form?.supreme_court_date?.map((obj) => ({
      date: obj?.date,
    }));
    const declaration_date = form?.declaration_date?.map((obj) => ({
      date: obj?.date,
    }));
    const submission_document = form?.submission_document?.map((obj) => ({
      name: obj?.name,
      date: obj?.date,
    }));
    const recived_document = form?.recived_document?.map((obj) => ({
      name: obj?.name,
      date: obj?.date,
    }));
    const public_auction_date = form?.public_auction_date?.map((obj) => ({
      date: obj?.date,
    }));
    const sell_to_public = form?.sell_to_public?.map((obj) => ({
      date: obj?.date,
    }));
    const money_from_execution = form?.money_from_execution?.map((obj) => ({
      date: obj?.date,
    }));
    const receipt_payment_account = form?.receipt_payment_account?.map(
      (obj) => ({
        date: obj?.date,
      })
    );
    const operation_record = form?.operation_record?.map((obj) => ({
      text: obj?.text,
    }));
    const detail_payment = form?.detail_payment?.map((obj) => ({
      received_date: obj?.received_date,
      reveal_date: obj?.reveal_date,
      ask_cost: obj?.ask_cost,
      lump_cost: obj?.lump_cost,
      professional_fee: obj?.professional_fee,
      etc_cost: obj?.etc_cost,
      detail_payment_cost: obj?.detail_payment_cost,
    }));
    return await client.mutate({
      mutation: UPDATE_TASK,
      variables: {
        input: {
          where: {
            id: taskId,
          },
          data: {
            users_permissions_user: form.lawyer_name?.value,
            acceptance_code: form.acceptance_code,
            status: form.status,
            parties: form.parties,
            register: form.register,
            lawyer_name: form.lawyer_name?.label,
            plaintiffComponent,
            complainantComponent,
            defendantComponent,
            suffererComponent,
            legal_relation,
            court_fee: form.court_fee,
            summons: form.summons,
            precautions: form.precautions,
            lawyer_fee: form.lawyer_fee,
            court_fees: form.court_fees,
            cost_fees_account: form.cost_fees_account,
            result_summons: form.result_summons,
            proceedingsComponent,
            result_regulations: form.result_regulations,
            detail_shortly,
            trial_result: form.trial_result,
            legal_executionComponent,
            fault: form.fault,
            seizure_fees: form.seizure_fees,
            delivery_cost: form.delivery_cost,
            etc_cost: form.etc_cost,
            details_execution: form.details_execution,
            account_legal_fees: form.account_legal_fees,
            official_appraisal_price,
            precautions2: form.precautions2,
            agencyComponent,
            operation_record,
            //วันที่ทั้งหมด
            date_accept: form.date_accept,
            newspaper_request_date: form.newspaper_request_date,
            delay_sue: form.delay_sue,
            delay_work_date: form.delay_work_date,
            mediation_date: form.mediation_date,
            filing_date: form.filing_date,
            announcement_date: form.announcement_date,
            counterclaim_date: form.counterclaim_date,
            appointment_judgment_date: form.appointment_judgment_date,
            judgment_reading_date: form.judgment_reading_date,
            appeal_filing_date: form.appeal_filing_date,
            submission_decision_date: form.submission_decision_date,
            appointment_decision_date: form.appointment_decision_date,
            read_decision_date: form.read_decision_date,
            petition_date: form.petition_date,
            submission_petition_date: form.submission_petition_date,
            appointment_petition_date: form.appointment_petition_date,
            read_petition_date: form.read_petition_date,
            announcement_date2: form.announcement_date2,
            submission_execution_date: form.submission_execution_date,
            execution_date: form.execution_date,
            final_certificate_date: form.final_certificate_date,
            publication_certificate_date: form.publication_certificate_date,
            accept_certificate_date: form.accept_certificate_date,
            receive_certificate_date: form.receive_certificate_date,
            claim_fee_date: form.claim_fee_date,
            receive_fee_date: form.receive_fee_date,
            closing_date: form.closing_date,
            preparing_documents_date: form.preparing_documents_date,
            execution_date2: form.execution_date2,
            closing_day2: form.closing_day2,

            //Component_date ใช้ได้
            introductory_date,
            public_auction_date,
            sell_to_public,
            money_from_execution,
            receipt_payment_account,
            introduction_date,
            submission_document,
            declaration_date,
            recived_document,
            supreme_court_date,
            appeal_deadline,
            consideration_date,
            //การเงิน
            court_fee2: form.court_fee2,
            summons_cost: form.summons_cost,
            precautions: form.precautions,
            document_certification_fee: form.document_certification_fee,
            newspaper_announcement_fee: form.newspaper_announcement_fee,
            other_expenses: form.other_expenses,
            seizure_fees2: form.seizure_fees2,
            asset_withdrawal_fee: form.asset_withdrawal_fee,
            total_court_fee_details: form.total_court_fee_details,
            transportation_expenses: form.transportation_expenses,
            food_expenses: form.food_expenses,
            postage: form.postage,
            newspaper_announcement_fee2: form.newspaper_announcement_fee2,
            civil_registration_fee: form.civil_registration_fee,
            documentation_fee: form.documentation_fee,
            photocopying_fee: form.photocopying_fee,
            allowance_profession: form.allowance_profession,
            other_expenses2: form.other_expenses2,
            details_costs_litigation: form.details_costs_litigation,
            detail_payment,
            result_all_cost: form.result_all_cost,
            end_cost: form.end_cost,
            //หมวดอัปโหลดเอกสาร
            etc_book1,
            etc_book2,
            etc_book3,
            etc_book4,
            notice_demand,
            receipt,
            proceedings_report,
            request_document2,
            court_order,
            opinion_book,
            upload_receipt,
            withdrawal_book,
            request_document1,
          },
        },
      },
    });
  },

  async updateEventId(taskId, field, eventId) {
    return await client.mutate({
      mutation: UPDATE_TASK,
      variables: {
        input: {
          where: {
            id: taskId,
          },
          data: {
            [field]: eventId,
          },
        },
      },
    });
  },

  async updateMultiEventId(taskId, field, eventId) {
    return await client.mutate({
      mutation: UPDATE_TASK,
      variables: {
        input: {
          where: {
            id: taskId,
          },
          data: {
            [field]: eventId,
          },
        },
      },
    });
  },

  async uploadFile(formData) {
    return await axios({
      method: "POST",
      url: "https://admin.ariyatham.com/upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
