import { CategoryTitle, Title } from "../../components/create_task/TitleStyle";
import Profile_Array from "./Profile_Array";

const Profile = ({ form }) => {
  return (
    <div className="p-4 bg-white rounded-md my-4 shadow">
      {CategoryTitle("หมวดคู่ความ")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <Profile_Array
        form={form}
        ComponentName="plaintiffComponent"
        title="โจทก์ ที่ "
      />
      <Profile_Array
        form={form}
        ComponentName="defendantComponent"
        title="จำเลย ที่ "
      />
      <Profile_Array
        form={form}
        ComponentName="complainantComponent"
        title="ผู้ร้อง ที่ "
      />
      <Profile_Array
        form={form}
        ComponentName="suffererComponent"
        title="ผู้เสียหาย ที่ "
      />
    </div>
  );
};
export default Profile;
