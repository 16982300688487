import React, { Component, useState, useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import CelesLoading from "../lib/CelesLoading";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import { AppContext } from "../context/AppContext";
import Swal from "sweetalert2";

// QUERY

// MUTATION
import MUTATION_LOGIN from "../apollo/mutation/login";

const User = () => {
  const router = useHistory();
  const { user, setUser } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [Login] = useMutation(MUTATION_LOGIN);

  const accessToken = localStorage.getItem("jwt");

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (p) => {
    setPassword(p.target.value);
  };

  const onClickSubmitSignIn = async () => {
    setLoading(true);
    try {
      const { data } = await Login({
        variables: {
          identifier: email,
          password: password,
        },
      });
      const dataLogin = data.login;
      localStorage.setItem("jwt", dataLogin.jwt);
      localStorage.setItem("uid", dataLogin.user.id);
      localStorage.setItem("userName", dataLogin.user.username);
      localStorage.setItem("roleName", dataLogin.user.role.name);
      setUser({
        userId: dataLogin.user.id,
        userName: dataLogin.user.username,
      });
      setTimeout(() => {
        router.push("/");
      }, [500]);
    } catch (error) {
      setLoading(false);
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "อีเมลหรือรหัสผ่านไม่ถูกต้อง โปรดลองใหม่อีกครั้ง",
      });
      setPassword("");
      console.log("ERROR", JSON.stringify(error));
    }
  };

  if (loading) return <CelesLoading />;
  if (accessToken) return <Redirect to="/" />;

  return (
    <div className="font-kanit bg-gray-100">
      <div className="sm:bg-user2 bg-user1  bg-no-repeat sm:w-full sm:max-w-3xl sm:mx-auto absolute top-0 right-0 left-0">
        <div className=" mx-auto  ">
          <div className="p-5">
            <div className="relative"></div>
            {/* <div className="py-2 sm:py-0">
              <img className="mt-2   sm:pl-4   h-6" src="/left.svg"></img>
            </div> */}
            <div className=" flex flex-col items-center mt-48 sm:mt-48 sm:ml-4">
              <div>
                <img
                  className="mt-14 sm:mt-36 h-36 w-auto "
                  src="./ariyatham.PNG"
                ></img>
              </div>

              <form>
                <div className="sm:ml-4">E-mail</div>
                <div>
                  <input
                    value={email}
                    onChange={onChangeEmail}
                    className="p-4 my-2 bg-gray-200 h-12 w-80 rounded-2xl outline-none "
                    type="text"
                    placeholder=""
                    name="email"
                  />
                </div>
                <div className="sm:ml-4">Password</div>
                <div>
                  <input
                    value={password}
                    onChange={onChangePassword}
                    className="p-4 my-2 bg-gray-200 h-12 w-80 rounded-2xl outline-none "
                    type="password"
                    id="pass"
                    placeholder=""
                    name="password"
                  />
                </div>
                <div className="">
                  <button
                    onClick={onClickSubmitSignIn}
                    className=" outline-none  w-full mt-6 bg-green1  cursor-pointer hover:bg-green2 h-12 text-white font-black rounded-2xl "
                  >
                    Sign In
                  </button>
                </div>
              </form>
              {/* <div className="mt-4 mb-10 self-end  font-black  mx-auto hover:text-green1 cursor-pointer">Sign up</div> */}
              <div className="py-16 sm:py-10  "></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default User;
