import React, { useState } from "react";
import NumberFormat from "react-number-format";

function formatMoney(inum) {
  var s_inum = new String(inum);
  var num2 = s_inum.split(".");
  var n_inum = "";
  if (num2[0] != undefined) {
    var l_inum = num2[0].length;
    for (var i = 0; i < l_inum; i++) {
      if (parseInt(l_inum - i) % 3 == 0) {
        if (i == 0) {
          n_inum += s_inum.charAt(i);
        } else {
          n_inum += "," + s_inum.charAt(i);
        }
      } else {
        n_inum += s_inum.charAt(i);
      }
    }
  } else {
    n_inum = inum;
  }
  if (num2[1] != undefined) {
    n_inum += "." + num2[1];
  }
  return n_inum;
}

const formatMyMoney = (value) => {
  var s_inum = new String(value);
  var n_inum = "";
  for (let index = 0; index < s_inum.length; index++) {
    if (parseInt(s_inum - index) % 3 == 0) {
      if (index == 0) {
        n_inum += s_inum.charAt(index);
      } else {
        n_inum += "," + s_inum.charAt(index);
      }
    } else {
      n_inum += s_inum.charAt(index);
    }
  }
};

const CelesFormatCurrency = ({
  label,
  name,
  setField,
  value,
  setFieldMulti,
  primaryField,
  index,
  className = "text-lg mb-2 text-gray-600 ",
}) => {
  const onChange = (e) => {
    const value = e.target.value;
    if (primaryField) {
      return setFieldMulti(name, primaryField, index)(value);
    }
    setField(name)(value);
  };

  // <NumberFormat
  //   decimalSeparator="."
  //   thousandSeparator={true}
  //   prefix={"$"}
  //   className="some"
  //   inputmode="numeric"
  // />;
  // console.log("NumberFormat", NumberFormat(value));

  return (
    <div className="w-full">
      <div className={className}>{label}</div>
      <NumberFormat
        thousandSeparator={true}
        fixedDecimalScale={true}
        decimalSeparator="."
        className="some"
        inputmode="numeric"
        type=""
        name={name}
        placeholder=""
        value={value}
        onChange={onChange}
        className={
          "bg-white border border-gray-200 my-1 rounded p-1 px-2 w-full focus:ring-1 focus:ring-green-500 outline-none  text-gray-700 "
        }
      />
    </div>
  );
};

export default CelesFormatCurrency;
