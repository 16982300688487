import {
  CategoryTitle,
  Title,
  Label,
} from "../../components/create_task/TitleStyle";
import Main from "./Main";
import MainTextArea from "./MainTextArea";

const Alldate = ({ form, STRAPI_URL }) => {
  const DateMulti = ({ title, ComponentName }) => {
    const data = form[ComponentName] || [];
    return (
      <div>
        {data.map((row, index) => {
          if (!row.date) return <div></div>;
          return (
            <div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg"></div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                <div>
                  {title} {index + 1}
                </div>
                <div>
                  <strong className="text-gray-700 text-right">
                    {row.date}
                  </strong>
                </div>
              </div>
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        })}
      </div>
    );
  };
  const DateWithText = ({ title, ComponentName }) => {
    const data = form[ComponentName] || [];
    return (
      <div>
        {data.map((row, index) => {
          if (!row.date) return <div></div>;
          return (
            <div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                {title}
                {index + 1}
              </div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                <div>{row.name}</div>
                <div>
                  <strong className="text-gray-700 text-right">
                    {row.date}
                  </strong>
                </div>
              </div>
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="p-4 bg-white rounded-md my-4 shadow">
      {CategoryTitle("หมวดการดำเนินงาน")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {Title("ขั้นตอนเตรียมงาน/เตรียมเอกสาร")}
      <DateMulti
        ComponentName="introduction_date"
        title="บอกกล่าวทวงถาม ครั้งที่ "
      />
      <Main title="ขอประกาศหนังสือพิมพ์" name={form.newspaper_request_date} />
      <Main title="ชะลอฟ้อง" name={form.delay_sue} />
      <Main title="ชะลองาน" name={form.delay_work_date} />
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {Title("ขั้นตอนฟ้องคดี/ดำเนินคดี/พิพากษา")}
      <Main title="วันฟ้อง" name={form.filing_date} />
      <DateMulti
        ComponentName="introductory_date"
        title="วันนำหมาย ฯ ครั้งที่ "
      />
      <Main title="วันขอประกาศ ฯ" name={form.announcement_date} />
      <Main title="วันยื่นคำให้การ / ฟ้องแย้ง" name={form.counterclaim_date} />
      <Main title="วันนัดไกล่เกลี่ย" name={form.mediation_date} />
      <DateMulti
        ComponentName="consideration_date"
        title="วันนัดพิจารณา / สืบพยาน ครั้งที่ "
      />
      <Main title="วันนัดฟังคำพิพากษา" name={form.appointment_judgment_date} />
      <Main title="วันอ่านคำพิพากษา" name={form.judgment_reading_date} />
      <DateMulti
        ComponentName="appeal_deadline"
        title="วันครบกำหนดอุทธรณ์คำพิพากษา ครั้งที่ "
      />
      <Main title="วันยื่นฟ้องอุทธรณ์" name={form.appeal_filing_date} />
      <Main
        title="วันยื่นคำให้การแก้ฟ้องอุทธรณ์"
        name={form.submission_decision_date}
      />
      <Main
        title="วันนัดฟังคำพิพากษาศาลอุทธรณ์"
        name={form.appointment_decision_date}
      />
      <Main title="วันอ่านคำพิพากษาศาลอุทธรณ์" name={form.read_decision_date} />
      <DateMulti
        ComponentName="supreme_court_date"
        title="วันครบกำหนดฎีกาคำพิพากษา ครั้งที่ "
      />
      <Main title="วันยื่นฟ้องฎีกา" name={form.petition_date} />
      <Main
        title="วันยื่นคำให้การแก้ฟ้องฎีกา"
        name={form.submission_petition_date}
      />
      <Main
        title="วันนัดฟังคำพิพากษาศาลฎีกา"
        name={form.appointment_petition_date}
      />
      <Main title="วันอ่านคำพิพากษาศาลฎีกา" name={form.read_petition_date} />
      <DateMulti
        ComponentName="declaration_date"
        title="วันนำคำบังคับ ครั้งที่ "
      />
      <Main title="วันขอประกาศ" name={form.announcement_date2} />
      <Main
        title="วันยื่นออกหมายบังคับคดี"
        name={form.submission_execution_date}
      />
      <Main title="วันพิมพ์หมายบังคับคดี" name={form.execution_date} />
      <Main
        title="วันยื่นขอหนังสือรับรองคดีถึงที่สุด"
        name={form.final_certificate_date}
      />
      <Main
        title="วันพิมพ์หนังสือรับรองคดีถึงที่สุด"
        name={form.publication_certificate_date}
      />
      <Main
        title="วันขอรับเอกสารต้นฉบับคืนจากศาล"
        name={form.accept_certificate_date}
      />
      <Main
        title="วันได้รับเอกสารต้นฉบับคืนจากศาล"
        name={form.receive_certificate_date}
      />
      <Main
        title="วันยื่นขอรับเงินค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
        name={form.claim_fee_date}
      />
      <Main
        title="วันได้รับเงินค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
        name={form.receive_fee_date}
      />
      <DateWithText
        ComponentName="recived_document"
        title="วันได้รับเอกสาร ครั้งที่ "
      />
      <Main title="วันปิดงาน" name={form.closing_date} />
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {Title("งานบังคับคดี")}
      <Main
        title="วันถ่ายรูป,ทำแผนที่,คัดถ่ายเอกสาร,เตรียมเอกสาร"
        name={form.preparing_documents_date}
      />
      <Main title="วันดำเนินการบังคับคดี" name={form.execution_date2} />
      <DateMulti
        ComponentName="public_auction_date"
        title="วันประกาศขายทอดตลาด ครั้งที่ "
      />
      <DateMulti
        ComponentName="sell_to_public"
        title="วันขายทรัพย์ ครั้งที่ "
      />
      <DateMulti
        ComponentName="money_from_execution"
        title="วันได้รับเงินจากการบังคับคดี ครั้งที่ "
      />
      <DateMulti
        ComponentName="receipt_payment_account"
        title="วันได้รับบัญชีรับจ่ายเงิน ครั้งที่ "
      />
      <Main title="วันปิดงาน" name={form.closing_day2} />
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {form.operation_record.map((row, index) => {
        if (!row.text) return <div></div>;
        return (
          <div>
            <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
              บันทึกการดำเนินงาน ครั้งที่ {index + 1}
            </div>
            <MainTextArea name={row.text} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
    </div>
  );
};

export default Alldate;
