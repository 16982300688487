import gql from "graphql-tag";

const CREATE_HISTORY = gql`
  mutation CreateTask($input: createTaskInput) {
    createTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export default CREATE_HISTORY;
