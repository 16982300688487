import gql from "graphql-tag";

const UPDATE_LAWYER = gql`
  mutation UpdateLawyer($input: updateLawyerInput) {
    updateLawyer(input: $input) {
      lawyer {
        id
      }
    }
  }
`;

export default UPDATE_LAWYER;
