const ShowUpload = ({
  title,
  name,
  fileName = "ไฟล์",
  STRAPI_URL = "https://admin.ariyatham.com",
}) => {
  if (!name) return <div></div>;
  return (
    <div>
      <div className="text-lg flex flex-row justify-between ">
        <div>
          <a
            className="my-4 text-blue-500 "
            href={STRAPI_URL + [name]}
            target="_blank"
          >
            {title}
          </a>
        </div>
        <div>
          <a
            className="my-4 text-blue-500 "
            href={STRAPI_URL + [name]}
            target="_blank"
          >
            {fileName}
          </a>
        </div>
      </div>
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
    </div>
  );
};
export default ShowUpload;
