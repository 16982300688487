import React, { useContext } from "react";
import EditPayment from "../../components/create_task/EditPayment";

function Payment() {
  return (
    <React.Fragment>
      <EditPayment />
    </React.Fragment>
  );
}

export default Payment;
