import { CategoryTitle, Title } from "../../components/create_task/TitleStyle";
import Main from "./Main";
import MainTextArea from "./MainTextArea";
import numeral from "numeral";

const Agency = ({ form }) => {
  //-----------------------------------------------------------------------------//
  //คำนวณ
  const court_fee = numeral(form.court_fee).value();
  const summons = numeral(form.summons).value();
  const precautions = numeral(form.precautions).value();
  const lawyer_fee = numeral(form.lawyer_fee).value();
  const court_fees = numeral(form.court_fees).value();
  const cost_fees_account =
    court_fee + summons + precautions + lawyer_fee - court_fees;
  const c_cost_fees_account = numeral(cost_fees_account).format("0,0.00");
  //-----------------------------------------------------------------------------//
  const seizure_fees = numeral(form.seizure_fees).value();
  const delivery_cost = numeral(form.delivery_cost).value();
  const etc_cost = numeral(form.etc_cost).value();
  const account_legal_fees = seizure_fees + delivery_cost + etc_cost;
  const c_account_legal_fees = numeral(account_legal_fees).format("0,0.00");
  //-----------------------------------------------------------------------------//
  return (
    <div className="p-4 bg-white rounded-md my-4 shadow">
      {CategoryTitle("หมวดหน่วยงาน")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>

      {form.agencyComponent.map((row, index) => {
        return (
          <div>
            <div className="text-xl mb-2 text-green-500 ">
              ศาล ที่ {index + 1}
            </div>
            <Main
              title="หน่วยงานที่รับฟ้อง / รับเรื่อง"
              name={row.agency_name}
            />
            <Main title="ประเภทคดี" name={row.case_type} />
            <Main title="หมายเลขคดีดำ" name={row.black_case} />
            <Main title="หมายเลขคดีแดง" name={row.red_case} />
            <Main title="ทุนทรัพย์" name={row.capital} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
      {Title("บัญชีค่าฤชาธรรมเนียมศาล")}
      <Main title="ค่าขึ้นศาล" name={form.court_fee} />
      <Main title="ค่านำหมายเรียก ฯ" name={form.summons} />
      <Main title="ค่านำคำบังคับ" name={form.precautions} />
      <Main title="ค่าทนายความ" name={form.lawyer_fee} />
      <Main title="ค่าธรรมเนียมส่วนที่ศาลสั่งคืน" name={form.court_fees} />
      <Main title="ยอดรวม" name={c_cost_fees_account} />
      <MainTextArea title="ผลการส่งหมายเรียก ฯ" name={form.result_summons} />
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      {form.proceedingsComponent.map((row, index) => {
        if (!row.text) return <div></div>;
        return (
          <div>
            <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
              รายงานกระบวนพิจารณา ครั้งที่ {index + 1}
            </div>
            <div className="my-4 p-4 text-gray-500 text-sm bg-gray-100 md:text-lg md:my-4 shadow rounded-lg">
              {row.text}
            </div>

            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
      <Main title="ผลการพิจารณาคดี" name={form.trial_result} />
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <MainTextArea title="ผลการส่งคำบังคับ ฯ" name={form.result_regulations} />
      {form.detail_shortly.map((row, index) => {
        if (!row.brief_indictment && !row.judgment_details) {
          return "";
        }
        if (!row.judgment_details) {
          return (
            <div>
              {" "}
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                รายละเอียดคำฟ้อง (โดยย่อ) / คำขอท้ายฟ้อง ที่ {index + 1}
              </div>
              <MainTextArea name={row.brief_indictment} />
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        }
        if (!row.brief_indictment) {
          return (
            <div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                รายละเอียดคำพิพากษา (โดยย่อ) ที่ {index + 1}
              </div>
              <MainTextArea name={row.judgment_details} />
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                รายละเอียดคำฟ้อง (โดยย่อ) / คำขอท้ายฟ้อง ที่ {index + 1}
              </div>
              <MainTextArea name={row.brief_indictment} />
              <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
                รายละเอียดคำพิพากษา (โดยย่อ) ที่ {index + 1}
              </div>
              <MainTextArea name={row.judgment_details} />
              <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
            </div>
          );
        }
      })}
      {form.legal_executionComponent.map((row, index) => {
        return (
          <div>
            <div className="text-xl mb-2 text-green-500 ">
              งานบังคับคดี ที่ {index + 1}
            </div>
            <Main
              title="หน่วยงานที่รับฟ้อง / รับเรื่อง"
              name={row.agency_name}
            />
            <Main title="ศาล" name={row.court} />
            <Main title="หมายเลขคดีดำ" name={row.black_case} />
            <Main title="หมายเลขคดีแดง" name={row.red_case} />
            <Main title="ทุนทรัพย์" name={row.capital} />
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}
      {Title("บัญชีค่าฤชาธรรมเนียมบังคับคดี")}
      <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
      <Main title="ค่าธรรมเนียมยึด / อายัดทรัพย์" name={form.seizure_fees} />
      <Main title="ค่านำส่งคำคู่ความ" name={form.delivery_cost} />
      <Main title="อื่น ๆ" name={form.etc_cost} />
      <Main title="ผลรวม" name={c_account_legal_fees} />
      <MainTextArea
        title="รายละเอียดการบังคับคดี"
        name={form.details_execution}
      />

      {form.official_appraisal_price.map((row, index) => {
        // const cost = numeral(row.cost).format("0,0.00")
        if (!row.cost) return <div></div>;
        return (
          <div>
            <div className="my-2 flex flex-row justify-between text-gray-500 text-lg">
              ราคาประเมินเจ้าพนักงาน ครั้งที่ {index + 1}
              <div>
                <strong className="text-gray-700 text-right">{row.cost}</strong>
              </div>
            </div>
            <div className="my-2 border border-gray-300 border-l-0 border-r-0 border-b-0"></div>
          </div>
        );
      })}

      {/* <Main title="" name=""/> */}
    </div>
  );
};

export default Agency;
