import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../lib/CelesButton";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import CelesLoading from "../lib/CelesLoading";

// QUERY
import QUERY_LAWYERS from "../apollo/queries/queryLawyers";

// MUTATION
import MUTATION_REGISTER from "../apollo/mutation/register";
import MUTATION_UPDATE_USER_ROLE from "../apollo/mutation/updateUserRole";
import UPDATE_LAWYER from "../apollo/mutation/updateLawyer";

const Create = () => {
  const router = useHistory();

  const [selectLawyer, setSelectLawyer] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { data, loading, error } = useQuery(QUERY_LAWYERS);
  const [Register] = useMutation(MUTATION_REGISTER);
  const [UpdateUserRole] = useMutation(MUTATION_UPDATE_USER_ROLE);
  const [UpdateLawyer] = useMutation(UPDATE_LAWYER);

  const getOptionLawyers = () => {
    const lawyerOptions = data?.lawyers
      ?.map((lawyer) => {
        if (!lawyer.userId) {
          return { label: lawyer.name, value: lawyer.id };
        }
      })
      .filter((lawyer) => lawyer);
    return lawyerOptions;
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (p) => {
    setPassword(p.target.value);
  };

  const onClickSubmitSignUp = async () => {
    if (typeof selectLawyer === "undefined") {
      return window.alert("โปรดเลือกทนาย");
    }

    try {
      const { data } = await Register({
        variables: {
          input: {
            username: selectLawyer.label,
            email: email,
            password: password,
          },
        },
      });
      const user = data.register.user;
      await UpdateUserRole({
        variables: {
          input: {
            where: {
              id: user.id,
            },
            data: {
              // role: "60364717bd2d5a24043eb470", //role Lawyer //production
              role: "60473bb28f8aea321f8feda8", //role Lawyer
            },
          },
        },
      });

      // console.log("AL", selectLawyer);
      await UpdateLawyer({
        variables: {
          input: {
            where: {
              id: selectLawyer.value,
            },
            data: {
              userId: data.register.user.id,
            },
          },
        },
      });
      router.push("/congratulation");
    } catch (error) {
      console.log("ERROR", JSON.stringify(error));
    }
  };

  const onChangeSelectLawyer = (selectedValue) => {
    setSelectLawyer(selectedValue);
  };

  if (loading) return <CelesLoading />;

  return (
    <div className="static ">
      <div className=" bg-cover sm:bg-center  font-kanit    ">
        <div className="bg-user1 sm:bg-user2 sm:w-full sm:max-w-3xl sm:mx-auto">
          <div className=" sm:mx-auto  ">
            <div className="sm:p-5">
              {/* <div className="py-6 sm:py-0">
      <img className="mt-2  pl-4   h-6" src="/left.svg"></img> 
      </div> */}
              <div>
                <div className="pt-32 sm:pt-28 pl-4 text-gray-50 font-black">
                  <div className="text-4xl sm:text-5xl ">Create</div>
                  <div className="text-4xl sm:text-5xl font-black">Account</div>
                </div>
                <img
                  className="mt-20 sm:mt-40   mx-auto  h-20 sm:32 w-auto"
                  src="/ariya2.png"
                ></img>
              </div>
              <div className="ml-10 mb-2 text-lg mt-5 sm:mt-10 ">
                <div className="sm:ml-2">Lawyer</div>
              </div>
              <Select
                className="mx-10 sm:mx-12 "
                isSearchable
                options={getOptionLawyers()}
                onChange={onChangeSelectLawyer}
              />

              {/* <div className="grid grid-cols-1 sm:gap-4  ">
                <div className="bg-gray-200 hover:text-green1 cursor-pointer     rounded text-center ml-10 mx-7 sm:mx-12 p-2 px-4 flex  justify-between items-center   ">
                  <div className="flex  items-center text-lg mr-1 hover:text-green1  cursor-pointer">
                    lawyer
                  </div>{" "}
                  <svg
                    className=" w-4 h-4 flex items-center hover:text-green1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                <div className="ml-10 mt-4 text-lg">
                <div className="sm:ml-2">Password</div>
                <div className="sm:mr-14">
                  <input className="bg-gray-200 rounded py-2 w-11/12 sm:w-full sm:ml-2 outline-none pl-4   mt-2" />
                </div>
              </div>
              </div> */}
              <div className="ml-10 mt-4 text-lg">
                <div className="sm:ml-2">E-mail</div>
                <div className="sm:mr-14 mr-3">
                  <input
                    value={email}
                    onChange={onChangeEmail}
                    className="bg-gray-200 rounded py-2 w-11/12 sm:w-full sm:ml-2  outline-none pl-4   mt-2"
                  />
                </div>
              </div>
              <div className="ml-10 mt-4 text-lg">
                <div className="sm:ml-2">Password</div>
                <div className="sm:mr-14 mr-3">
                  <input
                    type="password"
                    value={password}
                    onChange={onChangePassword}
                    className="bg-gray-200 rounded py-2 w-11/12 sm:w-full sm:ml-2 outline-none pl-4   mt-2"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-6 ">
                <div
                  onClick={onClickSubmitSignUp}
                  // onClick={() => router.push("/congratulation")}
                  className="flex  mr-4 text-xl sm:mt-2 hover:text-black  font-semibold  text-white  cursor-pointer bg-green1  p-2 rounded-xl"
                >
                  Sign Up
                </div>
                {/* <div className="flex sm:mt-2 hover:text-green1 cursor-pointer">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                    />
                  </svg>
                </div> */}
              </div>
              <div className="py-10 sm:py-10 xl:py-10 "></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Create;
