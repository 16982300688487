import React, { useContext } from "react";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import CelesSelect from "./CelesSelect";
import SelectLawyer from "./SelectLawyer";
import Lable from "./CelesLabel";
import ComponentProfileArray from "./ComponentProfileArray";
import CelesDatePicker from "./CelesDatePicker";
import CelesButton from "../../lib/CelesButton";
import CelesFormatCurrency from "./CelesFormatCurrency";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

//QUERY
import QUERY_LAWYERS from "../../apollo/queries/queryLawyers";
import { useQuery, useMutation } from "@apollo/client";

const EditProfile = () => {
  const { data, loading, error } = useQuery(QUERY_LAWYERS);

  const {
    form,
    setField,
    checkRadio,
    setFieldMulti,
    removeArray,
    addArray,
  } = useContext(TaskContext);

  const getOptionLawyers = () => {
    const lawyerOptions = data?.lawyers
      ?.map((lawyer) => {
        if (lawyer?.userId) {
          return { label: lawyer.name, value: lawyer.userId };
        }
      })
      .filter((lawyer) => lawyer);
    return lawyerOptions;
  };

  const handleChange = (e, name) => {
    const value = e.target.value;
    setField(name)(value);
  };

  return (
    <div className="p-4">
      <div className="">
        {CategoryTitle("หมวดคู่ความ")}
        <CelesTextFieldWithTitle
          label="รหัสรับงาน"
          name="acceptance_code"
          setField={setField}
          value={form.acceptance_code}
        />
        <div className="grid grid-cols-1">
          <CelesDatePicker
            label="วันรับงาน"
            name="date_accept"
            setField={setField}
            value={form.date_accept}
          />
        </div>
        {Label("สถานะ")}
        <CelesSelect
          options={[
            { value: "รับเรื่อง", label: "รับเรื่อง" },
            { value: "ชะลอฟ้อง", label: "ชะลอฟ้อง" },
            { value: "ชะลองาน", label: "ชะลองาน" },
            { value: "นัดพิจารณา", label: "นัดพิจารณา" },
            { value: "พิพากษา", label: "พิพากษา" },
            { value: "บังคับคดี", label: "บังคับคดี" },
            { value: "ปิดงาน", label: "ปิดงาน" },
          ]}
          name="status"
          setField={setField}
          value={form.status}
          // value={"บังคับคดี"}
        />
        {Label("ฝ่ายคู่ความ")}
        <CelesSelect
          options={[
            { value: "โจทก์", label: "โจทก์" },
            { value: "จำเลย", label: "จำเลย" },
            { value: "ผู้ร้อง", label: "ผู้ร้อง" },
            { value: "ผู้เสียหาย", label: "ผู้เสียหาย" },
          ]}
          name="parties"
          setField={setField}
          value={form.parties}
        />
        <CelesTextFieldWithTitle
          label="ผู้ลงทะเบียน"
          name="register"
          setField={setField}
          value={form.register}
        />
        {Label("ชื่อทนาย")}
        <SelectLawyer
          options={getOptionLawyers()}
          name="lawyer_name"
          setField={setField}
          value={form?.lawyer_name?.label}
        />
        <div className="border-b border-gray-300 my-4"></div>
        {CategoryTitle("ฝ่ายคู่ความ")}
        <ComponentProfileArray
          ComponentName="plaintiffComponent"
          TextName="โจทก์ที่ "
          ButtonName="เพิ่มโจทก์"
        />
        <ComponentProfileArray
          ComponentName="defendantComponent"
          TextName="จำเลยที่ "
          ButtonName="เพิ่มจำเลย"
        />
        <ComponentProfileArray
          ComponentName="complainantComponent"
          TextName="ผู้ร้องที่ "
          ButtonName="เพิ่มผู้ร้อง"
        />
        <ComponentProfileArray
          ComponentName="suffererComponent"
          TextName="ผู้เสียหายที่ "
          ButtonName="เพิ่มผู้เสียหาย"
        />
      </div>
    </div>
  );
};

export default EditProfile;
