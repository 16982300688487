import React, { useState } from "react";
import Select from "react-select";

const CelesSelect = ({
  name,
  options,
  className,
  disable = false,
  value,
  defaultValue,
  // selectedOption = "",
  // setSelectOption,
  setField,
}) => {
  const handleChange = (e) => {
    setField(name)(e.label);

    // if (setSelectOption != undefined) setSelectOption(selectedOption);
  };

  const customStyles = {
    input: () => ({
      height: 36,
      border: "0px solid black",
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid #DDE2E9",
      /*
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid black',
            }*/
    }),
  };
  return (
    <Select
      styles={customStyles}
      // isDisabled={disable}
      isSearchable={true}
      className={className}
      options={options}
      onChange={handleChange}
      value={options?.filter(function (option) {
        return option.label === value;
      })}
      // value={value}
    />
  );
};

export default CelesSelect;
