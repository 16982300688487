import React, { useContext } from "react";
import EditOperation from "../../components/create_task/EditOperation";

function Document() {
  return (
    <React.Fragment>
      <EditOperation />
    </React.Fragment>
  );
}

export default Document;
