import gql from "graphql-tag";

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
    }
  }
`;

export default UPLOAD_FILE;
