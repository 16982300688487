import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const initialApplloClient = () => {
  const httpLink = createHttpLink({
    uri: "https://admin.ariyatham.com/graphql",
    // uri: "http://localhost:1337/graphql",
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const accessToken = localStorage.getItem("jwt");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return {
    client,
  };
};

export default initialApplloClient;
