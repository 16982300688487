import React, { useContext } from "react";
import EditClient from "../../components/create_task/EditClient";

function Court() {
  return (
    <React.Fragment>
      <EditClient />
    </React.Fragment>
  );
}

export default Court;
