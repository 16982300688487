const RadioGroup = ({
  handleChange,
  index,
  radio_1,
  radio_2,
  radio_3,
  radio_4,
}) => {
  return (
    <div className=" p-2 rounded-md border border-gray-200 text-gray-700">
      <div>
        <label className="inline-flex items-center">
          <input
            onChange={(e) =>
              handleChange(e, "collateral_type", "legal_relation", index)
            }
            type="radio"
            className="form-radio text-green-500"
            name="collateral_type"
            value="บุคคลค้ำประกัน"
            checked={radio_1}
          />
          <span className="ml-2">บุคคลค้ำประกัน</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center ">
          <input
            onChange={(e) =>
              handleChange(e, "collateral_type", "legal_relation", index)
            }
            type="radio"
            className="form-radio text-green-500"
            name="collateral_type"
            value="หลักทรัพย์ค้ำประกัน"
            checked={radio_2}
          />
          <span className="ml-2">หลักทรัพย์ค้ำประกัน</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            onChange={(e) =>
              handleChange(e, "collateral_type", "legal_relation", index)
            }
            type="radio"
            className="form-radio text-green-500"
            name="collateral_type"
            value="บุคคลและหลักทรัพย์ค้ำประกัน"
            checked={radio_3}
          />
          <span className="ml-2">บุคคลและหลักทรัพย์ค้ำประกัน</span>
        </label>
      </div>
      <div>
        <label className="inline-flex items-center">
          <input
            onChange={(e) =>
              handleChange(e, "collateral_type", "legal_relation", index)
            }
            type="radio"
            className="form-radio text-green-500"
            name="collateral_type"
            value="ไม่มีหลักประกัน"
            checked={radio_4}
          />
          <span className="ml-2">ไม่มีหลักประกัน</span>
        </label>
      </div>
    </div>
  );
};

export default RadioGroup;
