import React, { useState, useEffect, useCallback, useContext } from "react";
import CelesMultiUpload from "./CelesMultiUpload";
import CelesUpload from "./CelesUpload";
import CelesButton from "../../lib/CelesButton";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

function EditClient() {
  const {
    setField,
    form,
    removeArray,
    addArray,
    onDropFileSingle,
    setFieldMulti,
    setFieldUploadMulti,
  } = useContext(TaskContext);

  const TitleStyle = ({ title }) => {
    return <div className="text-lg mb-2 text-gray-600">{title}</div>;
  };

  console.log("FORM", form);
  return (
    <div className="p-4 ">
      {CategoryTitle("หมวดอัปโหลดเอกสาร")}
      {Title("ขั้นตอนเตรียมงาน/เตรียมเอกสาร")}
      <TitleStyle title="สัญญากู้" />
      <CelesUpload name="loan_agreement" label="สัญญากู้" setField={setField} />
      <TitleStyle title="สัญญาค้ำประกัน" />
      <CelesUpload
        name="surety_contract"
        label="สัญญาค้ำประกัน"
        setField={setField}
      />
      <TitleStyle title="สัญญาจำนอง,ต่อท้าย" />
      <CelesUpload
        label="สัญญาจำนอง,ต่อท้าย"
        name="mortgage"
        setField={setField}
      />
      <TitleStyle title="สำเนาบัตรประชาชน,ทะเบียนบ้าน" />
      <CelesUpload
        label="สำเนาบัตรประชาชน,ทะเบียนบ้าน"
        name="id_card"
        setField={setField}
      />
      {/* หนังสือบอกกล่าวงทวงถาม [index+1] notice_demand */}
      {form?.notice_demand?.length &&
        form.notice_demand.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("หนังสือบอกกล่าวงทวงถาม ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("notice_demand", "file", index)}
                label="หนังสือบอกกล่าวงทวงถาม"
                name="file"
                setFieldMulti={setFieldMulti}
                primaryField="notice_demand"
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("notice_demand", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("notice_demand", { file: null })}
          text="เพิ่มหนังสือบอกกล่าวงทวงถาม"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <TitleStyle title="ประกาศหนังสือพิมพ์" />
      <CelesUpload
        name="newspaper_announcement"
        label="ประกาศหนังสือพิมพ์"
        setField={setField}
      />
      <TitleStyle title="รายการคำนวณยอดหนี้" />
      <CelesUpload
        name="debt_calculation_list"
        label="รายการคำนวณยอดหนี้"
        setField={setField}
      />
      {Label("อื่น ๆ")}
      {form?.etc_book4?.length &&
        form.etc_book4.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              <CelesTextFieldWithTitle
                index={index}
                primaryField="etc_book4"
                name="name"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row?.name}
                label="ชื่อเอกสาร"
              />
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("etc_book4", "file", index)}
                label="อื่น ๆ"
                name="file"
                primaryField="etc_book4"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("etc_book4", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("etc_book4", { name: "", file: null })}
          text="เพิ่มอื่น ๆ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="border-b border-gray-300 my-4"></div>
      {Title("ขั้นตอนฟ้องคดี/ดำเนินคดี")}
      <TitleStyle title="คำฟ้อง" />
      <CelesUpload name="indictment" label="คำฟ้อง" setField={setField} />
      <TitleStyle title="เอกสารท้ายคำฟ้อง" />
      <CelesUpload
        name="end_plaint"
        label="เอกสารท้ายคำฟ้อง"
        setField={setField}
      />
      <TitleStyle title="ใบแต่งทนายความ" />
      <CelesUpload
        name="paper_lawyer"
        label="ใบแต่งทนายความ"
        setField={setField}
      />
      <TitleStyle title="บัญชีระบุพยาน" />
      <CelesUpload
        name="witness_account"
        label="บัญชีระบุพยาน"
        setField={setField}
      />
      <TitleStyle title="คำแถลงปิดหมาย พร้อมเอกสารที่อยู่จำเลย" />
      <CelesUpload
        label="คำแถลงปิดหมาย พร้อมเอกสารที่อยู่จำเลย"
        name="closing_statement"
        setField={setField}
      />
      <TitleStyle title="คำร้องขอเลื่อนกำหนดนัดพิจารณา" />
      <CelesUpload
        name="request_consideration"
        label="คำร้องขอเลื่อนกำหนดนัดพิจารณา"
        setField={setField}
      />
      <TitleStyle title="คำแถลงขออ้างเอกสารท้ายคำฟ้องแทนการส่งสำเนาเอกสาร" />
      <CelesUpload
        name="statement_request_documents"
        label="คำแถลงขออ้างเอกสารท้ายคำฟ้องแทนการส่งสำเนาเอกสาร"
        setField={setField}
      />
      {form?.request_document1?.length &&
        form.request_document1.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("คำร้อง / คำขอ / คำแถลง ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("request_document1", "file", index)}
                label="คำร้อง / คำขอ / คำแถลง"
                name="file"
                primaryField="request_document1"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("request_document1", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("request_document1", { file: null })}
          text="เพิ่มคำร้อง / คำขอ / คำแถลง"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2" />
      <TitleStyle title="หมายเรียกจำเลย" />
      <CelesUpload
        name="summons_defendant"
        label="หมายเรียกจำเลย"
        setField={setField}
      />
      <TitleStyle title="รูปภาพ ,ภาพถ่าย,แผนที่" />
      <CelesUpload
        name="pictures_photos_maps"
        label="รูปภาพ ,ภาพถ่าย,แผนที่"
        setField={setField}
      />
      <TitleStyle title="รายงานการยึด , อายัดทรัพย์" />
      <CelesUpload
        name="report_seizure"
        label="รายงานการยึด , อายัดทรัพย์"
        setField={setField}
      />
      <TitleStyle title="ประกาศขายทอดตลาด" />
      <CelesUpload
        name="auction_announcement"
        label="ประกาศขายทอดตลาด"
        setField={setField}
      />
      <TitleStyle title="บัญชีรับจ่ายเงิน" />
      <CelesUpload
        name="payment_account"
        label="บัญชีรับจ่ายเงิน"
        setField={setField}
      />
      {form?.receipt?.length &&
        form.receipt.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              <div className=" text-md  mb-2  text-gray-700">
                ใบเสร็จรับเงิน ครั้งที่ {index + 1}
              </div>
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("receipt", "file", index)}
                label="ใบเสร็จรับเงิน"
                name="file"
                primaryField="receipt"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("receipt", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("receipt", { file: null })}
          text="เพิ่มใบเสร็จรับเงิน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2" />
      {Label("อื่น ๆ")}
      {form?.etc_book1?.length &&
        form.etc_book1.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              <CelesTextFieldWithTitle
                index={index}
                primaryField="etc_book1"
                name="name"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row?.name}
                label="ชื่อเอกสาร"
              />
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("etc_book1", "file", index)}
                label="อื่น ๆ"
                name="file"
                primaryField="etc_book1"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("etc_book1", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("etc_book1", { name: "", file: null })}
          text="เพิ่มอื่น ๆ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="border-b border-gray-300 my-4"></div>
      {Title("ขั้นตอนนัดพิจารณา/พิพากษา/บังคับคดี")}
      {/* รายงานกระบวนพิจารณา ครั้งที่ [index+1] proceedings_report */}
      {form?.proceedings_report?.length &&
        form.proceedings_report.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("รายงานกระบวนพิจารณา ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("proceedings_report", "file", index)}
                label="รายงานกระบวนการพิจารณา"
                name="file"
                primaryField="proceedings_report"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("proceedings_report", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("proceedings_report", { file: null })}
          text="เพิ่มรายงานกระบวนพิจารณา"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2"></div>
      <TitleStyle title="คำพิพากษา" />
      <CelesUpload
        name="sentence_document"
        label="คำพิพากษา"
        setField={setField}
      />
      <TitleStyle title="คำบังคับ" />
      <CelesUpload
        name="decree_document"
        label="คำบังคับ"
        setField={setField}
      />
      <TitleStyle title="บัญชีค่าฤชาธรรมเนียม" />
      <CelesUpload
        name="fee_account"
        label="บัญชีค่าฤชาธรรมเนียม"
        setField={setField}
      />
      <TitleStyle title="หมายบังคับคดี" />
      <CelesUpload
        name="warrant_execution"
        label="หมายบังคับคดี"
        setField={setField}
      />
      <TitleStyle title="หนังสือรับรองคดีถึงที่สุด" />
      <CelesUpload
        name="certificate_final_case"
        label="หนังสือรับรองคดีถึงที่สุด"
        setField={setField}
      />
      {/* คำร้อง / คำขอ / คำแถลง" [index+1] request_document2 */}
      {form?.request_document2?.length &&
        form.request_document2.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("คำร้อง / คำขอ / คำแถลง ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("request_document2", "file", index)}
                label="คำร้อง / คำขอ / คำแถลง"
                name="file"
                primaryField="request_document2"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("request_document2", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("request_document2", { file: null })}
          text="เพิ่มคำร้อง / คำขอ / คำแถลง"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2" />
      {form?.court_order?.length &&
        form.court_order.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("คำสั่งศาล ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("court_order", "file", index)}
                label="คำสั่งศาล"
                name="file"
                primaryField="court_order"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("court_order", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("court_order", { file: null })}
          text="เพิ่มคำสั่งศาล"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2" />
      {form?.upload_receipt?.length &&
        form.upload_receipt.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("ใบเสร็จรับเงิน ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("upload_receipt", "file", index)}
                label="ใบเสร็จรับเงิน"
                name="file"
                primaryField="upload_receipt"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("upload_receipt", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("upload_receipt", { file: null })}
          text="เพิ่มใบเสร็จรับเงิน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="pt-2" />
      <div className="border-b border-gray-300 my-4"></div>
      {Label("อื่น ๆ")}
      {form?.etc_book2?.length &&
        form.etc_book2.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              <CelesTextFieldWithTitle
                index={index}
                primaryField="etc_book2"
                name="name"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.name}
                label="ชื่อเอกสาร"
              />
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("etc_book2", "file", index)}
                label="อื่น ๆ"
                name="file"
                primaryField="etc_book2"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("etc_book2", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("etc_book2", { name: "", file: null })}
          text="เพิ่มอื่น ๆ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      <div className="border-b border-gray-300 my-4"></div>
      {Title("ขั้นตอนติดต่อกับลูกความ")}
      <TitleStyle title="หนังสือเบิกเอกสาร" />
      <CelesUpload
        name="withdraw_documents"
        label="หนังสือเบิกเอกสาร"
        setField={setField}
      />
      {form?.opinion_book?.length &&
        form.opinion_book.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("หนังสือความเห็น ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("opinion_book", "file", index)}
                label="หนังสือความเห็น"
                name="file"
                primaryField="opinion_book"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("opinion_book", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("opinion_book", { file: null })}
          text="เพิ่มหนังสือความเห็น"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
      {/* หนังสือเบิกเงิน [index+1] withdrawal_book */}
      {form?.withdrawal_book?.length &&
        form.withdrawal_book.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              {Label("หนังสือเบิกเงิน ครั้งที่ " + [index + 1])}
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("withdrawal_book", "file", index)}
                label="หนังสือเบิกเงิน"
                name="file"
                primaryField="withdrawal_book"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("withdrawal_book", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("withdrawal_book", { file: null })}
          text="เพิ่มหนังสือเบิกเงิน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>

      <div className="mt-4" />
      {Label("อื่น ๆ")}
      {form?.etc_book3?.length &&
        form.etc_book3.map((row, index) => {
          return (
            <div className="grid grid-row-1">
              <CelesTextFieldWithTitle
                index={index}
                primaryField="etc_book3"
                name="name"
                form={form}
                setFieldMulti={setFieldMulti}
                value={row.name}
                label="ชื่อเอกสาร"
              />
              <CelesMultiUpload
                index={index}
                onDrop={onDropFileSingle("etc_book3", "file", index)}
                label="อื่น ๆ"
                name="file"
                primaryField="etc_book3"
                setFieldMulti={setFieldMulti}
              />
              <div className="pt-2" />
              <button
                onClick={() => removeArray("etc_book3", index)}
                className="bg-red-500 text-white p-2  rounded"
                hidden={index === 0}
              >
                Delete
              </button>
            </div>
          );
        })}
      <div className="pt-2" />
      <div className="grid grid-row-1">
        <CelesButton
          onClick={() => addArray("etc_book3", { name: "", file: null })}
          text="เพิ่มอื่น ๆ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </div>
    </div>
  );
}
export default EditClient;
