import gql from "graphql-tag";

const QUERY_LAWYERS = gql`
  query {
    lawyers {
      name
      id
      userId
    }
  }
`;
export default QUERY_LAWYERS;
