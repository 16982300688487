const MainTextArea = ({ title = "", name = "" }) => {
  if (!name) return <div></div>;
  return (
    <div className="my-6 text-gray-700 text-lg text-bold md:text-xl">
      <div>{title} </div>
      <div className="my-4 p-4 text-gray-500 text-sm bg-gray-100 md:text-lg md:my-4 shadow rounded-lg">
        {name}
      </div>
    </div>
  );
};
export default MainTextArea;
