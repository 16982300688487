import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";

// CONTEXT
import { AppContext } from "../context/AppContext";

const NavBar = () => {
  const { user } = useContext(AppContext);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const router = useHistory();

  const ref = useRef();
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("uid");
    localStorage.removeItem("userName");
    localStorage.removeItem("roleName");

    router.push(`/user`);
  };

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowProfileMenu(false);
  };

  const checkUser = () => {
    const roleName = localStorage.getItem("roleName");
    if (roleName === "User") return true;
    return false;
  };
  //test

  if (checkUser()) {
    return (
      <nav className="bg-white  w-full">
        <div className="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button*/}
              <button
                onClick={() => setShowMenu(!showMenu)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {/* Icon when menu is closed. */}
                {/*
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Icon when menu is open. */}
                {/*
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <a href="/"></a>

            <div className="flex-1 hidden md:flex items-center justify-center sm:items-stretch sm:justify-start ">
              <a href="/">
                <img className="w-10 h-10 ml-6" src="/ariya2.png" />
              </a>
              <div className="flex-shrink-0 flex items-center"></div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4"></div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* Profile dropdown */}
              <div
                className="ml-3 relative"
                onClick={() => setShowProfileMenu(true)}
              >
                <div>
                  <span className="sr-only">Open user menu</span>
                  <div className="h-8 w-8 rounded-full mr-2 sm:mr-5 max-w-3xl ">
                    <Avatar name={user.userName} size="40" round={true} />
                  </div>
                </div>

                <div
                  ref={ref}
                  className={
                    (showProfileMenu ? "" : "hidden") +
                    " origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50"
                  }
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div
                    onClick={() => logout()}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer "
                    role="menuitem"
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*       
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  */}
        {showMenu && (
          <div className=" block sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <a
                href="/"
                className="text-gray-700 hover:bg-green1 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
              >
                Home
              </a>
            </div>
          </div>
        )}
      </nav>
    );
  }

  return (
    <nav className="bg-white  w-full">
      <div className="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed. */}
              {/*
              Heroicon name: menu
  
              Menu open: "hidden", Menu closed: "block"
            */}
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Icon when menu is open. */}
              {/*
              Heroicon name: x
  
              Menu open: "block", Menu closed: "hidden"
            */}
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <a href="/"></a>

          <div className="flex-1 hidden md:flex items-center justify-center sm:items-stretch sm:justify-start mt-8 ">
            <a href="/">
              <img className="w-10 h-10 ml-6" src="/ariya2.png" />
            </a>
            <div className="flex-shrink-0 flex items-center"></div>
            {localStorage.getItem("roleName") !== "Lawyer" && (
              <div className="grid grid-rows-2 text-center">
                <a
                  className="flex-shrink-0 sm:flex items-center ml-28 hidden hover:text-green1     "
                  href="/calendar"
                >
                  <img
                    className=" h-8  w-8  flex-row  mr-2 mt-1  items-center"
                    src="../calendar.svg"
                  />
                </a>
                <div className="text-xs ml-24 pl-1">Calendar</div>
              </div>
            )}
            <div className="grid grid-rows-2 text-center">
              <a
                className="flex-shrink-0 sm:flex items-center ml-28 hidden hover:text-green1 "
                href="/newtask"
              >
                <img
                  className=" h-8  w-8  flex-row  mr-2 mt-1  items-center"
                  src="../compliant2.svg"
                />
              </a>
              <div className="text-xs ml-24 pl-2">Add Task</div>
            </div>
            {localStorage.getItem("roleName") !== "Lawyer" && (
              <div className="grid grid-rows-2 text-center">
                <a
                  className="flex-shrink-0 sm:flex items-center  ml-28 hidden hover:text-green1 "
                  href="/create"
                >
                  <img
                    className=" h-8  w-8  flex-row  mr-2 mt-1  items-center"
                    src="../newlawyer.svg"
                  />
                </a>
                <div className="text-xs ml-24 pl-1">New Lawyer</div>
              </div>
            )}
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4"></div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}
            <div
              className="ml-3 relative "
              onClick={() => setShowProfileMenu(true)}
            >
              <div>
                <span className="sr-only">Open user menu</span>
                <div className="h-8 w-8 rounded-full mr-2 sm:mr-5 max-w-3xl ">
                  <Avatar name={user.userName} size="40" round={true} />
                </div>
              </div>

              <div
                ref={ref}
                className={
                  (showProfileMenu ? "" : "hidden") +
                  " origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50"
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <div
                  onClick={() => logout()}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer "
                  role="menuitem"
                >
                  Sign out
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*       
      Mobile menu, toggle classes based on menu state.
  
      Menu open: "block", Menu closed: "hidden"
    */}
      {showMenu && (
        <div className=" block sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
            <a
              href="/"
              className="text-gray-700 hover:bg-green1 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
            >
              Home
            </a>
            <a
              href="/calendar"
              className="text-gray-700 hover:bg-green1 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
            >
              Calendar
            </a>
            <a
              href="/newtask"
              className="text-gray-700 hover:bg-green1 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
            >
              Add Task
            </a>
            <a
              href="/create"
              className="text-gray-700 hover:bg-green1 hover:text-white block px-3 py-2 rounded-md text-base font-medium text-center"
            >
              Create Lawyer
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
