import { useState, createContext, useCallback } from "react";
import useForm from "../hooks/useForm";

// create context
const TaskContext = createContext({});

// context provider
const TaskProvider = ({ children }) => {
  const [editing, setEditing] = useState(false);

  const {
    form,
    setField,
    setForm,
    setFieldMulti,
    setFieldRemoveMulti,
    setFieldAddMulti,
    setFieldUploadMulti,
  } = useForm({
    plaintiffComponent: [
      {
        name: "",
        citizen_number: "",
        address: "",
        position: "",
        phone_number: "",
        email: "",
      },
    ],
    defendantComponent: [
      {
        name: "",
        citizen_number: "",
        address: "",
        position: "",
        phone_number: "",
        email: "",
      },
    ],
    complainantComponent: [
      {
        name: "",
        citizen_number: "",
        address: "",
        position: "",
        phone_number: "",
        email: "",
      },
    ],
    suffererComponent: [
      {
        name: "",
        citizen_number: "",
        address: "",
        position: "",
        phone_number: "",
        email: "",
      },
    ],
    agencyComponent: [
      {
        agency_name: "",
        case_type: "",
        black_case: "",
        red_case: "",
        capital: "",
      },
    ],
    proceedingsComponent: [
      {
        text: "",
      },
    ],
    legal_executionComponent: [
      {
        agency_name: "",
        court: "",
        black_case: "",
        red_case: "",
        capital: "",
      },
    ],

    introduction_date: [
      {
        date: "",
      },
    ],
    consideration_date: [
      {
        date: "",
      },
    ],
    appeal_deadline: [
      {
        date: "",
      },
    ],
    supreme_court_date: [
      {
        date: "",
      },
    ],
    public_auction_date: [
      {
        date: "",
      },
    ],
    money_from_execution: [
      {
        date: "",
      },
    ],
    receipt_payment_account: [
      {
        date: "",
      },
    ],
    proceedings_report: [
      {
        file: null,
      },
    ],
    notice_demand: [
      {
        file: null,
      },
    ],
    request_document1: [
      {
        file: null,
      },
    ],
    request_document2: [
      {
        file: null,
      },
    ],
    withdrawal_book: [
      {
        file: null,
      },
    ],
    detail_payment: [
      {
        //
        received_date: "",
        reveal_date: "",
        //
        ask_cost: "",
        lump_cost: "",
        professional_fee: "",
        etc_cost: "",
      },
    ],
    // ส่วนนี้ยังไม่ได้แอดหลังบ้าน
    legal_relation: [
      {
        account_number: "",
        principle: "",
        interest: "",
        total_cost: "",
        bank: "",
        branch: "",
        county: "",
        typy_loan: "",
        collateral_type: "",
        detail_type: "",
      },
    ],
    detail_shortly: [
      {
        brief_indictment: "",
        judgment_details: "",
      },
    ],
    official_appraisal_price: [
      {
        cost: "",
      },
    ],
    introductory_date: [
      {
        date: "",
      },
    ],
    declaration_date: [
      {
        date: "",
      },
    ],
    submission_document: [
      {
        name: "",
        date: "",
      },
    ],
    recived_document: [
      {
        name: "",
        date: "",
      },
    ],
    sell_to_public: [
      {
        date: "",
      },
    ],
    receipt: [
      {
        file: null,
      },
    ],
    court_order: [
      {
        file: null,
      },
    ],
    upload_receipt: [
      {
        file: null,
      },
    ],
    opinion_book: [
      {
        file: null,
      },
    ],
    etc_book1: [
      {
        name: "",
        file: null,
      },
    ],
    etc_book2: [
      {
        name: "",
        file: null,
      },
    ],
    etc_book4: [
      {
        name: "",
        file: null,
      },
    ],
    etc_book3: [
      {
        name: "",
        file: null,
      },
    ],
    operation_record: [
      {
        text: "",
      },
    ],
  });

  const onDropFileSingle = (primaryField, field, index) => (acceptedFiles) => {
    setFieldMulti(field, primaryField, index)(acceptedFiles[0]);
  };

  const removeArray = (primaryField, index) => {
    setFieldRemoveMulti(primaryField, index)();
  };

  const addArray = (primaryField, defaultValue) => {
    setFieldAddMulti(primaryField, defaultValue);
  };

  const STRAPI_URL = "https://admin.ariyatham.com";

  const value = {
    form,
    setField,
    setForm,
    setFieldMulti,
    setFieldRemoveMulti,
    setFieldAddMulti,
    addArray,
    removeArray,
    onDropFileSingle,
    STRAPI_URL,
    editing,
    setEditing,
    setFieldUploadMulti,
  };

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export { TaskContext, TaskProvider };
