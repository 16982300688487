const MainCalendar = () => {
  return (
    <div className=" my-4 mx-4 sm:mx-0  ">
      <iframe
        src="https://calendar.google.com/calendar/embed?src=gf9l1vs85fk5kdlr4strsqnr2k%40group.calendar.google.com&ctz=Asia%2FBangkok"
        style={{ borderWidth: 0 }}
        className=" sm:mx-auto h-96   w-full  sm:w-8/12 xl:w-6/12   "
        // width={800}
        // height={600}
        frameBorder={0}
        scrolling="no"
      />
    </div>
  );
};

export default MainCalendar;
