//APPOLLO
import controller from "../../apollo/controller";

//เอา eid จาก apllo ไปใช้เพื่อ update
var gapi = window.gapi;
var CLIENT_ID =
  "631323867453-r8arlrmci68jaricci2nhqc14vjsmg22.apps.googleusercontent.com";
var API_KEY = "AIzaSyA9BMSXu-R7Qn7YG8maUMUqn9G668MNz1U";
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
var SCOPES = "https://www.googleapis.com/auth/calendar.events";
const CALENDAR_ID = `gf9l1vs85fk5kdlr4strsqnr2k@group.calendar.google.com`;

// ทำ List เดียว  ใช้ได้ทุก Function
// ทุก วัน จะผ่าน Function นี้ หมายความว่า 7 วันต้องผ่าน <Render>

export const addGoogleEvent = ({ taskId, form, router }) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  gapi.load("client:auth2", async () => {
    // console.log("loaded");
    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    });
    gapi.client.load("calendar", "v3", () => console.log("bam!"));

    const getFromThaiDate = (date) => {
      const dd = date.split("/")[0];
      const mm = date.split("/")[1];
      const yy = date.split("/")[2];
      return Number(yy) - 543 + "-" + mm + "-" + dd;
    };

    const formatDate = (date) => {
      if (!date) return;
      const DATE_WITH_TIME = 15;
      if (date.length > DATE_WITH_TIME) {
        //DATE WITH TIME
        const dateSelect = getFromThaiDate(date.split(" ")[0]);
        const timeSelect = "T" + date.split(" ")[1] + ":00+07:00";
        return dateSelect + timeSelect;
      } else {
        //DATE;
        const dateSelect = getFromThaiDate(date);
        const defaultTime = "T09:00:00+07:00";
        return dateSelect + defaultTime;
      }
    };

    //ที่บวกชั่วโมง
    const addHour = (time) => {
      if (!time) return;
      const hh = time.split(":")[0];
      const mm = time.split(":")[1];
      const result = Number(hh) + 3;
      return result + ":" + mm;
    };

    const formatEndTime = (date) => {
      if (!date) return;
      const DATE_WITH_TIME = 15;
      if (date.length > DATE_WITH_TIME) {
        //DATE WITH TIME
        const dateSelect = getFromThaiDate(date.split(" ")[0]);
        const object = date.split(" ")[1];
        const addHours = addHour(object);
        const timeSelectplus = "T" + addHours + ":00+07:00";
        return dateSelect + timeSelectplus;
      } else {
        //DATE;
        const dateSelect = getFromThaiDate(date);
        const defaultTime = "T12:00:00+07:00";
        return dateSelect + defaultTime;
      }
    };

    // const loaded = await gapi.auth2.getAuthInstance().signIn();

    // console.log("LOAD", loaded);
    // if (loaded?.uc?.id_token) {

    //   router.push("/");
    // }

    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(async () => {
        const insertCalendar = async ({
          desc,
          startDate,
          endTime,
          EVENT_FIELD,
          keyEventAction,
          eid,
        }) => {
          let event = {
            summary: form.defendantComponent[0].name,
            location: form.agencyComponent[0].agency_name,
            description: desc + form.lawyer_name,
            start: {
              dateTime: startDate,
              timeZone: "Asia/Bangkok",
            },
            end: {
              dateTime: endTime,
              timeZone: "Asia/Bangkok",
            },
            reminders: {
              useDefault: true,
            },
          };

          let requestSameData = {
            calendarId: CALENDAR_ID,
            resource: event,
          };

          let requestData = {
            insert: {
              ...requestSameData,
            },
            update: {
              ...requestSameData,
              eventId: eid,
            },
          };

          let requestGo = gapi.client.calendar.events[keyEventAction](
            requestData[keyEventAction]
          );
          await requestGo.execute(async (event) => {
            if (!event) return;
            switch (keyEventAction) {
              case "update":
                console.log("UPDATE EVENT", event);
                break;
              case "insert":
                console.log("CREATE EVENT");
                await controller.updateEventId(taskId, EVENT_FIELD, event.id);
              default:
                console.log("UNKNOWN KEY");
                break;
            }
          });
        };

        const insertMultiCalendar = async ({
          desc,
          startDate,
          endDate,
          eventField,
          prevId,
          eventId,
          type,
        }) => {
          let event = {
            summary: form.defendantComponent[0].name,
            location: form.agencyComponent[0].agency_name,
            description: desc + form.lawyer_name,
            start: {
              dateTime: startDate,
              timeZone: "Asia/Bangkok",
            },
            end: {
              dateTime: endDate,
              timeZone: "Asia/Bangkok",
            },
            reminders: {
              useDefault: true,
            },
          };

          switch (type) {
            case "ADD_NEW":
              {
                let request = gapi.client.calendar.events.insert({
                  calendarId: CALENDAR_ID,
                  resource: event,
                });
                await request.execute(async (event) => {
                  if (!event) return;
                  const payload = [{ eid: event.id }];
                  await controller.updateMultiEventId(
                    taskId,
                    eventField,
                    payload
                  );
                  console.log(`EVENT: `, event);
                });
              }
              break;

            case "ADD_MORE":
              {
                let request = gapi.client.calendar.events.insert({
                  calendarId: CALENDAR_ID,
                  resource: event,
                });
                await request.execute(async (event) => {
                  if (!event) return;
                  const payload = [...prevId, { eid: event.id }];
                  await controller.updateMultiEventId(
                    taskId,
                    eventField,
                    payload
                  );
                  console.log(`EVENT: `, event);
                });
              }
              break;

            case "UPDATE":
              {
                let request = gapi.client.calendar.events.update({
                  calendarId: CALENDAR_ID,
                  resource: event,
                  eventId: eventId,
                });
                await request.execute(async (event) => {
                  if (!event) return;

                  console.log(`EVENT: `, event);
                });
              }
              break;

            default:
              break;
          }
        };

        //Array =====================================================================
        ["consideration_date", "appeal_deadline", "supreme_court_date"].map(
          async (field) => {
            const EVENT_FIELD = "eid_" + field;
            //แปลงวันที่ใส่ Array
            const lineDate = form[field].map((item) => ({
              startDate: formatDate(item.date),
              endDate: formatEndTime(item.date),
            }));

            //เช็คว่าสร้าง Task ครั้งแรก
            const isNewDate = !form[EVENT_FIELD];

            //เช็คว่า Add วันเพิ่ม
            const isAddMore = form[field]?.length > form[EVENT_FIELD]?.length;

            //เช็คอัพเดท
            const isUpdate = form[field]?.length === form[EVENT_FIELD]?.length;

            //แปลง eid ใส่ Array
            const previousEventId = form[EVENT_FIELD]?.map((el) => ({
              eid: el.eid,
            }));

            const getDescMulti = (name) => {
              const key = name;
              switch (key) {
                case "consideration_date":
                  return "วันนัดพิจารณา / สืบพยาน ครั้งที่";
                case "appeal_deadline":
                  return "วันครบกำหนดอุทธรณ์คำพิพากษา ครั้งที่";
                case "supreme_court_date":
                  return "วันครบกำหนดฎีกา ครั้งที่";
                default:
                  return "-99";
              }
            };

            const descCalendar = getDescMulti(field);
            // ADD NEW SUCCESS 100%
            console.log(`isNewDate: `, isNewDate);
            if (isNewDate) {
              if (!lineDate[0]?.startDate) return;
              await insertMultiCalendar({
                desc: `${descCalendar}1`,
                startDate: lineDate[0].startDate,
                endDate: lineDate[0].endDate,
                eventField: EVENT_FIELD,
                type: "ADD_NEW",
              });
            }
            // ADD MORE SUCCESS
            if (!isNewDate) {
              lineDate.map(async (item, index) => {
                if (!item?.startDate) return;
                const lastIndex = form?.consideration_date?.length - 1;
                const isNew = index === lastIndex;
                if (isAddMore && isNew) {
                  await insertMultiCalendar({
                    desc: `${descCalendar} ${index + 1}`,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    eventField: EVENT_FIELD,
                    prevId: previousEventId,
                    type: "ADD_MORE",
                  });
                }
              });
            }
            // UPDATE SUCCESS
            if (isUpdate) {
              lineDate.map(async (item, index) => {
                if (!item?.startDate) return;
                const eventId = previousEventId[index].eid;
                await insertMultiCalendar({
                  desc: `${descCalendar} ${index + 1}`,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  eventField: EVENT_FIELD,
                  prevId: previousEventId,
                  eventId: eventId,
                  type: "UPDATE",
                });
              });
            }
          }
        );

        //Single =====================================================================
        const getKeyEventAction = (value) => (!value ? "insert" : "update");

        const getDescName = (name) => {
          const key = name;
          switch (key) {
            case "mediation_date":
              return "วันนัดไกล่เกลี่ย";
            case "appointment_judgment_date":
              return "วันนัดฟังคำพิพากษา";
            case "appointment_decision_date":
              return "วันนัดฟังคำพิพากษาศาลอุทธรณ์";
            case "appointment_petition_date":
              return "วันนัดฟังคำพิพากษาศาลฎีกา";
            default:
              return "-99";
          }
        };
        [
          "mediation_date",
          "appointment_judgment_date",
          "appointment_decision_date",
          "appointment_petition_date",
        ].map(async (field) => {
          if (!form[field]) return;
          const startDate = formatDate(form[field]);
          const endTime = formatEndTime(form[field]);
          const eid = form["eid_" + field];
          const desc = getDescName(field);

          const keyEventAction = getKeyEventAction(eid);
          await insertCalendar({
            desc,
            startDate,
            endTime,
            EVENT_FIELD: "eid_" + field,
            keyEventAction,
            eid,
          });
        });

        delay(1500);
        router.push("/");
      });
  });
};
