import React from "react";
import EditProfile from "../../components/create_task/EditProfile";

function Defendant() {
  return (
    <React.Fragment>
      <EditProfile />
    </React.Fragment>
  );
}

export default Defendant;
