const CelesButton = ({
  onClick,
  color = "green-500",
  width = "auto",
  primary = "true",
  text = "บันทึก",
  icon = "M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4",
}) => {
  const handleClick = (e) => {
    if (onClick != null || onClick != undefined) {
      // console.log("CLICK");
      onClick();
    }
  };

  const prim = () => {
    return "text-white bg-" + color;
  };

  const sec = () => {
    return "border border-" + color + " text-" + color;
  };

  return (
    <button
      type="submit"
      onClick={handleClick}
      className={
        (primary ? prim() : sec()) +
        " p-2 rounded flex justify-center w-" +
        width
      }
    >
      {
        <div className="mr-1 flex justify-center ">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={icon}
            />
          </svg>
        </div>
      }
      {text}
    </button>
  );
};

export default CelesButton;
