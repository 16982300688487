import React, { useState, useContext } from "react";
import Stepper from "../../components/create_task/Stepper";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

//QUERY

function NewTask() {
  const [step, setStep] = useState(0);

  const { form } = useContext(TaskContext);

  return (
    <div className="bg-gray-200 relative font-kanit">
      <div className="bg-gray-100 pb-32 max-w-3xl w-full mx-auto md:bg-gray-200">
        <div className="p-4 mx-0 bg-gray-50">
          <Stepper form={form} step={step} setStep={setStep} />
        </div>
      </div>
    </div>
  );
}

export default NewTask;
