import React, { useState, useEffect, useCallback, useContext } from "react";
import CelesDate from "./CelesDatePicker";
import CelesDateTime from "./CelesDateTimePicker";
import CelesButton from "../../lib/CelesButton";
import TextArea from "./TextArea";
import CelesTextFieldWithTitle from "./CelesTextFieldWithTitle";
import { CategoryTitle, Title, SubTitle, Label } from "./TitleStyle";

//CONTEXT
import { TaskContext } from "../../context/TaskContext";

function EditClient() {
  const { setField, form, setFieldMulti, removeArray, addArray } = useContext(
    TaskContext
  );

  // const getFromThaiDate = (date) => {
  //   const dd = date.split("/")[0];
  //   const mm = date.split("/")[1];
  //   const yy = date.split("/")[2];
  //   return Number(yy) - 543 + "-" + mm + "-" + dd;
  // };

  // const formatDate = (date) => {
  //   if (!date) return;

  //   const DATE_WITH_TIME = 15;
  //   if (date.length > DATE_WITH_TIME) {
  //     //DATE WITH TIME
  //     // console.log(date);

  //     const dateSelect = getFromThaiDate(date.split(" ")[0]);
  //     const timeSelect = "T" + date.split(" ")[1] + ":00+00:00";
  //     return dateSelect + timeSelect;
  //   } else {
  //     //DATE;
  //     const dateSelect = getFromThaiDate(date);
  //     const defaultTime = "T01:00:00+07:00";
  //     return dateSelect + defaultTime;
  //   }
  // };

  // const addHour = (time) => {
  //   if (!time) return;
  //   const hh = time.split(":")[0];
  //   const mm = time.split(":")[1];
  //   // console.log(`getHour: `, getHour);
  //   const result = Number(hh) + 3;
  //   // console.log(`result: `, resultv );

  //   return result + ":" + mm;
  // };

  // const formatEndTime = (date) => {
  //   if (!date) return;

  //   const DATE_WITH_TIME = 15;
  //   if (date.length > DATE_WITH_TIME) {
  //     //DATE WITH TIME

  //     const dateSelect = getFromThaiDate(date.split(" ")[0]);
  //     const object = date.split(" ")[1];
  //     const addHours = addHour(object);
  //     const timeSelectplus = "T" + addHours + ":00+07:00";

  //     return dateSelect + timeSelectplus;
  //   } else {
  //     //DATE;
  //     const dateSelect = getFromThaiDate(date);
  //     const defaultTime = "T12:00:00+07:00";
  //     return dateSelect + defaultTime;
  //   }
  // };

  return (
    <div className="p-4 ">
      <div className="grid grid-row-1">
        {CategoryTitle("หมวดการดำเนินงาน")}
        {Title("ขั้นตอนเตรียมงาน/เตรียมเอกสาร")}
        {form?.introduction_date?.length &&
          form.introduction_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="introduction_date"
                  name="date"
                  label={" บอกกล่าวทวงถาม ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("introduction_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("introduction_date", { date: "" })}
          text="เพิ่มบอกกล่าวทวงถาม"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="newspaper_request_date"
          label="ขอประกาศหนังสือพิมพ์"
          setField={setField}
          value={form.newspaper_request_date}
        />
        <CelesDate
          name="delay_sue"
          label="ชะลอฟ้อง"
          setField={setField}
          value={form.delay_sue}
        />
        <CelesDate
          name="delay_work_date"
          label="ชะลองาน"
          setField={setField}
          value={form.delay_work_date}
        />
        <div className="border-b border-gray-300 my-4"></div>
        {Title("ขั้นตอนฟ้องคดี/ดำเนินคดี/พิพากษา")}
        <CelesDate
          name="filing_date"
          label="วันฟ้อง"
          setField={setField}
          value={form.filing_date}
        />
        {form?.introductory_date?.length &&
          form.introductory_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="introductory_date"
                  name="date"
                  label={"วันนำหมาย ฯ ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("introductory_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("introductory_date", { date: "" })}
          text="เพิ่มวันนำหมาย ฯ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="announcement_date"
          label="วันขอประกาศ ฯ"
          setField={setField}
          value={form.announcement_date}
        />
        <CelesDate
          name="counterclaim_date"
          label="วันยื่นคำให้การ / ฟ้องแย้ง"
          setField={setField}
          value={form.counterclaim_date}
        />
        <CelesDateTime
          name="mediation_date"
          label="วันนัดไกล่เกลี่ย"
          form={form}
          setField={setField}
          value={form.mediation_date}
        />
        {form?.consideration_date?.length &&
          form.consideration_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDateTime
                  index={index}
                  primaryField="consideration_date"
                  name="date"
                  label={"วันนัดพิจารณา / สืบพยาน ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("consideration_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("consideration_date", { date: "" })}
          text="เพิ่มวันนัดพิจารณา"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDateTime
          name="appointment_judgment_date"
          label="วันนัดฟังคำพิพากษา"
          value={form.appointment_judgment_date}
          setField={setField}
        />
        <CelesDate
          name="judgment_reading_date"
          label="วันอ่านคำพิพากษา"
          setField={setField}
          value={form.judgment_reading_date}
        />
        {form?.appeal_deadline?.length &&
          form.appeal_deadline.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="appeal_deadline"
                  name="date"
                  label={"วันครบกำหนดอุทธรณ์คำพิพากษา ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("appeal_deadline", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("appeal_deadline", { date: "" })}
          text="เพิ่มวันครบกำหนดอุทธรณ์คำพิพากษา"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="appeal_filing_date"
          label="วันยื่นฟ้องอุทธรณ์"
          setField={setField}
          value={form.appeal_filing_date}
        />
        <CelesDate
          name="submission_decision_date"
          label="วันยื่นคำให้การแก้ฟ้องอุทธรณ์"
          setField={setField}
          value={form.submission_decision_date}
        />
        <CelesDateTime
          name="appointment_decision_date"
          label="วันนัดฟังคำพิพากษาศาลอุทธรณ์"
          setField={setField}
          value={form.appointment_decision_date}
        />
        <CelesDate
          name="read_decision_date"
          label="วันอ่านคำพิพากษาศาลอุทธรณ์"
          setField={setField}
          value={form.read_decision_date}
        />
        {form?.supreme_court_date?.length &&
          form.supreme_court_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="supreme_court_date"
                  name="date"
                  label={"วันครบกำหนดฎีกาคำพิพากษา ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("supreme_court_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("supreme_court_date", { date: "" })}
          text="เพิ่มวันครบกำหนดฎีกาคำพิพากษา"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="petition_date"
          label="วันยื่นฟ้องฎีกา"
          value={form.petition_date}
          setField={setField}
        />
        <CelesDate
          name="submission_petition_date"
          label="วันยื่นคำให้การแก้ฟ้องฎีกา"
          value={form.submission_petition_date}
          setField={setField}
        />
        <CelesDateTime
          name="appointment_petition_date"
          label="วันนัดฟังคำพิพากษาศาลฎีกา"
          value={form.appointment_petition_date}
          setField={setField}
        />
        <CelesDate
          name="read_petition_date"
          label="วันอ่านคำพิพากษาศาลฎีกา"
          value={form.read_petition_date}
          setField={setField}
        />
        {Title("ขั้นตอนภายหลังพิพากษา")}
        {form?.declaration_date?.length &&
          form.declaration_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="declaration_date"
                  name="date"
                  label={"วันนำคำบังคับ ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("declaration_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("declaration_date", { date: "" })}
          text="เพิ่มวันนำคำบังคับ"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="announcement_date2"
          label="วันขอประกาศ"
          value={form.announcement_date2}
          setField={setField}
        />
        <CelesDate
          name="submission_execution_date"
          label="วันยื่นออกหมายบังคับคดี"
          value={form.submission_execution_date}
          setField={setField}
        />
        <CelesDate
          name="execution_date"
          label="วันพิมพ์หมายบังคับคดี"
          value={form.execution_date}
          setField={setField}
        />
        <CelesDate
          name="final_certificate_date"
          label="วันยื่นขอหนังสือรับรองคดีถึงที่สุด"
          value={form.final_certificate_date}
          setField={setField}
        />
        <CelesDate
          name="publication_certificate_date"
          label="วันพิมพ์หนังสือรับรองคดีถึงที่สุด"
          value={form.publication_certificate_date}
          setField={setField}
        />
        <CelesDate
          name="accept_certificate_date"
          label="วันขอรับเอกสารต้นฉบับคืนจากศาล"
          value={form.accept_certificate_date}
          setField={setField}
        />
        <CelesDate
          name="receive_certificate_date"
          label="วันได้รับเอกสารต้นฉบับคืนจากศาล"
          value={form.receive_certificate_date}
          setField={setField}
        />
        <CelesDate
          name="claim_fee_date"
          label="วันยื่นขอรับเงินค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
          value={form.claim_fee_date}
          setField={setField}
        />
        <CelesDate
          name="receive_fee_date"
          label="วันได้รับเงินค่าธรรมเนียมส่วนที่ศาลสั่งคืน"
          value={form.receive_fee_date}
          setField={setField}
        />
        <div className="mt-2" />
        {form?.submission_document?.length &&
          form.submission_document.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                {Label("วันยื่นคัดเอกสาร ครั้งที่ " + [index + 1])}
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="submission_document"
                  name="name"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.name}
                  label="เอกสาร"
                />
                <CelesDate
                  index={index}
                  primaryField="submission_document"
                  name="date"
                  label={"วันที่"}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("submission_document", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() =>
            addArray("submission_document", { name: "", date: "" })
          }
          text="เพิ่มวันยื่นคัดเอกสาร"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        {form?.recived_document?.length &&
          form.recived_document.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                {Label("วันได้รับเอกสาร ครั้งที่ " + [index + 1])}
                <CelesTextFieldWithTitle
                  index={index}
                  primaryField="recived_document"
                  name="name"
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.name}
                  label="เอกสาร"
                />
                <CelesDate
                  index={index}
                  primaryField="recived_document"
                  name="date"
                  label={"วันที่"}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("recived_document", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("recived_document", { name: "", date: "" })}
          text="เพิ่มวันได้รับเอกสาร"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        <CelesDate
          name="closing_date"
          label="วันปิดงาน"
          value={form.closing_date}
          setField={setField}
        />
        <div className="border-b border-gray-300 my-4"></div>
        {Title("งานบังคับคดี")}
        <CelesDate
          name="preparing_documents_date"
          label="วันถ่ายรูป,ทำแผนที่,คัดถ่ายเอกสาร,เตรียมเอกสาร"
          value={form.preparing_documents_date}
          setField={setField}
        />
        <CelesDate
          name="execution_date2"
          label="วันดำเนินการบังคับคดี"
          value={form.execution_date2}
          setField={setField}
        />
        {form?.public_auction_date?.length &&
          form.public_auction_date.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="public_auction_date"
                  name="date"
                  label={"วันประกาศขายทอดตลาด ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("public_auction_date", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("public_auction_date", { date: "" })}
          text="เพิ่มวันประกาศขายทอดตลาด"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="border-b border-gray-300 my-4"></div>
        {form?.sell_to_public?.length &&
          form.sell_to_public.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="sell_to_public"
                  name="date"
                  label={"วันขายทรัพย์ ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("sell_to_public", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("sell_to_public", { date: "" })}
          text="เพิ่มวันขายทรัพย์"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        {form?.money_from_execution?.length &&
          form.money_from_execution.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="money_from_execution"
                  name="date"
                  label={"วันได้รับเงินจากการบังคับคดี ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("money_from_execution", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("money_from_execution", { date: "" })}
          text="เพิ่มวันได้รับเงินจากการบังคับคดี"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <div className="border-b border-gray-300 my-4"></div>
        {form?.receipt_payment_account?.length &&
          form.receipt_payment_account.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                <CelesDate
                  index={index}
                  primaryField="receipt_payment_account"
                  name="date"
                  label={"วันได้รับบัญชีรับจ่ายเงิน ครั้งที่ " + (index + 1)}
                  form={form}
                  setFieldMulti={setFieldMulti}
                  value={row.date}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("receipt_payment_account", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <CelesButton
          onClick={() => addArray("receipt_payment_account", { date: "" })}
          text="เพิ่มวันได้รับบัญชีรับจ่ายเงิน"
          icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
        <div className="pt-2"></div>
        <CelesDate
          name="closing_day2"
          label="วันปิดงาน"
          value={form.closing_day2}
          setField={setField}
        />
        <div className="border-b border-gray-300 my-4"></div>
        {form?.operation_record?.length &&
          form.operation_record.map((row, index) => {
            return (
              <div className="grid grid-row-1">
                {Label("บันทึกการดำเนินงาน ครั้งที่ " + [index + 1])}
                <TextArea
                  name="text"
                  primaryField="operation_record"
                  setFieldMulti={setFieldMulti}
                  index={index}
                  value={row.text}
                />
                <div className="pt-2" />
                <button
                  onClick={() => removeArray("operation_record", index)}
                  className="bg-red-500 text-white p-2  rounded"
                  hidden={index === 0}
                >
                  Delete
                </button>
              </div>
            );
          })}
        <div className="pt-2"></div>
        <div className="grid grid-cols-1">
          <CelesButton
            onClick={() => addArray("operation_record", { text: "" })}
            text="เพิ่มบันทึกการดำเนินงาน"
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </div>
        <div className="border-b border-gray-300 my-4"></div>
      </div>
    </div>
  );
}

export default EditClient;
